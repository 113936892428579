export const typeINM = {
    "Psychologiques":  [
        "Art thérapies",
        "Programmes d'éducation santé",
        "Psychothérapie",
        "Pratiques psychocorporelles",
        "Thérapies Assistées par l'animal",
    ],
    "Physiques":  [
        "Programmes d'activités physique",
        "Hortithérapie",
        "Physiothérapies",
        "Thérapies manuelles",
        "Techniques de puncture",
        "Programmes balnéologiques",
    ], 
    "Nutritionnelles": ["Compléments alimentaires", "Programmes nutritionnels"],
    "Numériques": [
        "m-santé",
        "Thérapies par le jeu vidéo",
        "Thérapie par la réalité virtuelle",
    ],
    "Elémentaires": [
        "Préparations minérales",
        "Préparations botaniques",
        "Méthodes éléctro-magnétiques",
        "Cosméceutiques",
    ]
}

export const inmListe = [
    {
        'value':"Psychologiques",
        'label':"Psychologiques"
    },
    {
        'value':"Physiques",
        'label':"Physiques"
    },
    {
        'value':"Nutritionnelles",
        'label':"Nutritionnelles"
    },
    {
        'value':"Numériques",
        'label':"Numériques"
    },
    {
        'value':"Elémentaires",
        'label':"Elémentaires"
    },
]