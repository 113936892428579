import { useCallback, useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Avis, AvisUpdate } from '../../interfaces/Avis';
import { avisService } from '../../services/avis';
import { AvisListItemModal } from './AvisListItemModal';
import { Rate } from './star';
import { createPropertyAssignment } from 'typescript';
const statut = {
  UNVERIFIED: 0,
  APPROVED: 1,
  APPROVED_AND_MODIFIED: 2,
  DENIED: 3,
  REPORTED: 4,
  UPDATED_BY_USER: 5,
}
const decryptStatut = (statut: number) => {
  if (statut === 0) {
    return "UNVERIFIED"
  }
  if (statut === 1) {
    return "APPROVED"
  }
  if (statut === 2) {
    return "APPROVED_AND_MODIFIED"
  }
  if (statut === 3) {
    return "DENIED"
  }
  if (statut === 4) {
    return "REPORTED"
  }
  if (statut === 5) {
    return "UPDATED_BY_USER"
  }
}
export const AvisListItem = ({
  avis,
  type,
  updateList,
  proName
}: {
  avis: Avis;
  type: string;
  updateList: () => void;
  proName: string;
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [raisonSociale] = useState(avis.etablissement?.raisonSociale ?? "");

  const isEts = useMemo(() => type === "avisets", [type]);
  const showModal = useCallback(
    (x: boolean) => () => {
      setShow(x);
    },
    []
  );

  return (
    <>
      <tr>
        <td>
          <Rate
            className="stars"
            note={avis.note}
            total={5}
            height="20px"
            full={undefined}
            empty={undefined}
          />
        </td>
        <td>{proName}</td>
        <td>{raisonSociale}</td>
        <td>{avis.pseudo}</td>
        <td>{avis.email}</td>
        <td>{avis.titre}</td>
        <td>{avis.plus}</td>
        <td>{avis.moins}</td>
        <td>{new Date(avis.date).toLocaleDateString()}</td>
        <td>{decryptStatut(avis.statut)}</td>
        <td>
          <div className="d-flex">
            <Button variant="info" onClick={showModal(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        </td>
      </tr>
      <AvisListItemModal
        setShow={setShow}
        updateList={updateList}
        isEts={isEts}
        loading={loading}
        show={show}
        showModal={showModal}
        avis={avis}
        target={raisonSociale}
      />
    </>
  );
};
