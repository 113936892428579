import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { url as back } from "../../server.json";
import { ecoleService } from "../../services/ecole.service";
import { syndicatService } from "../../services/syndicat.service";
import { trainingOrganizationService } from "../../services/training-organization.service";

export const CreateSyndicatModal = ({
  showCreateModal,
  setShowCreateModal,
  onSuccess,
}: any) => {
  const [error, setError] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const createSyndicat = async (data: any) => {
    try {
      const syndicat = await syndicatService.create(data);
      if (syndicat) {
        onSuccess();
      }
      setShowCreateModal(false);
    } catch (err: any) {
      setError(err.response.data.message);
      console.error(err.response);
    }
  };

  return (
    <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un syndicat</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(createSyndicat)}>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="denomination">Dénomination</Form.Label>
            <Form.Control
              {...register("denomination", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="denomination"
              id="denomination"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Sigle</Form.Label>
            <Form.Control
              {...register("sigle", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="sigle"
              id="sigle"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="email">Email</Form.Label>
            <Form.Control
              {...register("email", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="email"
              id="email"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="url">Lien url</Form.Label>
            <Form.Control
              {...register("url", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="url"
              id="url"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="address.street">
              Rue et libellé de voie
            </Form.Label>
            <Form.Control
              {...register("address.street", {
                required: "Champs obligatoire",
              })}
              as="input"
              type="text"
              name="address.street"
              id="address.street"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="address.city">Ville</Form.Label>
            <Form.Control
              {...register("address.city", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="address.city"
              id="address.city"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="address.cp">Code postal</Form.Label>
            <Form.Control
              {...register("address.cp", { required: "Champs obligatoire" })}
              as="input"
              type="number"
              name="address.cp"
              id="address.cp"
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="phoneNumber">Numéro de téléphone</Form.Label>
            <Form.Control
              {...register("phoneNumber", { required: "Champs obligatoire" })}
              as="input"
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              placeholder="format : +xxxxxxxxxxx"
            />
          </Form.Group>
          {error && <p style={{ color: "red" }}>{errors}</p>}
          <Button type="submit" variant="success">
            Valider
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
