import { InputGroup, FormControl } from "react-bootstrap";
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StudyName = ({etudeName, setEtudeName}) => {

    return (
        <div className="etude-name">
            {
            etudeName &&
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                    Nom de l'étude
                </InputGroup.Text>
                <FormControl
                    placeholder="Nom de l'étude"
                    aria-label="Name of the study"
                    aria-describedby="basic-addon1"
                    value={etudeName}
                    onChange={(e) => {
                        setEtudeName(e.target.value);
                    }}
                />
                <InputGroup.Text>
                    <FontAwesomeIcon 
                        icon={faPen}
                        size="lg"
                        color="black"
                    />
                </InputGroup.Text>
            </InputGroup>
            }
        </div>
    )

}

export default StudyName;