import axios from "axios";

import { Syndicat } from "../interfaces/Syndicat";
import { url as serveur } from "../server.json";

const instance = axios.create({
  withCredentials: true,
});

class SyndicatService {
  async findAll(): Promise<Syndicat[]> {
    const res = await instance.get(`${serveur}/admin/syndicat/`, {
      withCredentials: true,
    });
    if (res.status !== 200) {
      return Promise.reject();
    }
    return res.data;
  }

  async create(data: Partial<Syndicat>): Promise<Syndicat> {
    const res = await instance.post(`${serveur}/admin/syndicat/`, data, {
      withCredentials: true,
    });
    if (res.status !== 201) {
      return Promise.reject();
    }
    return res.data;
  }

  async update(id: string, data: Partial<Syndicat>): Promise<Syndicat> {
    const res = await instance.put(`${serveur}/admin/syndicat/${id}`, data, {
      withCredentials: true,
    });
    if (res.status !== 200) {
      return Promise.reject();
    }
    return res.data;
  }
  async acceptClaim(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/syndicat/claim/${id}/accept`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async declineClaim(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/syndicat/claim/${id}/decline`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }
}

export const syndicatService = new SyndicatService();
