import React from 'react';
import './loader.style.css';

function View() {

	return (
        <div className="container h-100">
            <div className="row h-100">
                <div className="col-12 text-center h-100 p-50">
                    <div className="loadingio-spinner-rolling-enjvpmpkdor">
                            <div className="ldio-kx3qkynwrdi">
                                <div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default View;