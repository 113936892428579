import React, { useState } from 'react';
import { Resource } from '../../../components/Resource';
import { Besoin } from '../../../interfaces/Besoin';
import { Modal, Table, Button, InputGroup, FormControl } from "react-bootstrap";
import axios from 'axios';
import { url as serveur } from "../../../server.json";

interface props {
    close: any;
}
const UpdateBesoin = (props: props) => {
    const [besoin, setBesoin] = useState<Besoin | null>(null);
    const [hasUpdateModal, setHasUpdateModal] = useState<boolean>(false);
    const [motClef, setMotClef] = useState<string>('');
    const [deleted, setDelete] = useState<Boolean>(false);
    const close = props.close;
    console.log(besoin);
    const deletWord = (k: any) => {
        if (besoin) {
            const newArray = besoin.motsCles;
            newArray.splice(k, 1);
            setBesoin({
                ...besoin,
                motsCles: newArray,
            })
        }
    };
    const pushMotClef = () => {
        if (besoin) {
            const newArray = besoin?.motsCles;
            newArray?.push(motClef);
            setBesoin({
                ...besoin,
                motsCles: newArray
            });
            setMotClef('');
        }
    }
    const update = () => {
        if (besoin) {
            //TODO CHANGE TO FETCH
            axios({
                url: `${serveur}/besoins/${besoin._id}`,
                method: 'PUT',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                data: besoin
            }).then((e) => {
                console.log(e)
                if (e.status === 200) {
                    setHasUpdateModal(false);
                    setMotClef('');
                }
            });
        }
    }
    const deleteItem = () => {
        if (besoin) {
            //TODO CHANGE TO FETCH
            axios({
                url: `${serveur}/besoins/${besoin._id}`,
                method: 'DELETE',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }).then((e) => {
                console.log(e)
                if (e.status === 200) {
                    setHasUpdateModal(false);
                    setMotClef('');
                    setDelete(false);
                }
            });
        }
    }
    return (
        <>
            <Resource<Besoin[]>
                url={'/besoins/'}
                render={(data) => (
                    <Table striped bordered hover>
                        {data
                            .map((_besoin) => (
                                <tr
                                    onClick={() => {
                                        setBesoin(_besoin)
                                        setHasUpdateModal(true)
                                        setDelete(false)
                                    }}
                                >
                                    {
                                        Object.values(_besoin).map((id, k) => (
                                            typeof id !== 'object' && <td key={k}>{id}</td>
                                        ))
                                    }
                                </tr>
                            ))}
                    </Table>

                )}
            />
            <Modal
                show={hasUpdateModal}
                onHide={() => {
                    setHasUpdateModal(false)
                    setDelete(false)
                }}
            >
                <Modal.Title>Modification</Modal.Title>
                <Modal.Body>


                    <div style={{ padding: '20px' }}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">libelle</InputGroup.Text>
                            {besoin &&
                                <FormControl
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={besoin?.libelle}
                                    onChange={(e) => setBesoin({
                                        ...besoin,
                                        libelle: e.target.value
                                    })}
                                />
                            }
                        </InputGroup>
                        {
                            besoin && besoin.motsCles.map((word, k) => (
                                <Button variant='danger' style={{ margin: '5px' }} key={k}
                                    onClick={() => deletWord(k)}
                                >{word}</Button>
                            ))
                        }
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1">Mot clef</InputGroup.Text>
                            {besoin &&
                                <FormControl
                                    placeholder="Ajouter un mot clef"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    value={motClef}
                                    onChange={(e) => setMotClef(
                                        e.target.value
                                    )}
                                />
                            }
                        </InputGroup>
                        <Button variant='secondary' onClick={() => {
                            pushMotClef()
                        }}>Ajouter</Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {deleted && <Button variant='danger' onClick={() => deleteItem()}>Confirmer</Button>}
                    <Button variant='danger' onClick={() => setDelete(true)}>Supprimer</Button>
                    <Button variant='secondary' onClick={() => setHasUpdateModal(false)}>Annuler</Button>
                    <Button variant='success' onClick={() => update()}>Enregistrer</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default UpdateBesoin;