import { url } from '../server.json';

const postEditionService = {
  async list(editionState, kind) {
    const searchParam = new URLSearchParams();
    if (editionState) {
      searchParam.append('editionState', editionState);
    }
    if (kind) {
      searchParam.append('kind', kind);
    }

    const response = await fetch(`${url}/admin/post/editor?${searchParam.toString()}`, {
      method: 'get',
      credentials: 'include'
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  // draft value is optionnal
  async create(postData, draft) {
    const endpoint = (draft !== undefined ? `${url}/admin/post/editor?draft=${draft}` : `${url}/post/editor`);

    const response = await fetch(endpoint, {
      method: 'post',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  async get(postId) {
    const response = await fetch(`${url}/admin/post/editor/${postId}`, {
      method: 'get',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  async update(postId, postData) {
    const response = await fetch(`${url}/admin/post/editor/${postId}`, {
      method: 'put',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData)
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  async updateEditionState(postId, newState) {
    const response = await fetch(`${url}/admin/post/editor/${postId}/state?state=${newState}`, {
      method: 'put',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  async updateImage(postId, file) {
    const formData = new FormData();

    // Update the formData object 
    formData.append("image", file, file.name);

    const response = await fetch(`${url}/admin/post/editor/${postId}/image`, {
      method: 'put',
      credentials: 'include',
      body: formData,
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  },

  async delete(postId) {
    const response = await fetch(`${url}/admin/post/editor/${postId}`, {
      method: 'delete',
      credentials: 'include',
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return;
  },

  async searchTag(searchValue){
    const searchParam = new URLSearchParams();
    searchParam.append('search', searchValue);

    const response = await fetch(`${url}/post/tag?${searchParam.toString()}`, {
      method: 'get',
      credentials: 'include',
    });
    if (!response.ok) {
      return Promise.reject();
    }
    return await response.json();
  }
};

export default postEditionService;

