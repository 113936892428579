import { useEffect, useState } from "react";
import postEditionService from "../../services/post-edition";
import { ErrorAlert } from "../../components/errorAlert/ErrorAlert";

export const PostImage = ({ imgKey, postId, createDraft, onChange }) => {
  const [imageKey, setImageKey] = useState(imgKey);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)

  const _createDraft = async () => {
    return await createDraft();
  };

  const handleImageLoaded = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    try {
      // 1 - Create a draft if post does not exists yet
      const _postId = postId ? postId : await _createDraft();

      // 2 - Update Image
      const post = await postEditionService.updateImage(_postId, file);
      setImageKey(post.image);
      onChange(true);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setError(true);
    }
  };
  return (
    <div className="row image-upload">
      <label className="col-12 p-0 mb-3">
        Sélectionner l'image mise en avant:{" "}
      </label>
      <input
        id="image"
        type="file"
        hidden
        onChange={handleImageLoaded}
        accept="image/png, image/jpeg"
      ></input>

      {imageKey ? (
        <div className="d-flex align-items-center">
          <label htmlFor="image">
            <button className="btn btn-blue-outline">Modifier l'image</button>
          </label>
          {loading && (
            <p>Chargement en cours</p>
          )}

          {error ? (
            <ErrorAlert>Erreur lors du chargement de l'image</ErrorAlert>
          ) : (
            <img
              src={`/api/post/editor/${postId}/image?key=${imageKey}`}
              alt="Post banner"
              style={{objectFit: "contain", borderRadius: '5px', maxWidth:"300px"}}
              className="ml-4"
            />
          )}
        </div>
      ) : (
        <label htmlFor="image">
          <button className="btn btn-blue-outline">Ajouter une image</button>
        </label>
      )}
    </div>
  );
};
