import { Button, Input, InputRef, Space, Table, Tooltip } from "antd";
import { ColumnType } from "antd/es/table";
import { FilterConfirmProps } from "antd/es/table/interface";
import { useRef, useState } from "react";
import Highlighter from "react-highlight-words";

import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { Resource } from "../../../components/Resource";
import {
  Claim,
  ClaimStatus,
} from "../../../interfaces/training-organisation/Claim";
import { syndicatService } from "../../../services/syndicat.service";

let shape: Claim[];

export const VerifyClaimSyndicat = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          ></Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            <DeleteOutlined />
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrer
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            x
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const acceptClaim = async (claim: Claim) => {
    const updated = await syndicatService.acceptClaim(claim._id);
    if (updated) {
      shape.map((c) => (c._id === updated._id ? updated : c));
    }
  };

  const columns = [
    {
      title: "Email",
      dataIndex: ["email"],
      key: "email",
      ellipsis: {
        showTitle: true,
      },
      width: "25%",
      ...getColumnSearchProps("email"),
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: "Organisation",
      dataIndex: ["target", "denomination"],
      key: "denomination",
      ellipsis: {
        showTitle: true,
      },
      width: "25%",
      ...getColumnSearchProps("target.denomination"),
      sorter: (a: any, b: any) =>
        a.target.denomination.localeCompare(b.target.denomination),
    },
    {
      title: "Statut",
      dataIndex: ["status"],
      key: "status",
      width: "7%",
      render: (_: any, record: any) => (
        <div className="pb-1 text-center">
          {(record.status === ClaimStatus.PENDING && (
            <Tooltip title="En attente">
              <ClockCircleOutlined style={{ color: "orange" }} />
            </Tooltip>
          )) ||
            (record.status === ClaimStatus.ACCEPTED && (
              <Tooltip title="Acceptée">
                <CheckCircleOutlined style={{ color: "green" }} />
              </Tooltip>
            )) ||
            (record.status === ClaimStatus.DECLINED && (
              <Tooltip title="Révoquée">
                <CloseCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            ))}
        </div>
      ),
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      filterSearch: true,
      onFilter: (value: string, record: Claim) =>
        record.status.startsWith(value),
      filters: [
        {
          text: "En attente",
          value: ClaimStatus.PENDING,
        },
        {
          text: "Acceptée",
          value: ClaimStatus.ACCEPTED,
        },
        {
          text: "Révoquée",
          value: ClaimStatus.DECLINED,
        },
      ],
    },
    {
      title: "Invitation",
      dataIndex: ["invitation", "status"],
      key: "invitation.status",
      width: "8%",
      render: (_: any, record: any) => (
        <div className="pb-1 text-center">
          {(record.invitation?.status === "active" && (
            <Tooltip title="En attente">
              <ClockCircleOutlined style={{ color: "orange" }} />
            </Tooltip>
          )) ||
            (record.invitation?.status === "used" && (
              <Tooltip title="Inscrit">
                <CheckCircleOutlined style={{ color: "green" }} />
              </Tooltip>
            )) ||
            (record.invitation?.status === "revoked" && (
              <Tooltip title="Révoquée">
                <CloseCircleOutlined style={{ color: "red" }} />
              </Tooltip>
            )) ||
            (!record.invitation && (
              <Tooltip title="Pas envoyé">
                <MinusOutlined style={{ color: "black" }} />
              </Tooltip>
            ))}
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.invitation?.status?.localeCompare(b.invitation?.status),
      filterSearch: true,
      onFilter: (value: string, record: Claim) =>
        record.invitation?.status?.startsWith(value),
      filters: [
        {
          text: "En attente",
          value: "active",
        },
        {
          text: "Acceptée",
          value: "used",
        },
        {
          text: "Révoquée",
          value: "revoked",
        },
        {
          text: "Pas envoyée",
          value: undefined,
        },
      ],
    },
    {
      title: "Invité le",
      width: "10%",
      dataIndex: ["invitation", "createdAt"],
      key: "createdAt",
      ellipsis: {
        showTitle: true,
      },
      sorter: (a: any, b: any) =>
        a.acc?.invitation?.createdAt.localeCompare(
          b.acc?.invitation?.createdAt
        ),
      render: (_: any, record: any) => (
        <td>
          {record.invitation?.createdAt ? (
            new Date(record.invitation?.createdAt).toLocaleDateString("fr")
          ) : (
            <Tooltip title="Pas envoyé">
              <MinusOutlined style={{ color: "black" }} />
            </Tooltip>
          )}{" "}
          {record.invitation?.createdAt
            ? new Date(record.invitation?.createdAt).toLocaleTimeString("fr")
            : ""}
        </td>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_: any, record: any, index: number) => (
        <Space size="middle">
          {record.status === ClaimStatus.PENDING && (
            <Button onClick={() => acceptClaim(record)}>
              Accepter et inviter
            </Button>
          )}
          {record.status === ClaimStatus.PENDING && <Button>Refuser</Button>}
          {record.invitation?.status === "active" && <Button>Relancer</Button>}
        </Space>
      ),
    },
  ];

  return (
    <>
      <div>
        <h1>Prise de contrôle syndicats</h1>
      </div>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        className="pr-3 mb-3"
        onClick={() => {}}
        disabled
      >
        Inviter une personne
      </Button>

      <Resource
        dataShape={shape}
        url="/admin/syndicat/claims"
        render={(data) => (
          <Table
            columns={columns}
            size="middle"
            pagination={false}
            dataSource={data}
            rowClassName={(record, index) =>
              index % 2 === 1 ? "bg-light" : ""
            }
          ></Table>
        )}
      />
    </>
  );
};
