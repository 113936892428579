import { FormControl, Form, Button, Modal, ListGroup, Spinner } from "react-bootstrap";
import { VisibilityKind } from "../../../../models/study.model";
import { establishmentService } from "../../../../services/establishment.service";
import { useEffect, useState } from "react";
import { etudeLocaleService } from "./local-study";

const StudyVisibility = ({etudeVisibility, setEtudeVisibility}) => {

    const [showEtsList, setShowEtsList] = useState(false);
    const [showAddEts, setShowAddEts] = useState(false);

    const [etsList, setEtsList] = useState(null);
    const [getEtsListError, setGetEtsListError] = useState(null);

    const [visibilityEts, setVisibilityEts] = useState(null);

    const [init, setInit] = useState(false);

    const [etsSearch, setEtsSearch] = useState("");

    const [loading, setLoading] = useState(false);

    useEffect(async() => {
        if(!visibilityEts) {
            if(!etudeVisibility.etablissements) setVisibilityEts([]);
            else setVisibilityEts(etudeVisibility.etablissements)
        }
        if(!init && !etsList) {
            setInit(true);
        }
    });

    const test = () => { 
        if(loading) {
            setTimeout(test, 100);
        }
        return
    }

    useEffect(async() => {
        if(showAddEts && etsSearch.length >= 2) {
            setLoading(true);
            establishmentService.findAllEtsByName(etsSearch).then((result) => {
                setEtsList(result.data);
                setLoading(false);
            }).catch((err) => {
                setGetEtsListError(err);
            })
        }
    }, [etsSearch]);

    useEffect(async() => {
        if(!etudeVisibility.etablissements) setVisibilityEts([]);
        else setVisibilityEts(etudeVisibility.etablissements)
    }, [etudeVisibility]);

    const addThenupdateEtsList = (ets) => {
        let list = visibilityEts;
        list.push({id: ets.id, raisonSociale: ets.raisonSociale});
        setEtudeVisibility({kind: etudeVisibility.kind, etablissements: list});
        setVisibilityEts(null);
        
    }

    const removeThenupdateEtsList = (ets) => {
        let list = visibilityEts;
        list.splice(list.map(e => e.id).indexOf(ets.id), 1);
        setEtudeVisibility({kind: etudeVisibility.kind, etablissements: list});
        setVisibilityEts(null);
    }

    return (
        etudeVisibility &&
        <div className="etude-visibility">
            <div className="etude-visibility-radios-box">
                <p className="etude-visibility-radios-texte">Spectre de visibilité de l'étude</p>
                <Form className="etude-visibility-radios">
                    <Form.Check
                        inline
                        label={VisibilityKind.CLOSED}
                        name="visibility"
                        type="radio"
                        id="visibility-radio-closed"
                        checked={etudeVisibility.kind === VisibilityKind.CLOSED}
                        onChange={() => {setEtudeVisibility({kind: VisibilityKind.CLOSED, etablissements: etudeVisibility.etablissements})}}
                    />
                    <Form.Check
                        inline
                        label={VisibilityKind.OPENED}
                        name="visibility"
                        type="radio"
                        id="visibility-radio-opened"
                        checked={etudeVisibility.kind === VisibilityKind.OPENED}
                        onChange={() => {setEtudeVisibility({kind: VisibilityKind.OPENED, etablissements: etudeVisibility.etablissements})}}
                    />
                    <Form.Check
                        etablissements
                        label={VisibilityKind.RESTRICTED}
                        name="visibility"
                        type="radio"
                        id="visibility-radio-restricted"
                        checked={etudeVisibility.kind === VisibilityKind.RESTRICTED}
                        onChange={() => {setEtudeVisibility({kind: VisibilityKind.RESTRICTED, etablissements: etudeVisibility.etablissements})}}
                    />
                </Form>
            </div>
            {
                etudeVisibility.kind === VisibilityKind.RESTRICTED &&
                <div className="etude-visibility-buttons">
                    <Button
                        variant='light outline-light'
                        onClick={async() => {
                            setShowEtsList(true);
                        }}
                        size='md'
                    >Liste des établissements avec accès</Button>
                    <Button
                        variant='light outline-light'
                        onClick={async() => {
                            setShowAddEts(true);
                        }}
                        size='md'
                    >Ajouter un établissement</Button>
                </div>
            }
            {
                <Modal
                    show={showEtsList}
                    onHide={() => {
                        setShowEtsList(false)
                    }}
                >
                    <Modal.Title>Liste des établissements avec accès</Modal.Title>
                    <Modal.Body>
                        {
                            visibilityEts &&
                            <div>
                                <FormControl
                                    placeholder="Rechercher un établissement"
                                    aria-label="EtsRaisonSociale"
                                    value={etsSearch}
                                    onChange={(e) => {
                                        setEtsSearch(e.target.value);
                                    }}
                                />
                                <ListGroup  style={{border: '1px solid rgba(0, 0, 0, 0.5)', minHeight:"50px", maxHeight: '650px'}}>
                                    {
                                    etudeVisibility.etablissements &&
                                    etudeVisibility.etablissements.filter(ets => ets.raisonSociale.toLowerCase().includes(etsSearch.toLowerCase())).map((etablissement, i) => (
                                        <ListGroup.Item key={i}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                                                {etablissement.raisonSociale}
                                                <Button 
                                                    variant="danger"
                                                    onClick={() => {
                                                        removeThenupdateEtsList(etablissement);
                                                    }}
                                                >
                                                    <b>-</b>
                                                </Button>
                                            </div>
                                        </ListGroup.Item>
                                    ))
                                    }
                                </ListGroup>
                            </div>
                        }
                        {
                            getEtsListError && (
                            <p className="error"> {getEtsListError} </p>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => {
                                setShowEtsList(false)
                        }}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            }
            {
                <Modal
                    show={showAddEts}
                    onHide={() =>  {
                        setShowAddEts(false)
                        setEtsSearch("");
                        setEtsList(null);
                    }}>
                    <Modal.Title>Ajouter un établissement</Modal.Title>
                    <Modal.Body>
                        {
                        <FormControl
                            placeholder="Rechercher un établissement"
                            aria-label="Username"
                            value={etsSearch}
                            onChange={(e) => {
                                setEtsSearch(e.target.value);
                            }}
                        />
                        }
                        {
                            (etsList && visibilityEts &&
                            <div>
                                <ListGroup  style={{border: '1px solid rgba(0, 0, 0, 0.5)', minHeight:"50px", maxHeight: '650px'}}>
                                    {    
                                    etsList && !loading &&  etsSearch.length >= 2 &&                       
                                    etsList.filter((etablissement) => {
                                        let contains = false;
                                        visibilityEts.forEach(ets => {
                                            if(ets.id === etablissement.id) contains = true;
                                        })
                                        return !contains && etablissement.raisonSociale.toLowerCase().includes(etsSearch.toLowerCase())
                                    }).map((etablissement, i) => (
                                        <ListGroup.Item key={i}>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                                                {etablissement.raisonSociale}
                                                <Button onClick={() => {
                                                    addThenupdateEtsList(etablissement);
                                                }}><b>+</b></Button>
                                            </div>
                                        </ListGroup.Item>
                                    ))

                                    }
                                    {
                                        loading && etsSearch.length >= 2 &&
                                        <Spinner animation="border" role="status">
                                        </Spinner>
                                    }
                                    {
                                        etsSearch.length < 2 &&
                                        <ListGroup.Item>                                            
                                            <p>Commencez à écrire dans la zone de recherche pour voir des établissements</p>
                                        </ListGroup.Item>
                                    }
                                </ListGroup>
                            </div>)
                        }
                        {
                            getEtsListError && (
                            <p className="error"> {getEtsListError} </p>
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => {
                            setShowAddEts(false);
                            setEtsSearch("");
                            setEtsList(null);
                        }}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )

}

export default StudyVisibility;