import {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import useSWR, { useSWRConfig } from "swr";

import { url as serverUrl } from "../server.json";

export function getFetcher<T>() {
  return (url: string) =>
    fetch(`${serverUrl}${url}`, {
      method: "GET",
      credentials: "include",
      headers: new Headers(),
      mode: "cors",
      cache: "default",
    }).then((res) => {
      if (res.status !== 200) {
        console.log(res);
        throw new Error(`Error fetching ${url}`);
      }
      return res.json() as Promise<T>;
    });
}

export function getCustomFetcher<T>() {
  return (url: string, init?: RequestInit) =>
    fetch(`${serverUrl}${url}`, init).then((res) => {
      if (res.status !== 200) throw new Error(`Error fetching ${url}`);
      return res.json() as Promise<T>;
    });
}

interface ResourceProps<T> {
  url: string;
  render: (data: T) => JSX.Element;
  error?: (e: Error) => JSX.Element;
  interval?: number;
  dataShape?: T;
  update?: boolean;
}

export function Resource<T>({
  url,
  render,
  interval,
  error,
}: ResourceProps<T>) {
  const { data, error: resourceError } = useSWR(url, getFetcher<T>(), {
    refreshInterval: interval || 1000 * 60 * 60,
  });

  if (resourceError) {
    if (error) {
      error(resourceError);
    } else {
      return (
        <Alert variant="danger">
          {resourceError.message || "Unhandled error"}
        </Alert>
      );
    }
  }
  if (!data)
    return (
      <div className="d-flex justify-content-center align-items-center w-100">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  return render(data);
}
