import { useState } from "react";
import { Button } from "react-bootstrap";
import { PartnershipService } from "../../../services/partnership.service";

export const PartenariatProItem = (props:any) => {
  const [loading, setLoading] = useState(false);
  const {pro, setModalData, setShowModal} = props;
  const onClickOpenQuestionnaire = async () => {
    const dataQuestionnaire = await PartnershipService.getForm(pro._id);
    console.log(dataQuestionnaire)
    setModalData(dataQuestionnaire);
    setShowModal(true);
  };

  return (
    <tr>
      <td>
        {pro?.nom} {pro?.prenom}
      </td>
      <td>
        <Button
          variant="primary"
          onClick={onClickOpenQuestionnaire}
          disabled={loading}
        >
          Voir le questionnaire
        </Button>
      </td>
      <td>
        <Button
          variant="success"
          onClick={() => {
            setLoading(true);
            PartnershipService.validateQuestionnary(pro._id).then(() => {
              setLoading(false);
            });
          }}
          disabled={loading}
        >
          Accepter
        </Button>
        <Button
          variant="warning"
          onClick={() => {
            setLoading(true);
            PartnershipService.declineQuestionnary(pro._id).then(() => {
              setLoading(false);
            });
          }}
          disabled={loading}
        >
          Refuser
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            setLoading(true);
            PartnershipService.deleteQuestionnary(pro._id).then(() => {
              setLoading(false);
            });
          }}
          disabled={loading}
        >
          Supprimer
        </Button>
      </td>
    </tr>
  );
};