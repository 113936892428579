
import { useState } from "react";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import {Report } from "../../../interfaces/Avis";
import { Pro } from "../../../interfaces/Pro";
import { ProService } from "../../../services/pro.service";

export interface IReportedProsListItemProps {
  pro: Pro;
  setCurrentReports: (reports: Report[]) => void
  setShowModal: (show: boolean) => void
}

export const ReportedProsListItemBlacklisted = (
  { pro, setCurrentReports, setShowModal }: IReportedProsListItemProps
) => {

  const [loading, setLoading] = useState(false);

  const blacklistPro = async () => {
    setLoading(true);
    try {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Souhaites-tu vraiment retirer le pro de la liste noire ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, retirer le pro",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "validate-btn",
          cancelButton: "cancel-btn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProService.updateBlacklisted(pro._id, false);
        }
      })
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreur est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      setLoading(false);
    }
  }

  const loadReports = async () => {
    setCurrentReports(pro.reports);
    setShowModal(true);
  }

  const nbReportsConfirmed = pro.reports.filter((report) => report.isConfirmed).length;
  return (
    <tr>
      <td>{`${pro.nom} ${pro?.prenom}`}</td>
      <td><a href={`mailto:${pro.email}`} target="_blank" rel="noopener noreferrer">{pro.email}</a></td>
      <td>{`${pro.reports.length}`} </td>
      <td>{`${nbReportsConfirmed}`}</td>
      <td>
        <Button variant="primary" onClick={loadReports}>
          Voir les signalements
        </Button>
        <Button variant="danger" onClick={blacklistPro}>
          Approuver le pro
        </Button>
      </td>
    </tr>
  );
};
