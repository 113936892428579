type IndicatorEntry<P = "+", N = "-"> = P | N | null;

export type CovidIndicators = {
  noMask: boolean;
  proximity: boolean;
  insufficentCleaning: boolean;
};

export type Indicators = {
  parking: IndicatorEntry;
  reception: IndicatorEntry;
  room: IndicatorEntry;
  food: IndicatorEntry;
  organisation: IndicatorEntry;
  doctorRelationship: IndicatorEntry;
  caregiversRelationship: IndicatorEntry;
  medicalErrors: IndicatorEntry;
  pain: IndicatorEntry;
  service: IndicatorEntry;
  invoice: IndicatorEntry;
  covid: IndicatorEntry<any, CovidIndicators>;
};

export const defaultIndicators: Indicators = {
  parking: null,
  reception: null,
  room: null,
  food: null,
  organisation: null,
  doctorRelationship: null,
  caregiversRelationship: null,
  medicalErrors: null,
  pain: null,
  service: null,
  invoice: null,
  covid: null,
};
Object.freeze(defaultIndicators);

export const defaultCovidIndicators: CovidIndicators = {
  insufficentCleaning: false,
  noMask: false,
  proximity: false,
};
Object.freeze(defaultCovidIndicators);
