import { Form, Input, Select, Button } from "antd"
import { useContext, useEffect, useState } from "react"
import { GlobalSourceContext, PratiqueSelectedContext, TypeProSelectedContext } from "./context"
import { PratiquesService } from "../../services/pratique.service"
import { inmListe, typeINM } from "./utils"

const CreatePratique = () => {
    const [form_pratique] = Form.useForm()
    const [inm_selected, setInmSelected] = useState(null)
    let pratique_select = useContext(PratiqueSelectedContext)
    let global = useContext(GlobalSourceContext)
    const [updateState, setUpdateState] = useState(false)

    useEffect(() => {
        if (pratique_select.value) {
            setInmSelected(pratique_select.value.inmLib)
            setUpdateState(true)
            form_pratique.setFieldsValue({
                libelle: pratique_select.value.libelle,
                description: pratique_select.value.definition,
                inm: pratique_select.value.inmLib,
                type: pratique_select.value.typeLib,
                mca: pratique_select.value.mca_string || ""
            })
        }
    }, [pratique_select.value])

    const submitPratique = async (values) => {
        const body = {
            libelle: values.libelle.trim(),
            inmLib: values.inm.trim(),
            typeLib: values.type.trim(),
            definition: values.description,
            mca: values.mca.trim()
        }
        const res = await PratiquesService.createPratique(body)
        // mets a jour la liste des pratiques avec le retour du serveur
        global.setPratiquesGlobal(global.pratiquesGlobal.concat([res.data]))
        global.setModalOpen(false)
        form_pratique.resetFields()
    }
    const updatePratique = async (values) => {
        const body = {
            ...pratique_select.value,
            libelle: values.libelle.trim(),
            inmLib: values.inm.trim(),
            typeLib: values.type.trim(),
            definition: values.description,
            mca: values.mca.trim()
        }
        const res = await PratiquesService.updatePratique(body)
        pratique_select.func({
            value: null, 
            func: pratique_select.func
        })
        form_pratique.resetFields()
        global.setModalOpen(false)
        global.setUpdate(true)
    }
    let typeLib = []
    if (typeINM[inm_selected]) {
        typeLib = typeINM[inm_selected].map((type) => {
            return {
                'value': type,
                'label': type
            }
        })
    }
    return (
        <Form
            name='create_pratique'
            onFinish={updateState ? updatePratique: submitPratique}
            form={form_pratique}
        >
            <Form.Item
                label="Libelle"
                name="libelle"
                rules={[
                    {
                        required: true,
                        message: 'Le libelle est requis!',
                    },
                ]}
            >
                <Input placeholder='Libelle' />
            </Form.Item>
            <Form.Item
                label="Description"
                name="description"
            >
                <Input.TextArea rows={4} placeholder='Description' />
            </Form.Item>
            <Form.Item
                label="INM"
                name="inm"
                rules={[
                    {
                        required: true,
                        message: "L'inm est requis",
                    },
                ]}
            >
                <Select
                    options={inmListe}
                    style={{ minWidth: 300 }}
                    onChange={(val) => setInmSelected(val)}
                />
            </Form.Item>
            {
                inm_selected !== null ? (
                    <>
                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Le type est requis!',
                                },
                            ]}
                        >
                            <Select
                                style={{ minWidth: 300 }}
                                options={typeLib}
                            />
                        </Form.Item>
                        <Form.Item
                            label="MCA"
                            name="mca"
                            rules={[
                                {
                                    required: true,
                                    message: 'Le MCA est requis!',
                                },
                            ]}
                        >
                            <Select
                                style={{ minWidth: 300 }}
                                options={[
                                    {
                                        'value': 'Inconnue',
                                        'label': 'Inconnue'
                                    },
                                    {
                                        'value': 'Soin officiel non médicamenteux et relationnel',
                                        'label': 'Soin officiel non médicamenteux et relationnel'
                                    },
                                    {
                                        'value': 'Acceptée',
                                        'label': 'Acceptée'
                                    },
                                    {
                                        'value': 'Tolérée',
                                        'label': 'Tolérée'
                                    },
                                    {
                                        'value': 'Rejetée',
                                        'label': 'Rejetée'
                                    }
                                ]}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType='submit'>{ updateState ? ("Mettre à jour la pratique") : ("Ajouter la pratique")}</Button>
                        </Form.Item>
                    </>
                ) : (null)
            }
        </Form>
    )
}

export default CreatePratique