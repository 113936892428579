import axios from "axios";
import { url as serveur } from "../../../../server.json";

const instance = axios.create({
  withCredentials: true,
});

class EtudeService {
  async findAllExpertises() {
    return await instance.get(`${serveur}/expertises/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async findAllSpecialites() {
    return await instance.get(`${serveur}/specialites/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  //TODO DEPLACER CA DANS UN SERVICE ETABLISSEMENTS
  async findAllEts() {
    return await instance.get(`${serveur}/etablissements/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async findAll() {
    return await instance.get(`${serveur}/etudes/admin/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async findAllByEts(etsId) {
    return instance.get(`${serveur}/etudes/etablissement/` + etsId, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async create(etude) {
    console.log("etude", etude);
    return await instance.post(`${serveur}/etudes/admin/`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: etude,
    });
  }

  async update(etude) {
    return await instance.put(`${serveur}/etudes/admin/` + etude.id, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: etude,
    });
  }
}

export const etudeService = new EtudeService();
