import React, { useState } from 'react';
import { Pratique } from '../../../interfaces/Pratique';
import { Resource } from '../../../components/Resource';
import { Modal, Table, Button, InputGroup, FormControl } from "react-bootstrap";
import axios from 'axios';
import { url as serveur } from "../../../server.json";

const UpdatePratique = () => {
    const [hasUpdateModal, setHasUpdateModal] = useState<boolean>(false);
    const [deleted, setDelete] = useState<Boolean>(false);
    const [pratique, setPratique] = useState<any>({
        libelle: '',
        inm: "",
        inmLib: "",
        type: "",
        typeLib: "",
        mca: "",
        definition: "",
    });
    const keyPratique = {
            libelle: '',
            inm: "",
            inmLib: "",
            type: "",
            typeLib: "",
            mca: "",
            definition: "",
    }

    const update = () => {
        if(pratique){
        axios({
            url: `${serveur}/pratiques/${pratique._id}`,
            method: 'PUT',
            withCredentials:true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: pratique
        }).then((e) => {
            console.log(e)
            if(e.status === 200){
                setHasUpdateModal(false);
            }
        });
        }
    }
    const deleteItem = () => {
        if (pratique) {
            //TODO CHANGE TO FETCH
            axios({
            url: `${serveur}/pratiques/${pratique._id}`, 
                method: 'DELETE',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }).then((e) => {
                console.log(e)
                if (e.status === 200) {
                    setHasUpdateModal(false);
                    setDelete(false);
                }
            });
        }
    }

    console.log(pratique);
    return <>
        <Resource<Pratique[]>
            url={'/pratiques/'}
            render={(data) => (
                <Table striped bordered hover>
               { data.map((_pratique, o) => (
                   <tr key={o}
                   onClick={()=> {
                    setPratique(_pratique);
                    setHasUpdateModal(true);
                    setDelete(false); 
                }}
                   >
                     {
                        Object.values(_pratique).map((id, k) => (
                            typeof id !== 'object' &&  <td key={k}>{id}</td> 
                            ))
                        }
                        </tr>
                ))
               }
                </Table>
                )}
            />
                <Modal
          show={hasUpdateModal}
          onHide={()=> setHasUpdateModal(false)}
          >
              <Modal.Title>Modifier</Modal.Title>
              <Modal.Body>
    { pratique && Object.keys(keyPratique).map((idName, k) => (
        idName !== '_id' && 
              <InputGroup key={k} className="mb-3">    
    <InputGroup.Text id="basic-addon1">{idName}</InputGroup.Text>
    <FormControl
      placeholder={idName}
      aria-label={idName}
      aria-describedby="basic-addon1"
      value={pratique[idName]}
      onChange={(e) => setPratique({
          ...pratique,
          [idName]: e.target.value
      })}
      />
  </InputGroup>
  ))}
              </Modal.Body>
              <Modal.Footer>
              {deleted && <Button variant='danger' onClick={() => deleteItem()}>Confirmer</Button>}
                    <Button variant='danger' onClick={() => setDelete(true)}>Supprimer</Button>
              <Button variant='secondary' onClick={()=> setHasUpdateModal(false)}>Annuler</Button>

                  <Button variant='success' onClick={()=> update()}>Enregistrer</Button>
              </Modal.Footer>
          </Modal>
    </>
}

export default UpdatePratique;