import { useEffect, useState } from "react";
import postEditionService from "../../services/post-edition";

import "./post.style.scss";

export const TagEditor = (props) => {
  const [tagSearchResult, setTagSearchResult] = useState([]);
  const [tags, setTags] = useState(props.tags);
  const suggestions = [...tagSearchResult];

  useEffect(() => {
    setTags(props.tags);
  }, [props.tags]);

  function debounce(func, wait, immediate) {
    let timeout;
    return function executedFunction() {
      let context = this;
      let args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  const searchTagClicked = debounce(async (searchValue) => {
    if (searchValue && searchValue.length > 2) {
      const result = await postEditionService.searchTag(searchValue);
      setTagSearchResult(result);
    } else {
      setTagSearchResult([]);
    }
  }, 300);

  const removeTagClicked = (removedTag) => {
    props.removeTag(removedTag);
  };

  const addTagClicked = (addedTag) => {
    // const updatedTags = [...tags, addedTag];
    // setTags(updatedTags);
    props.addTag(addedTag);
  };

  if (tags) {
    // Remove already set tags
    for (const existingTag of tags) {
      const index = suggestions.findIndex(
        (tag) => tag.kind == existingTag.kind && tag.value == existingTag.value
      );
      if (index >= 0) {
        suggestions.splice(index, 1);
      }
    }
  }

  return (
    <div className="row mb-3">
      <label className="col-12 p-0">Vos mots-clés</label>

      {tags && (
        <div className="row col-12 my-2 tags-grid">
          {tags.map((tag, i) => {
            return (
              <div key={i} className="tag-label d-flex align-items-center">
                <p className="mb-0 mr-1">
                  {tag.kind} - {tag.label}
                </p>
                <i
                  className="fas fa-times"
                  onClick={() => removeTagClicked(tag)}
                ></i>
              </div>
            );
          })}
        </div>
      )}

      <div className="col-12 tag-search">
        <input
          type="text"
          id="tagSearch"
          name="tagsearch"
          placeholder="Ajouter un mot-clé"
          onChange={(e) => searchTagClicked(e.target.value)}
          className="col-12"
        />
      </div>

      <div className="row w-100">
        <div className="mt-2 col-12">
          {suggestions.map((tag, i) => {
            return (
              <div
                key={i}
                onClick={() => addTagClicked(tag)}
                className="suggestion-result"
              >
                {tag.kind} - {tag.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
