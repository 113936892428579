import './etablissement.style.scss';
import 'moment/locale/fr';

import moment from 'moment';
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useSWRConfig } from 'swr';

import { faEdit, faEye, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Resource } from '../../components/Resource';
import { url as serverBase } from '../../server.json';
import { accountService } from '../../services/account.service';

type claim = {
  name: string;
  firstname: string;
  email: string;
  link: {
    id: string;
    etablissement: string;
    role: string;
    createAt: Date;
    file: string;
  };
};

export const EtablissementClaims = () => {
  const { mutate } = useSWRConfig();
  const [error, setError] = useState("");
  moment.locale("fr");
  const FETCHING_URL = "/admin/accounts/ets/claims";

  const handleValidate = async (id: string) => {
    try {
      await accountService.updateClaimEtsStatus(id, "APPROVED");
      mutate(FETCHING_URL);
    } catch (e) {
      console.log(e);
      setError("Une erreur est survenue lors de la validation.");
    }
  };

  const handleDenied = async (id: string) => {
    try {
      await accountService.updateClaimEtsStatus(id, "DENIED");
      mutate(FETCHING_URL);
    } catch (e) {
      console.log(e);
      setError("Une erreur est survenue lors de la validation.");
    }
  };

  return (
    <div id="etablissement-claims">
      <h1>Claims</h1>
      {error && <p className="error">{error}</p>}
      <Resource
        url={FETCHING_URL}
        render={(claims: claim[]) => (
          <Table striped responsive>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Etablissement</th>
                <th>Role</th>
                <th>Créé le</th>
                <th>Document</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {claims.length === 0 && <p>Aucune demande pour l'instant.</p>}
              {claims.map((claim) => (
                <tr>
                  <td>{claim.name}</td>
                  <td>{claim.firstname}</td>
                  <td>{claim.email}</td>
                  <td>{claim.link.etablissement}</td>
                  <td>{claim.link.role}</td>
                  <td>
                    {moment(claim.link.createAt).format("dddd DD/MM/YYYY")}
                  </td>
                  <td>
                    <a
                      href={`${serverBase}/admin/accounts/ets/claims/document/${claim.link.file}`}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-info"
                    >
                      <FontAwesomeIcon icon={faEye} color="white" />
                    </a>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        handleValidate(claim.link.id);
                      }}
                    >
                      Valider
                    </Button>
                    <Button
                      onClick={() => {
                        handleDenied(claim.link.id);
                      }}
                    >
                      Refuser
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      />
    </div>
  );
};
