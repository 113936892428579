import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Table, Button } from "react-bootstrap";
import { url as serveur } from "../server.json";
import BesoinCreator from './pratiques-type-besoin-creator/BesoinCreator';
import PratiqueCreator from './pratiques-type-besoin-creator/PratiqueCreator';
import TypeProCreator from './pratiques-type-besoin-creator/TypeProCreator';
import UpdateSelector from './pratiques-type-besoin-creator/UpdateSelector';

const CreateTypePratique = () => {
    const [creator, setCreator] = useState(null);
    const modalCreator = () => {
        switch (creator) {
            case 'pratique':
                return <PratiqueCreator hide={() => setCreator(null)} />
            case 'typePro':
                return <TypeProCreator hide={() => setCreator(null)} />
            case 'besoin':
                return <BesoinCreator hide={() => setCreator(null)} />
            default:
                return <div></div>
        }
    }
    return <div>
        <UpdateSelector />
        <Button style={{ margin: '10px' }} onClick={() => setCreator('pratique')}>Créer une pratique</Button>
        <Button style={{ margin: '10px' }} onClick={() => setCreator('typePro')}>Créer un type de pro</Button>
        <Button style={{ margin: '10px' }} onClick={() => setCreator('besoin')}>Créer un besoin</Button>
        {
            <Modal
                show={creator}
                onHide={() => setCreator(null)}
            >
                {modalCreator()}
            </Modal>
        }

    </div>
}

export default CreateTypePratique;