import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Ecole, EcoleStatus } from '../../interfaces/Ecole';
import { ecoleService } from '../../services/ecole.service';
import { trainingOrganizationService } from '../../services/training-organization.service';
import { RemoveEcoleModal } from './RemoveEcoleModal';
import { UpdateEcoleModal } from './UpdateEcoleModal';

export const EcoleListItem = ({
  ecole,
  onDelete,
}: {
  ecole: Ecole;
  onDelete: Function;
}) => {
  const [loading, setLoading] = useState(false);
  const [askRemove, setAskRemove] = useState(false);
  const [editing, setEditing] = useState(false);
  const [denomination, setDenomination] = useState("");
  const [pratiques, setPratiques] = useState([""]);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (ecole) {
      setDenomination(ecole.denomination);
      setPratiques(ecole.practices);
      setStatus(ecole.status);
    }
  }, []);

  const handleStatusChange = async () => {
    let newStatus;

    if (status === "PUBLISHED") {
      newStatus = "DRAFT";
      const result = await trainingOrganizationService.toDraft(ecole._id);
    } else {
      newStatus = "PUBLISHED";
      const result = await trainingOrganizationService.publish(ecole._id);
    }
    setStatus(newStatus);
  };

  return (
    <>
      <tr>
        <td>{denomination}</td>

        <td>
          {pratiques.map((pratique: any) => pratique.libelle).join(" - ")}
        </td>
        <td className="">
          <div className="d-flex align-items-center justify-content-center">
            Brouillon
            <label className="switch">
              <input
                type="checkbox"
                checked={status === "PUBLISHED"}
                onChange={handleStatusChange}
              />
              <span className="slider round"></span>
            </label>
            Public
          </div>
        </td>
        <td>
          <div className="d-flex">
            <Button
              variant="info"
              disabled={loading}
              className="mx-1"
              onClick={() => setEditing((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faEdit} color="white" />
            </Button>
            <Button
              variant="danger"
              onClick={() => setAskRemove((prev) => !prev)}
            >
              <FontAwesomeIcon icon={faTrash} color="white" />
            </Button>
          </div>
        </td>
      </tr>
      <RemoveEcoleModal
        showRemoveModal={askRemove}
        setShowRemoveModal={setAskRemove}
        onDelete={onDelete}
        ecole={ecole}
      />
      <UpdateEcoleModal
        showUpdateModal={editing}
        setShowUpdateModal={setEditing}
        ecole={ecole}
      />
    </>
  );
};
