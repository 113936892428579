import { Diplome } from "../interfaces/Diplome";
import axios from "axios";
import { url } from "../server.json"

class DiplomeService {
  async update(id: string, data: Partial<Diplome>): Promise<Diplome> {
    const res = await axios.put(`${url}/admin/diplomes/${id}`, data, {
      withCredentials: true
    });
    if (res.status !== 200) {
      return Promise.reject();
    }
    return res.data;
  }

  async approve(id: string, level: number): Promise<void> {
    const body = { action: 'approve', level };
    const res = await axios.put(`${url}/admin/diplomes/${id}/action`, body, {
      withCredentials: true
    });
    if (res.status !== 200) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  async deny(id: string): Promise<void> {
    const body = { action: 'deny' };
    const res = await axios.put(`${url}/admin/diplomes/${id}/action`, body, {
      withCredentials: true
    });
    if (res.status !== 200) {
      return Promise.reject();
    }
    return Promise.resolve();
  }

  async delete(_id: string): Promise<void> {
    const res = await axios.delete(`${url}/admin/diplomes/${_id}`, {
      withCredentials: true
    })
    if (res.status !== 200) {
      return Promise.reject()
    }
    return Promise.resolve()
  }
}

export const diplomeService = new DiplomeService()