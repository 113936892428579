import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Diplome } from "../../interfaces/Diplome";
import { ecoleService } from "../../services/ecole.service";
import { trainingOrganizationService } from "../../services/training-organization.service";
import { DiplomeListItem } from "./DiplomeListItem";

export const DiplomeList = ({ diplomes }: { diplomes: Diplome[] }) => {
  const [ecoles, setEcole] = useState([]);

  useEffect(() => {
      (async () => {
        const ecolesPublished = await trainingOrganizationService.findAll();
  
        setEcole(ecolesPublished);
      })();      
  }, []);

  if (diplomes.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Nom</th>
          <th>Profession</th>
          <th>Diplôme</th>
          <th>RNCP</th>
          <th>Heures</th>
          <th>Ecole</th>
          <th>Fichier</th>
          <th>Commentaire</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {diplomes.map((diplome, i) => (
          <DiplomeListItem
            key={diplome._id}
            diplome={diplome}
            ecoles={ecoles}
          />
        ))}
      </tbody>
    </Table>
  );
};
