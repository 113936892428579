import { useEffect, useState } from "react";
import { studyService } from "../../../../services/study.service";
import { Button, ListGroup } from "react-bootstrap";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./study-list.scss";

const StudyList = ({ showEtude, update }) => {
  const [etudes, setEtudes] = useState(null);
  const [findAllError, setFindAllError] = useState(null);
  const [currentEtude, setCurrentEtude] = useState(null);

  const [createdEtude, setCreatedEtude] = useState(null);

  useEffect(async () => {
    if (!etudes && !findAllError) {
      await updateList();
    }
  });

  useEffect(async () => {
    if (update) {
      await updateList();
    }
  }, [update]);

  const create = async (etude) => {
    console.log(etude);
    await studyService.create(etude).then(async (res) => {
      setCurrentEtude(res.data.id);
      await updateList();
      showEtude(res.data.id);
    });
  };

  const updateList = async () => {
    await studyService
      .findAll()
      .then((result) => {
        console.log("result", result);
        setEtudes(result.data);
      })
      .catch((error) => {
        setFindAllError(error);
        console.log(error);
        console.log(findAllError);
      });
  };

  return (
    (!etudes && !findAllError && <p>Fetching ...</p>) ||
    (findAllError && (
      <p>Une erreur est survenue lors de la récuperation des données</p>
    )) ||
    (etudes && (
      <div
        className="etudes-list"
        style={{ minWidth: "350px", height: "800px" }}
      >
        <Button
          onClick={() => {
            create({
              name: "Nom à modifier",
              forms: [],
              visibility: { kind: "CLOSED" },
            });
          }}
          style={{
            height: "45px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Créer une étude
        </Button>
        {etudes && (
          <ListGroup>
            {etudes.map((etude, i) => (
              <ListGroup.Item
                key={i}
                style={{
                  height: "55px",
                  backgroundColor:
                    currentEtude && currentEtude === etude.id
                      ? "rgba(0, 0, 0, .1)"
                      : "transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center !important",
                  }}
                >
                  <p>{etude.name}</p>
                  <div>
                    <Button
                      variant="light outline-none"
                      disabled={etude.id === currentEtude}
                      onClick={() => {
                        showEtude(etude.id);
                        setCurrentEtude(etude.id);
                      }}
                      style={{}}
                    >
                      <FontAwesomeIcon
                        icon={faExpand}
                        size="lg"
                        color="black"
                      />
                    </Button>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    ))
  );
};

export default StudyList;
