import React, { useEffect, useState, useRef, createContext, useContext } from 'react'
import { DeleteFilled, PlusOutlined, FormOutlined } from '@ant-design/icons'
import { TweenOneGroup } from 'rc-tween-one'
import { Button, Col, Divider, Row, Modal, Collapse, Empty, Select, Form, Input, InputNumber, Tag } from 'antd'
import { PratiquesService } from '../../services/pratique.service';
import _ from 'underscore'
import './index.scss'

import ModalGlobalComponent from './ModalGlobalComponent';
import DisplayTypePro from './DisplayTypePro';
import { BesoinsContext, BesoinSelectedContext, PratiqueSelectedContext, TypeProSelectedContext, GlobalSourceContext } from './context';

const { Panel } = Collapse;
const { Search } = Input

const TypeProPratiquesBesoins = () => {
    const [configModal, setConfigModal] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [loadingTypePro, setLoadingTypePro] = useState(false)
    const [typePros, setTypePros] = useState([])
    // pour la barre de recherche toujours appeler setTypePros avec SetTypeProSaved
    const [typeProsSaved, setTypeProsSaved] = useState([])
    // mets a jour + dans form in mdoal
    const [pratiquesGlobal, setPratiquesGlobal] = useState([])
    const [besoinsGlobal, setBesoinsGlobal] = useState(null)
    // pagination
    const [page, setPage] = useState(1)
    const [limit, setlimit] = useState(10)
    // pour update directement les modal et le formulaire
    const [typepro_selected, setTypeProSelected] = useState(null)
    const [pratique_selected, setPratiqueSelected] = useState(null)
    const [besoin_selected, setBesoinSelected] = useState(null)
    // gere le flow de l'applciation
    const [update, setUpdate] = useState(false)
    // search bar
    const [search, setSearch] = useState("")
    const [panelState, setPanelState] = useState(null)
    // initial hook
    useEffect(async () => {
        setLoadingTypePro(true)
        const typepros = await PratiquesService.getAllTypePros(page, limit)
        const pratiques = await PratiquesService.getAllPratiques()
        const besoins = await PratiquesService.getAllBesoins()
        setTypePros(typepros.data)
        setPratiquesGlobal(pratiques.data)
        setBesoinsGlobal({
            besoins: besoins.data,
            setBesoinsGlobal: setBesoinsGlobal
        })
        setTypeProSelected({
            value: null,
            func: setTypeProSelected
        })
        setPratiqueSelected({
            value: null,
            func: setPratiqueSelected
        })
        setBesoinSelected({
            value: null,
            func: setBesoinSelected
        })
        // pour la recherche
        setTypeProsSaved(typepros.data)
        setLoadingTypePro(false)
    }, [])

    // update des query hook
    useEffect(async () => {
        if (update) {
            setLoadingTypePro(true)
            const typepros = await PratiquesService.getAllTypePros(page, limit)
            const pratiques = await PratiquesService.getAllPratiques()
            const besoins = await PratiquesService.getAllBesoins()
            setTypePros(typepros.data)
            setPratiquesGlobal(pratiques.data)
            setBesoinsGlobal({
                besoins: besoins.data,
                setBesoinsGlobal: setBesoinsGlobal
            })
            // pour la recherche
            setTypeProsSaved(typepros.data)
            if (search !== "") {
                const newTypePros = _.filter(typepros.data, (typepro) => {
                    return typepro.nom.includes(search)
                })
                setTypePros(newTypePros)
            }
            setUpdate(false)
            setLoadingTypePro(false)
        }
    }, [update])

    const handleClick = (type) => {
        setConfigModal(type)
        setModalOpen(true)
        setBesoinSelected({
            value: null, 
            func: setBesoinSelected
        })
        setPratiqueSelected({
            value: null, 
            func: setPratiqueSelected
        })
        setTypeProSelected({
            value: null, 
            func: setTypeProSelected
        })
    }
    const onSearch = (e) => {
        setSearch(e.target.value)
        if (e.target.value === '') {
            setTypePros(typeProsSaved)
        } else {
            const newTypePros = _.filter(typeProsSaved, (typepro) => {
                return typepro.nom.includes(e.target.value)
            })
            setTypePros(newTypePros)
        }
    }
    const updateTypeProClick = (e, typepro) => {
        e.stopPropagation()
        setTypeProSelected({
            value: typepro,
            func: setTypeProSelected
        })
        setConfigModal('typepro')
        setModalOpen(true)
    }
    return (
        <div className='container-typepro-pratiques-besoins'>
            <Row>
                <Col xs={8}>
                    <h4>TypePro - Pratiques - Besoins</h4>
                </Col>
                <Col xs={16} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button onClick={() => handleClick('typepro')}>
                        <PlusOutlined style={{ verticalAlign: 2 }}/>
                        TypePro
                    </Button>
                    <Divider type='vertical' />
                    <Button onClick={() => handleClick('pratique')}>
                        <PlusOutlined style={{ verticalAlign: 2 }}/>
                        Pratique
                    </Button>
                    <Divider type='vertical' />
                    <Button onClick={() => handleClick('besoin')}>
                        <PlusOutlined style={{ verticalAlign: 2 }}/>
                        Besoin
                    </Button>
                </Col>
            </Row>
            <GlobalSourceContext.Provider value={{
                setModalOpen: setModalOpen,
                setConfigModal: setConfigModal,
                pratiquesGlobal: pratiquesGlobal,
                setPratiquesGlobal: setPratiquesGlobal,
                typePros: typePros,
                setTypePros: setTypePros,
                setTypeProsSaved: setTypeProsSaved,
                setUpdate: setUpdate,
                modalOpen: modalOpen
            }}>
                <TypeProSelectedContext.Provider value={typepro_selected}>
                    <PratiqueSelectedContext.Provider value={pratique_selected}>
                        <BesoinSelectedContext.Provider value={besoin_selected}>
                            <Row style={{ marginTop: 20 }}>
                                <Col xs={24}>
                                    <h6>List TypePro</h6>
                                    <Search value={search} placeholder="Chercher un typepro" onChange={onSearch} style={{ width: 300, marginBottom: 20 }} />
                                    {
                                        loadingTypePro ? (<>loading...</>) : (
                                            typePros.length === 0 ? (
                                                <Empty />
                                            ) : (
                                                <Collapse accordion onChange={setPanelState} activeKey={panelState !== null ? panelState : -1}>
                                                    {
                                                        typePros.map((typepro, id) => {
                                                            return (
                                                                <Panel header={typepro.nom} extra={<div className=''><FormOutlined  style={{ cursor: 'default' }} onClick={(e) => updateTypeProClick(e, typepro) }/></div>}>
                                                                    <BesoinsContext.Provider value={besoinsGlobal} >
                                                                        <DisplayTypePro 
                                                                            key={id}
                                                                            typepro={typepro}
                                                                            pratiquesGlobal={pratiquesGlobal}
                                                                        />
                                                                    </BesoinsContext.Provider>
                                                                </Panel>
                                                            )
                                                        })
                                                    }
                                                </Collapse>
                                            )
                                            )
                                        }
                                </Col>
                            </Row>
                            <ModalGlobalComponent
                                modalOpen={modalOpen}
                                configModal={configModal}
                                setModalOpen={setModalOpen}
                            />
                            {/* <ModalGlobal
                                modalOpen={modalOpen}
                                configModal={configModal}
                                setModalOpen={setModalOpen}
                                pratique_select={pratique_select}
                                pratiques={pratiques}
                                setPratiques={setPratiques}
                                setTypePros={setTypePros}
                                setTypeProsSaved={setTypeProsSaved}
                                typePros={typePros}
                                setUpdate={setUpdate}
                                besoins={besoins}
                                besoin_selected={besoin_selected}
                                setBesoinSelected={setBesoinSelected}
                                setBesoinUpdateSelected={setBesoinUpdateSelected}
                                besoinUpdateSelected={besoinUpdateSelected}
                                setBesoins={setBesoins}
                                pratiqueRef={pratiqueRef}
                                setPratiqueRef={setPratiqueRef}
                            /> */}
                        </BesoinSelectedContext.Provider>
                    </PratiqueSelectedContext.Provider>
                </TypeProSelectedContext.Provider>
            </GlobalSourceContext.Provider>
        </div>
    )
}

export default TypeProPratiquesBesoins