import axios from 'axios';
import mongoose from 'mongoose';

import { PaginatedPros } from '../interfaces/PaginatedPros';
import { url as back } from '../server.json';

export const ProService = {
  async findAll(limit?: number, page?: number, subscription?: string): Promise<PaginatedPros> {
    try {
      const result = await axios.get(
        `${back}/admin/pro/?limit=${limit ?? 10}&page=${page ?? 1}${subscription ? "&subscription=" + subscription : ''}`,
        { withCredentials: true }
      );
      return result.data;
    } catch (e) {
      return Promise.reject();
    }
  },
  getPro() {
    return fetch(`${back}/admin/pro/subscriptions`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },
  acceptSyndicat(_id: string, typePro: number) {
    return fetch(
      `${back}/admin/pro/${_id}/syndicat/accept?typePro=${typePro}`,
      {
        method: "PUT",
        credentials: "include",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      }
    ).then((res) => res.json());
  },
  declineSyndicat(_id: string, typePro: number) {
    return fetch(
      `${back}/admin/pro/${_id}/syndicat/decline?typePro=${typePro}`,
      {
        method: "PUT",
        credentials: "include",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
      }
    ).then((res) => res.json());
  },

  updateKbis(_id: string, siret: string) {
    console.log(_id, siret);
    return fetch(`${back}/admin/pro/kbis/${_id}`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ siret }),
    }).then((res) => res.json());
  },

  updateReport(
    idReport: mongoose.Types.ObjectId,
    isConfirmed: boolean
  ): Promise<any> {
    return fetch(`${back}/admin/pro/${idReport}/report`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ isConfirmed }),
    }).then((res) => res.json());
  },

  updateBlacklisted(idPro: string, isBlacklisted: boolean): Promise<any> {
    return fetch(`${back}/admin/pro/${idPro}/blacklisted`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ isBlacklisted }),
    }).then((res) => res.json());
  },
};
