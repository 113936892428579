import axios from "axios";
import { Study } from '../models/study.model'
import { url as serveur } from "../server.json";

const instance = axios.create({
    withCredentials: true,
})

class StudyService {


    async findAll(): Promise<Study[]> {
        return await instance.get(`${serveur}/etudes/admin/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }

    async find(id: number): Promise<Study> {
        return await instance.get(`${serveur}/etudes/admin/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }

    async create(etude: Study): Promise<Study> {
        console.log("etude", etude)
        return await instance.post(`${serveur}/etudes/admin/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: etude
        });
    }

    async update(etude: Study): Promise<Study> {
        return await instance.put(`${serveur}/etudes/admin/` + etude.id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: etude
        });
    }
}

export const studyService = new StudyService();