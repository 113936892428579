import axios from "axios";
import { url as serveur } from '../../../../server.json';

const instance = axios.create({
    withCredentials: true,
})

class EtudeLocaleService {

    async create(etudeLocale) {
        return await instance.post(`${serveur}/etudes-locales/admin-panel/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: etudeLocale
        });
    }

}

export const etudeLocaleService = new EtudeLocaleService();