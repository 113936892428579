import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Diplome } from '../../interfaces/Diplome';
import { Ecole } from '../../interfaces/Ecole';
import { url } from '../../server.json';
import { diplomeService } from '../../services/diplome.service';
import { Spin } from 'antd';

export const UpdateDiplomeModal = ({
  diplome,
  ecoles,
  editParent,
  setShowEditModal,
  setDiplomeGeneral
}: {
  diplome: Diplome;
  ecoles: any[];
  editParent: (diplome: Diplome) => any;
  setShowEditModal: any;
  setDiplomeGeneral?: any;
}) => {
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState(diplome.title || "");
  const [rncp, setRncp] = useState(diplome.rncp || "");
  const [comment, setComment] = useState(diplome.comment || "");
  const [ecole, setEcole] = useState(diplome.trainingOrganization?._id);
  const [status, setStatus] = useState(diplome.status || "")
  const [numberOfHours, setNumberOfHours] = useState(
    diplome.numberOfHours || 0
  );
  const [level, setLevel] = useState(diplome.level)

  const handleDiplomeEdit = async (e: any) => {
    e.preventDefault();
    setLoading(true)
    const data: Partial<Diplome> = {
      comment,
      numberOfHours,
      title,
    };

    if (rncp) {
      data.rncp = rncp;
    }
    if (ecole) {
      data.trainingOrganization = ecole;
    }
    if (status !== "UNDER_REVIEW") {
      data.status = status
    }
    if (level) {
      data.level = level
    }
    try {
      const updatedDiplome = await diplomeService.update(diplome._id, data);
      editParent(updatedDiplome);
      setLoading(false)
      setShowEditModal(false)
      setDiplomeGeneral(true)
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Modifier un Diplôme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleDiplomeEdit}>
          <div className="row">
            <Form.Group className="mb-2 col-6">
              <Form.Label htmlFor="sigle">Nom professionnel</Form.Label>
              <p>
                <b>{diplome.pro?.name}</b>
              </p>
            </Form.Group>
            <Form.Group className="mb-2 col-6">
              <Form.Label htmlFor="sigle">Profession</Form.Label>
              <p>
                <b>{diplome.pro?.profession}</b>
              </p>
            </Form.Group>
            {
              diplome.status !== "UNDER_REVIEW" ? (
                <>
                  <Form.Group className="mb-2 col-6">
                    <Form.Label htmlFor="sigle">Status</Form.Label>
                    <Form.Select value={status} onChange={(e) => setStatus(e.target.value.trim())}>
                      <option value="ACCEPTED">ACCEPTED</option>
                      <option value="DECLINED">DECLINED</option>
                    </Form.Select>
                  </Form.Group>            
                  <Form.Group className="mb-2 col-6">
                    <Form.Label htmlFor="sigle">Niveau</Form.Label>
                    <Form.Control type="number" value={level} onChange={(e) => setLevel(parseInt(e.target.value))} placeholder="le niveau du diplome" />
                  </Form.Group>            
                </>
              ) : (null)
            }
          </div>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Intitulé</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="sigle"
              id="sigle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">RNCP</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="sigle"
              id="sigle"
              value={rncp}
              onChange={(e) => setRncp(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Ecole</Form.Label>
            <div>
              <select
                className="form-control"
                name="level"
                id="level"
                value={ecole}
                onChange={(e) => setEcole(e.target.value)}
              >
                <option value="">--Selectionner une école---</option>
                {ecoles
                  .sort((a: any, b: any) =>
                    a.denomination.localeCompare(b.denomination)
                  )
                  .map((ecole: Ecole) => (
                    <option value={ecole._id} key={ecole._id}>
                      {ecole.denomination}
                    </option>
                  ))}
              </select>
            </div>
          </Form.Group>

          {diplome.numberOfHours && <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Nombre d'heures</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="numberOfHours"
              id="numberOfHours"
              value={numberOfHours}
              onChange={(e) => setNumberOfHours(parseInt(e.target.value) || 0)}
            />
          </Form.Group>}

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Fichier</Form.Label>
            <div>
              <a
                href={`${url}/diplomes/${diplome._id}/file`}
                target="_blank"
                rel="noreferrer"
                className="btn btn-info"
              >
                <FontAwesomeIcon icon={faEye} color="white" />
              </a>
            </div>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Commentaire</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              name="sigle"
              id="sigle"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>

          <div className="d-flex justify-content-between">
            <Button type="submit" variant="primary">
              Mettre à jour { loading ? <Spin /> : null }
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </>
  );
};
