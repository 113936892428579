import { useState } from 'react';
import StudyList from './components/study-list/StudyList';
import StudyUpdate from './components/study-update/StudyUpdate';
import './study.scss';

const Study = () => {

    const [etudeId, setEtudeId] = useState(null);
    const [updateList, SetUpdateList] = useState(false);


    const showEtude = (etudeId) => {
        console.log("showEtude")
        setEtudeId(etudeId);
    }

    const hasUpdated = () => {
        console.log("hasUpdated")
        SetUpdateList(true);
        SetUpdateList(false);
    }

    return (
        <div className="create-etude row">
            <div className="col col-3">
                <StudyList showEtude={showEtude} update={updateList} />
            </div>
            <div className="col col-9">
                <StudyUpdate etudeId={etudeId} hasUpdated={hasUpdated}></StudyUpdate>
            </div>
        </div>
    )

}

export default Study;