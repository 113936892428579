import axios from "axios";
import { Establishment } from "../models/establisment.model";
import { url as serveur } from "../server.json";

const instance = axios.create({
    withCredentials: true,
})

class EstablishmentService {

    async findById(id: string): Promise<Establishment> {
        return await instance.get(`${serveur}/etablissements/` + id, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }

    async findAllEtsByName(name: string): Promise<Establishment[]> {
        return await instance.get(`${serveur}/etablissements/name-like/` + name, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }
}

export const establishmentService = new EstablishmentService();