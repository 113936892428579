import { Form } from './form.model'

export enum VisibilityKind {
    RESTRICTED = "RESTRICTED",
    OPENED = "OPENED",
    CLOSED = "CLOSED",
}

export interface Study {
    id: String,
    name: String,
    forms: [Form],
    visibility: {
        kind: VisibilityKind,
        etablissementIds?: String
    }
}