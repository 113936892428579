import { useState, useEffect } from 'react';
import { Diplome, DiplomeStatus } from '../../interfaces/Diplome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UpdateDiplomeModal } from './UpdateDiplomeModal';
import { Modal, Button } from 'react-bootstrap';
import { trainingOrganizationService } from '../../services/training-organization.service';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Modal as mant } from 'antd';
import { diplomeService } from '../../services/diplome.service';

const { confirm } = mant 

const statusToColor = (status: DiplomeStatus): string => {
  switch (status) {
    case DiplomeStatus.ACCEPTED:
      return 'lime';
    case DiplomeStatus.DECLINED:
      return 'red';
    case DiplomeStatus.UNDER_REVIEW:
      return 'orange';
  }
};

export const VerifiedDiplomeListItem = ({ diplome, setDip, dip }: { diplome: Diplome, setDip: any, dip: Array<Diplome> }) => {
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [diplm, setDiplome] = useState(diplome);
  const [ecoles, setEcole] = useState([]);

  useEffect(() => {
    (async () => {
      const ecolesPublished = await trainingOrganizationService.findAll();

      setEcole(ecolesPublished);
    })();
  }, []);

  const editParent = (newDiplome: Diplome) => {
    const result = { ...diplm };
    result.comment = newDiplome.comment;
    result.trainingOrganization = newDiplome.trainingOrganization;
    result.numberOfHours = newDiplome.numberOfHours;
    result.rncp = newDiplome.rncp;
    result.title = newDiplome.title;
    result.status = newDiplome.status;
    result.level = newDiplome.level;
    setDiplome(result);
  };
  return (
    <tr style={{ borderLeft: `${statusToColor(diplome.status)} 4px solid` }}>
      <td>{new Date(diplome.createdAt).toLocaleDateString()}</td>
      <td>{new Date(diplome.updatedAt).toLocaleDateString()}</td>
      <td>{diplome.pro?.name} <br /> {diplome.pro?.email}</td>
      <td>{diplome.pro?.profession}</td>
      <td>{diplome.title}</td>
      <td>{diplome.rncp}</td>
      <td>{diplome.numberOfHours}</td>
      <td>{diplome.comment}</td>
      <td>{diplome.level}</td>
      <td>
          <Button
            variant='info'
            disabled={loading}
            className="mx-1"
            onClick={() => setShowEditModal((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faEdit} color="white" />
          </Button>
          <Button
            variant="danger"
            className="mx-1"
            onClick={() => confirm({
              title: 'Vous êtes sûr de vouloir supprimer ce diplome?',
              icon: <ExclamationCircleFilled />,
              content: '',
              onOk() {
                async function del() {
                  const res = await diplomeService.delete(diplome._id)
                  setDip(dip.filter(item => item._id !== diplome._id))
                }
                del()
              },
              onCancel() {
                console.log('Cancel');
              },
            })}
          >
            <DeleteOutlined />
          </Button>
          <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <UpdateDiplomeModal
              editParent={editParent}
              diplome={diplm}
              ecoles={ecoles}
              setShowEditModal={setShowEditModal}
            />
          </Modal>
      </td>
    </tr>
  );
};
