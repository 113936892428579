import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { StringLiteralLike } from "typescript";
import { Avis, Report } from "../../interfaces/Avis";
import { avisService } from "../../services/avis";
import { Rate } from "./star";


export interface IReportedAvisListItemProps {
  avis: Avis;
  setCurrentReports: (reports: Report[]) => void
  setShowModal: (show: boolean) => void
}

export const ReportedAvisListItem = ({
  avis,
  setCurrentReports,
  setShowModal
}: IReportedAvisListItemProps) => {

  const loadReports = async (avis: Avis) => {
    const reports = await avisService.getReportsDetailed(avis._id, avis.etablissement ? true : false);
    setCurrentReports(reports);
    setShowModal(true);
  }

  const approve = async (id: string) => {
    //setLoading(true);
    try {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Cette action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, approuver l'avis",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "validate-btn",
          cancelButton: "cancel-btn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await avisService.approveAfterReport(avis._id, avis.etablissement ? true : false);
        }
      })
      setShowModal(false);
      //updateList();
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreur est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      //setLoading(false);
    }
  };

  const deny = async (id: string) => {
    //setLoading(true);
    try {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Cette action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, ne pas approuver l'avis",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "validate-btn",
          cancelButton: "cancel-btn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await avisService.denyAfterReport(avis._id, avis.etablissement ? true : false);
        }
      })
      setShowModal(false);
      //updateList();
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreur est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      //setLoading(false);
    }
  };

  const decryptStatut = (statut: number) => {
    if (statut === 0) {
      return "UNVERIFIED"
    }
    if (statut === 1) {
      return "APPROVED"
    }
    if (statut === 2) {
      return "APPROVED_AND_MODIFIED"
    }
    if (statut === 3) {
      return "DENIED"
    }
    if (statut === 4) {
      return "REPORTED"
    }
    if (statut === 5) {
      return "UPDATED_BY_USER"
    }
  }
  return (
    <tr>
      <td>
        <Button variant="primary" size="sm" onClick={() => loadReports(avis)}>
          Voir le détail
        </Button>
        <Button variant="success" size="sm" onClick={() => approve(avis._id)}>
          Valider
        </Button>
        <Button variant="danger" size="sm" onClick={() => deny(avis._id)}>
          Refuser
        </Button>
      </td>
      <td>
        <Rate
          className="stars"
          note={avis.note}
          total={5}
          height="20px"
          full={undefined}
          empty={undefined}
        />
      </td>
      <td>{avis.etablissement?.raisonSociale}</td>
      <td>{avis.pseudo}</td>
      <td>{avis.email}</td>
      <td>{avis.titre}</td>
      <td>{avis.plus}</td>
      <td>{avis.moins}</td>
      <td>{new Date(avis.date).toLocaleDateString()}</td>
      <td>{decryptStatut(avis.statut)}</td>
    </tr>
  );
};
  