import React, { useState } from 'react';
import { Pratique } from '../../../interfaces/Pratique';
import { Resource } from '../../../components/Resource';
import { Modal, Table, Button, InputGroup, FormControl } from "react-bootstrap";
import axios from 'axios';
import { url as serveur } from "../../../server.json";

const UpdateTypePro = () => {
    const [hasUpdateModal, setHasUpdateModal] = useState<boolean>(false);
    const [deleted, setDelete] = useState<Boolean>(false);
    const [typepro, setTypepro] = useState<any>({
        libelle: '',
        inm: "",
        inmLib: "",
        type: "",
        typeLib: "",
        mca: "",
        definition: "",
    });
    const keyPratique = {
        id: '',
        nom: '',
        idParent: '',


    }

    const update = () => {
        if (typepro) {
            axios({
                url: `${serveur}/typepro/${typepro._id}`,
                method: 'PUT',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                data: typepro
            }).then((e) => {
                console.log(e)
                if (e.status === 200) {
                    setHasUpdateModal(false);
                }
            });
        }
    }
    const deleteItem = () => {
        if (typepro) {
            //TODO CHANGE TO FETCH
            axios({
                url: `${serveur}/typepro/${typepro._id}`,
                method: 'DELETE',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }).then((e) => {
                console.log(e)
                if (e.status === 200) {
                    setHasUpdateModal(false);
                    setDelete(false);
                }
            });
        }
    }

    console.log(typepro);
    return <>
        <Resource<Pratique[]>
            url={'/typepro/'}
            render={(data) => (
                <Table striped bordered hover>
                    {data.map((_typepro, o) => (

                        <tr key={o}
                            onClick={() => {
                                setTypepro(_typepro);
                                setHasUpdateModal(true);
                                setDelete(false);
                            }}
                        >
                            {
                                Object.values(_typepro).map((id, k) => (
                                    typeof id !== 'object' && <td key={k}>{id}</td>
                                ))
                            }
                        </tr>
                    ))
                    }
                </Table>
            )}
        />
        <Modal
            show={hasUpdateModal}
            onHide={() => setHasUpdateModal(false)}
        >
            <Modal.Title>Modifier</Modal.Title>
            <Modal.Body>
                {typepro && Object.keys(keyPratique).map((idName, k) => (
                    idName !== '_id' &&
                    <InputGroup key={k} className="mb-3">
                        <InputGroup.Text id="basic-addon1">{idName}</InputGroup.Text>
                        <FormControl
                            placeholder={idName}
                            aria-label={idName}
                            aria-describedby="basic-addon1"
                            value={typepro[idName]}
                            onChange={(e) => setTypepro({
                                ...typepro,
                                [idName]: e.target.value
                            })}
                        />
                    </InputGroup>
                ))}
            </Modal.Body>
            <Modal.Footer>
                {deleted && <Button variant='danger' onClick={() => deleteItem()}>Confirmer</Button>}
                <Button variant='danger' onClick={() => setDelete(true)}>Supprimer</Button>
                <Button variant='secondary' onClick={() => setHasUpdateModal(false)}>Annuler</Button>

                <Button variant='success' onClick={() => update()}>Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default UpdateTypePro;