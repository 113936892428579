import {url as back} from '../server.json';

export const CabinetProService = {

  acceptCabinet(_id: string) {
    return fetch(`${back}/admin/cabinet-pro/${_id}/accept`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  declineCabinet(_id: string) {
    return fetch(`${back}/admin/cabinet-pro/${_id}/decline`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  deleteCabinet(_id: string) {
    return fetch(`${back}/admin/cabinet-pro/${_id}`, {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  /*getAccountLinkedToCabinet(_id: string) {
    return fetch(`${back}/admin/pro/cabinet/linkedPro/${_id}`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  }*/
}
