import {url as back} from '../server.json';

export const PartnershipService = {

  getForm(applicant: string) {
    return fetch(`${back}/admin/partnership/${applicant}/form`, {
      method: "GET",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  validateQuestionnary(applicant: string) {
    return fetch(`${back}/admin/partnership/${applicant}/validate`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  declineQuestionnary(applicant: string) {
    return fetch(`${back}/admin/partnership/${applicant}/decline`, {
      method: "PUT",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

  deleteQuestionnary(applicant: string) {
    return fetch(`${back}/admin/partnership/${applicant}`, {
      method: "DELETE",
      credentials: "include",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((res) => res.json());
  },

}