import { useEffect, useMemo, useState } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Table from "react-bootstrap/esm/Table";
import { Paginated } from "../../../interfaces/Paginated";
import { Pagination } from "../../../components/Pagination";
import { ReportedProsListItem } from "./ReportedProsListItem";
import { Pro } from "../../../interfaces/Pro";
import { Report } from "../../../interfaces/Avis";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { ProService } from "../../../services/pro.service";
import mongoose from "mongoose";
import { ReportedProsListItemBlacklisted } from "./ReportedProsListItemBlacklisted";

export type ReportedProsListProps = {
  data: Pro[];
};

export const ReportedProsListBlacklisted = ({
  data,
}: ReportedProsListProps) => {
  const [currentReports, setCurrentReports] = useState<Report[]>([]);
  const [showModal, setShowModal] = useState(false);


  const [loading, setLoading] = useState(false);

  const approve = async (idReport: mongoose.Types.ObjectId) => {
    setLoading(true);
    try {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Souhaites-tu vraiment confirmer ce signalement ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, confirmer le signalement",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "validate-btn",
          cancelButton: "cancel-btn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProService.updateReport(idReport, true);
        }
      })
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreur est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  };

  const deny = async (idReport: mongoose.Types.ObjectId) => {
    setLoading(true);
    try {
      Swal.fire({
        title: "Êtes-vous sûr ?",
        text: "Souhaites-tu vraiment rejeter ce signalement ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, rejeter le signalement",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "validate-btn",
          cancelButton: "cancel-btn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ProService.updateReport(idReport, false);
        }
      })
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreur est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      setLoading(false);
      setShowModal(false);
    }
  }

  if (data.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Pro</th>
            <th>Mail</th>
            <th>Signalements</th>
            <th>Confirmés</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((pro, i) => (
            <ReportedProsListItemBlacklisted key={pro._id} pro={pro} setCurrentReports={setCurrentReports} setShowModal={setShowModal}/>
          ))}
        </tbody>
      </Table>
      <Modal 
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Signalements</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>Raison</th>
                <th>Commentaire</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentReports && currentReports.map((report:Report, i:Number) => (
                <tr key={`report-${i}`}>
                  <td>{new Date(report.date).toLocaleDateString()}</td> 
                  <td>{report.reason}</td>
                  <td>{report.text}</td>
                  <td>
                    <Button 
                    className="btn btn-success"
                    onClick={() => approve(report._id)}
                    >
                      Confirmer
                    </Button>
                    <Button 
                    className="btn btn-danger"
                    onClick={() => deny(report._id)}
                    >
                      Supprimer
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>      
    </>
  );
};
