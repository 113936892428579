import axios from 'axios';

import { url as serveur } from '../server.json';

const instance = axios.create({
  withCredentials: true,
});

class AccountService {
  async updateClaimEtsStatus(id: string, status: string) {
    const ret = await instance.put(
      `${serveur}/admin/accounts/ets/claim/${id}/`,
      { status }
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }
}

export const accountService = new AccountService();
