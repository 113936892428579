import { useContext, useState, useEffect } from "react"
import { Form, Input, Button } from "antd"
import { GlobalSourceContext, TypeProSelectedContext } from "./context"
import { PratiquesService } from "../../services/pratique.service"

const CreateTypePro = () => {
    const [form_typepro] = Form.useForm()
    let global = useContext(GlobalSourceContext)
    let typepro_selected = useContext(TypeProSelectedContext)
    const [updateState, setUpdateState] = useState(false)

    useEffect(() => {
        if (typepro_selected.value) {
            form_typepro.setFieldValue('nom', typepro_selected.value.nom) 
            setUpdateState(true)
        }
    }, [typepro_selected.value])

    const submitTypePro = async (values) => {
        console.log('create')
        const body = {
            nom: values.nom
        }
        const res = await PratiquesService.createTypePro(body)
        // mets a jour la liste des typepro avec le retour du serveur
        global.setTypePros(global.typePros.concat([res.data]))
        global.setTypeProsSaved(global.typePros.concat([res.data]))
        global.setModalOpen(false)
        form_typepro.resetFields()
    }
    const updateTypePro = async (values) => {
        console.log('update')
        const body = {
            ...typepro_selected.value,
            nom: values.nom
        }
        const res = await PratiquesService.updateTypePro(body)
        global.setUpdate(true)
        global.setModalOpen(false)
        form_typepro.resetFields()
        setUpdateState(false)
        // a la fin on supprime le typepro_selected
        typepro_selected.func({
            value: null,
            func: typepro_selected.func
        })
    }
    return (
        <Form
            name='create_typepro'
            onFinish={updateState ? updateTypePro : submitTypePro}
            form={form_typepro}
        >
            <Form.Item
                label="nom"
                name="nom"
            >
                <Input placeholder='Nom' />
            </Form.Item>
            <Form.Item>
                <Button htmlType='submit'>{updateState ? 'Mettre à jour le TypePro': 'Ajouter le TypePro'}</Button>
            </Form.Item>
        </Form> 
    )
}

export default CreateTypePro