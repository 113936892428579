import { useCallback, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import Col from "react-bootstrap/esm/Col";
import { AvisList } from "../components/avis/AvisList";
import { TrainingOrganizationReviewList } from "../components/avis/TrainingOrganizationReviewList";
import { TrainingOrganizationVerifiedReviewList } from "../components/avis/TrainingOrganizationVerifiedReviewList";
import { VerifiedAvisetsList } from "../components/avis/VerifiedAvisList";
import { Resource } from "../components/Resource";
import { Paginated } from "../interfaces/Paginated";
import { TrainingOrganizationReview } from "../interfaces/TrainingOrganizationReview";

export const VerifyAvisOfPage = () => {
  const [tab, setTab] = useState<"verify" | "verified">("verify");
  const [page, setPage] = useState(0);
  const handleTabChange = useCallback((newTab: "verify" | "verified") => {
    setPage(0);
    setTab(newTab);
  }, []);

  const updateList = () => {
    let tmp = page;
    setPage(-1);
    setPage(tmp);
  };

  return (
    <>
      <Col className="d-flex justify-content-between mb-4">
        <h1>Modération avis OF</h1>
        <div className="d-flex align-items-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              style={{ opacity: tab === "verify" ? 1 : 0.7 }}
              onClick={() => handleTabChange("verify")}
            >
              À vérifier
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "verified" ? 1 : 0.7 }}
              onClick={() => handleTabChange("verified")}
            >
              Vérifié
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col>
        {tab === "verify" && (
          <Resource<Paginated<TrainingOrganizationReview>>
            url={`/admin/training-organization/unverified-reviews/${page}/?sort=date_desc`}
            render={(data) => (
              <TrainingOrganizationReviewList
                type="avisof"
                data={data}
                setPage={setPage}
                updateList={updateList}
              />
            )}
          />
        )}
        {tab === "verified" && (
          <Resource<Paginated<TrainingOrganizationReview>>
            url={`/admin/training-organization/verified-reviews/${page}/?sort=date_desc`}
            render={(data) => (
              <TrainingOrganizationVerifiedReviewList
                data={data}
                setPage={setPage}
              />
            )}
          />
        )}
      </Col>
    </>
  );
};
