import ReactPaginate from 'react-paginate';
import { Paginated } from '../interfaces/Paginated';

export type PaginationProps<T> = {
  data: Paginated<T>;
  setPage: (selected: number) => void;
};

export function Pagination<T>({ data, setPage }: PaginationProps<T>) {
  const pageCount = data.itemsCount / data.itemsPerPage;
  if (pageCount <= 1) return null;
  return (
    <ReactPaginate
      previousLabel={"«"}
      nextLabel={"»"}
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      forcePage={data.page}
      onPageChange={(pagectx) => setPage(pagectx.selected)}
      containerClassName={"pagination justify-content-center"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
      previousLinkClassName={"page-link"}
      activeClassName={"active"}
    />
  );
}
