import axios from 'axios';
import { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { url as back } from '../../server.json';
import { ecoleService } from '../../services/ecole.service';
import { trainingOrganizationService } from '../../services/training-organization.service';

export const CreateEcoleModal = ({
  showCreateModal,
  setShowCreateModal,
}: any) => {
  const [error, setError] = useState("");
  const [sigle, setSigle] = useState("");
  const [denomination, setDenomination] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [street, setStreet] = useState("");
  const [cp, setCp] = useState();
  const [city, setCity] = useState("");
  const [url, setUrl] = useState("");

  const createSchool = async (e: any) => {
    e.preventDefault();
    const data = {
      sigle,
      contactMail,
      url,
      denomination,
      address: {
        street,
        cp,
        city,
      },
    };
    try {
      const trainingOrganization = await trainingOrganizationService.create(
        data
      );
      // setEcoles([...ecoles, newEcole.data])
      setShowCreateModal(false);
    } catch (err: any) {
      setError(err.response.data.message);
      console.error(err.response);
    }
  };

  const handleSigleChange = (e: any) => {
    setSigle(e.target.value);
  };

  const handleDenominationChange = (e: any) => {
    setDenomination(e.target.value);
  };

  const handleContactMailChange = (e: any) => {
    setContactMail(e.target.value);
  };

  const handleUrlChange = (e: any) => {
    setUrl(e.target.value);
  };

  const handleStreetChange = (e: any) => {
    setStreet(e.target.value);
  };

  const handleCityChange = (e: any) => {
    setCity(e.target.value);
  };

  const handleCpChange = (e: any) => {
    setCp(e.target.value);
  };

  return (
    <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Créer une École</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={createSchool}>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="denomination">Dénomination</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="denomination"
              id="denomination"
              value={denomination}
              onChange={handleDenominationChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Sigle</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="sigle"
              id="sigle"
              value={sigle}
              onChange={handleSigleChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="contactMail">Email de contact</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="contactMail"
              id="contactMail"
              value={contactMail}
              onChange={handleContactMailChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="url">Lien url</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="url"
              id="url"
              value={url}
              onChange={handleUrlChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="street">Rue et libellé de voie</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="street"
              id="street"
              value={street}
              onChange={handleStreetChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="city">Ville</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="city"
              id="city"
              value={city}
              onChange={handleCityChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="cp">Code postal</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="cp"
              id="cp"
              value={cp}
              onChange={handleCpChange}
            />
          </Form.Group>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button type="submit" variant="success">
            Valider
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
