import { Avis } from "../../interfaces/Avis";
import { Rate } from "./star";

export const VerifiedAvisetsListItem = ({ avis }: { avis: Avis }) => {
  const decryptStatut = (statut: number) => {
    if (statut === 0) {
      return "UNVERIFIED"
    }
    if (statut === 1) {
      return "APPROVED"
    }
    if (statut === 2) {
      return "APPROVED_AND_MODIFIED"
    }
    if (statut === 3) {
      return "DENIED"
    }
    if (statut === 4) {
      return "REPORTED"
    }
    if (statut === 5) {
      return "UPDATED_BY_USER"
    }
  }
  return (
    <tr>
      <td>
        <Rate
          className="stars"
          note={avis.note}
          total={5}
          height="20px"
          full={undefined}
          empty={undefined}
        />
      </td>
      <td>{avis.etablissement?.raisonSociale}</td>
      <td>{avis.pseudo}</td>
      <td>{avis.email}</td>
      <td>{avis.titre}</td>
      <td>{avis.plus}</td>
      <td>{avis.moins}</td>
      <td>{new Date(avis.date).toLocaleDateString()}</td>
      <td>{decryptStatut(avis.statut)}</td>
    </tr>
  );
};
