import { useState } from 'react';
import { Form, Modal, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { faEdit, faEye, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Diplome, DiplomeStatus } from '../../interfaces/Diplome';
import { Ecole } from '../../interfaces/Ecole';
import { url as serverBase } from '../../server.json';
import { diplomeService } from '../../services/diplome.service';
import { UpdateDiplomeModal } from './UpdateDiplomeModal';

const statusToColor = (status: DiplomeStatus): string => {
  switch (status) {
    case DiplomeStatus.ACCEPTED:
      return "lime";
    case DiplomeStatus.DECLINED:
      return "red";
    case DiplomeStatus.UNDER_REVIEW:
      return "orange";
  }
};

export const DiplomeListItem = ({
  diplome,
  ecoles,
}: {
  diplome: Diplome;
  ecoles: any[];
}) => {
  const [diplm, setDiplome] = useState(diplome);
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [validateModalLevel, setvalidateModalLevel] = useState(-1);

  const handleValidate = () => {
    setShowValidateModal(true);
  };

  const handleValidateWithLevel = async () => {
    await diplomeService.approve(diplm._id, validateModalLevel);
    setShowValidateModal(false);
  };

  const handleDeny = async () => {
    setLoading(true);

    const sure = window.confirm(
      `Etes-vous sur que vous voulez refuser ce diplome?`
    );
    if (!sure) {
      return setLoading(false);
    }

    try {
      await diplomeService.deny(diplm._id);
    } catch (err) {
      console.error(err);
    }
  };

  const editParent = (newDiplome: Diplome) => {
    const result = { ...diplm };
    result.comment = newDiplome.comment;
    result.trainingOrganization = newDiplome.trainingOrganization;
    result.numberOfHours = newDiplome.numberOfHours;
    result.rncp = newDiplome.rncp;
    result.title = newDiplome.title;
    result.status = newDiplome.status;
    setDiplome(result);
  };

  const canValidate =
    diplm.trainingOrganization?.status === "PUBLISHED" ? true : false;
  const canValidateInModal = validateModalLevel >= 1;

  console.log(diplm)

  return (
    <tr style={{ borderLeft: `${statusToColor(diplm.status)} 4px solid` }}>
      <td>
        {diplm.pro?.name} <br /> {diplm.pro?.email}
      </td>
      <td>{diplm.pro?.profession}</td>
      <td>{diplm.title}</td>
      <td>{diplm.rncp}</td>
      <td>{diplm.numberOfHours}</td>
      <td>
        <div>
          <Row>
            {diplm.trainingOrganization?.denomination ??
              "L'école n'existe plus"}
          </Row>
          <Row>
            {"Email de contact: " +
              (diplm.trainingOrganization?.contactMail &&
              diplm.trainingOrganization?.contactMail.length !== 0
                ? diplm.trainingOrganization?.contactMail
                : "Inconnue")}
          </Row>
        </div>
      </td>

      <td>
        {diplm.file !== "" ? (
          <a
            href={`${serverBase}/diplomes/${diplm._id}/file`}
            target="_blank"
            rel="noreferrer"
            className="btn btn-info"
          >
            <FontAwesomeIcon icon={faEye} color="white" />
          </a>
        ) : (
          <Button disabled variant="secondary">
            <FontAwesomeIcon icon={faMinus} color="white" />
          </Button>
        )}
      </td>

      <td>{diplm.comment}</td>
      <td>
        <div className="d-flex justify-content-center">
          {diplm.otherEcole ? (
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-${diplm._id}-top`}>
                  École indiquée par le pro: <b>{diplm.otherEcole}</b>
                </Tooltip>
              }
            >
              <div className="warning-circle">!</div>
            </OverlayTrigger>
          ) : (
            ""
          )}
          <Button
            variant="info"
            disabled={loading}
            className="mx-1"
            onClick={() => setShowEditModal((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faEdit} color="white" />
          </Button>
          <Button
            variant="success"
            disabled={loading || !canValidate}
            className="mx-1"
            onClick={() => handleValidate()}
          >
            Valider
          </Button>
          <Button
            variant="danger"
            disabled={loading}
            onClick={() => handleDeny()}
          >
            Refuser
          </Button>
        </div>
      </td>
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <UpdateDiplomeModal
          editParent={editParent}
          diplome={diplm}
          ecoles={ecoles}
          setShowEditModal={setShowEditModal}
        />
      </Modal>
      <Modal
        show={showValidateModal}
        onHide={() => setShowValidateModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Valider un Diplôme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleValidateWithLevel}>
            <div>
              <select
                className="form-control"
                name="level"
                id="level"
                value={validateModalLevel}
                onChange={(e) =>
                  setvalidateModalLevel(parseInt(e.target.value))
                }
              >
                <option value="-1">
                  -- Sélectionnez le niveau du diplôme --
                </option>
                <option value="1">1 (Or)</option>
                <option value="2">2 (Argent)</option>
                <option value="3">3 (Bronze)</option>
              </select>
            </div>

            <div className="d-flex justify-content-between">
              <Button
                type="submit"
                variant="primary"
                disabled={!canValidateInModal}
              >
                Valider
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </tr>
  );
};
