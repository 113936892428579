import React, { useState } from 'react';
import { Modal, Table, Button } from "react-bootstrap";
import { Resource } from '../../components/Resource';
import type { Pratique } from '../../interfaces/Pratique';
import UpdateBesoin from './update/UpdateBesoin';
import UpdatePratique from './update/UpdatePratique';
import UpdateTypePro from './update/UpdateTypePro';


const UpdateSelector = () => {
    const [hasUpateModal, sethasUpateModal]= useState(false);
    const [path, setPath] = useState<string|null>(null);
    const buttonList = [ 'besoin', 'pratiques', 'typePro'];
 
    return (
        <div>
            <Button variant='primary' onClick={()=> sethasUpateModal(true)}>Modifier</Button> 
            {hasUpateModal && buttonList.map((b,k)=>(
                <Button variant='danger' style={{margin:'10px'}} onClick={()=> setPath(b)} key={k}>{b}</Button>
            ))}
            {
                path && <>
  
                        {
                            path === 'besoin' && <UpdateBesoin close={() => setPath(null)} />
                        }
                         {
                            path === 'pratiques' &&  <UpdatePratique />
                        }
                         {
                            path === 'typePro' && <UpdateTypePro />
                        }
                         </>
            }
        </div>
    )
}

export default UpdateSelector;