import { Col, Spinner, Table } from "react-bootstrap";
import { Resource } from "../../../components/Resource";
import { useCallback, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Paginated } from "../../../interfaces/Paginated";
import { Pro } from "../../../interfaces/Pro";
import { ReportedProsList } from "./ReportedProsList";
import { ReportedProsListBlacklisted } from "./ReportedProsListBlacklisted";


export const ReportedPros = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<"verify" | "blacklisted">("verify");
  const handleTabChange = useCallback((newTab: "verify" | "blacklisted") => {
    setTab(newTab);
  }, []);

  return (
    <div id="verify-pro">
      <Col className="d-flex justify-content-between mb-4">
        <div>
          <h1>Signalements</h1>
        </div>
        <div className="d-flex align-items-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              style={{ opacity: tab === "verify" ? 1 : 0.7 }}
              onClick={() => handleTabChange("verify")}
            >
              À vérifier
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "blacklisted" ? 1 : 0.7 }}
              onClick={() => handleTabChange("blacklisted")}
            >
              Blacklistés
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col>
        {
          tab === "verify" && (
            <Resource<Pro[]>
              interval={1000}
              url={`/admin/pro/reported`}
              render={(data) => (
                <ReportedProsList data={data}/>
              )}
            />
          )
        }
        {
          tab === "blacklisted" && (
            <Resource<Pro[]>
              interval={1000}
              url={`/admin/pro/blacklisted`}
              render={(data) => (
                <ReportedProsListBlacklisted data={data}/>
              )}
            />
          )
        }
      </Col>
    </div>
  );
};

