import { useState } from "react";
import { Col, Button, ButtonGroup, Table, Spinner } from "react-bootstrap";

import { Resource } from "../../../components/Resource";
import { CabinetProItem } from "./CabinetProItem";

export const VerifyProsCabinet = () => {
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState("verify")

    return (
        <div id="verify-cabinet">
          <Col className="d-flex justify-content-between mb-4">
            <div>
              <h1>Pro Cabinets</h1>
            </div>
            <div className="d-flex align-items-center">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant="secondary"
                  style={{ opacity: tab === "verify" ? 1 : 0.7 }}
                  onClick={() => setTab("verify")}
                >
                  À vérifier
                </Button>
                <Button
                  variant="secondary"
                  style={{ opacity: tab === "accepted" ? 1 : 0.7 }}
                  onClick={() => setTab("accepted")}
                >
                  Acceptés
                </Button>
                <Button
                  variant="secondary"
                  style={{ opacity: tab === "denied" ? 1 : 0.7 }}
                  onClick={() => setTab("denied")}
                >
                  Refusés
                </Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Cabinet</th>
                  <th>Membre(s)</th>
                  <th>Adresse</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading && <Spinner animation="border" />}
              {!loading && (
                <Resource
                  interval={1000}
                  url="/admin/cabinet-pro"
                  render={(data: any) => (
                    <tbody>
                      {data
                        .filter((cabinet: any) => {
                          if (tab === "accepted") {
                            return cabinet.status === "ACCEPTED"
                          } else if (tab === "denied") {
                            return cabinet.status === "DENIED"
                          } else {
                            return cabinet.status === "PENDING"
                          }
                        })
                        
                        .map((cabinet: any) => (<CabinetProItem cabinet={cabinet} key={cabinet.businessName}/>))}             
                        </tbody>
                  )}
                />
              )}
            </Table>
          </Col>
        </div>
      );
}
