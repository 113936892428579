import React, { useEffect, useState } from 'react'
import { NotesService } from '../services/notes.service'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import './notes_by_accounts.scss'

const defineColorNote = (note) => {
    if (note < 7) {
        return "red"
    } else if (note > 8) {
        return "green"
    } else {
        return "grey"
    }
}
const NotesByAccounts = () => {
    const [notes, setNotes] = useState([])
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [infos, setInfos] = useState(null)
    const [countNotes, setCountNotes] = useState(0)
    const [valueFilter, setValueFilter] = useState(0)
    useEffect(async () => {
        const res = await NotesService.getAllNotesByAccounts(page, perPage, valueFilter)
        setNotes(res.data.items)
        setCountNotes(res.data.count)
    }, [valueFilter])

    useEffect(async () => {
        const infos = await NotesService.getInformationsAboutNotes()
        setInfos(infos.data[0])
    }, [])

    const requestNewPage = async (pg) => {
        const res = await NotesService.getAllNotesByAccounts(pg, perPage, valueFilter)
        setNotes(res.data.items)
    }
    const filterByNote = (e) => {
        setValueFilter(parseInt(e.target.value))
    }
    return (
        <div className='container-notes'>
            <div className='notes-visualisation row'>
                {
                    infos !== null ? (
                        <>
                            <div className='col-4 flex-center'>
                                <div style={{ marginBottom: 20 }}>note la plus basse</div>
                                <div style={{ fontSize: 18 }}>{infos.minNote}</div>
                            </div>
                            <div className='col-4 flex-center'>
                                <div style={{ marginBottom: 20 }}>moyenne</div>
                                <div style={{ fontSize: 18, color: infos.avgNote >= 7 ? ("green") : ("red") }}>{parseFloat(infos.avgNote).toFixed(2)}</div>
                            </div>
                            <div className='col-4 flex-center'>
                                <div style={{ marginBottom: 20 }}>note la plus haute</div>
                                <div style={{ fontSize: 18 }}>{infos.maxNote}</div>
                            </div>
                        </>
                    ) : (null)
                }
            </div>
            <hr style={{ marginTop: 30, marginBottom: 30 }}/>
            <div className='notes-tables'>
                <div style={{ marginBottom: 20 }}>
                    <label for="filter-note">Filter by note:</label>
                    <select name="filter" id="filter-note" style={{ marginLeft: 20, minWidth: 100 }} onChange={(e) => filterByNote(e)}>
                        <option value={0}>all</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                        <option value={8}>8</option>
                        <option value={9}>9</option>
                        <option value={10}>10</option>
                    </select>
                </div>
                <TableContainer component={Paper} style={{  marginBottom: 20 }}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: "#69b57d" }}>
                            <TableRow>
                                <TableCell sx={{ fontSize: 18 }}>Date</TableCell>
                                <TableCell sx={{ fontSize: 18 }} align='center'>Email Pro</TableCell>
                                <TableCell sx={{ fontSize: 18 }} align="center">Note</TableCell>
                                <TableCell sx={{ fontSize: 18 }} align="right">Commentaire</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { 
                            notes.map((note, id) => {
                                const createdAt = new Date(note.createdAt);
                                const humanReadableDate = createdAt.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
                                return (
                                    <TableRow
                                        key={id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ fontSize: 16 }} component="th" scope="row">
                                            {humanReadableDate}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{note.account.email}</TableCell>
                                        <TableCell sx={{ fontSize: 16, color: defineColorNote(note.note), fontWeight: 'bold' }} align="center">{note.note}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="right">{note.comment}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination sx={{ marginBottom: 30, float: 'right' }} count={Math.floor(countNotes/perPage) + 1} showFirstButton showLastButton onChange={(e, pg) => requestNewPage(pg)} variant="outlined" color="secondary" />
            </div>
        </div>
    )
}

export default NotesByAccounts