import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import Col from "react-bootstrap/esm/Col";
import { AvisList } from "../components/avis/AvisList";
import { ReportedAvisList } from "../components/avis/ReportedAvisList";
import { VerifiedAvisetsList } from "../components/avis/VerifiedAvisList";
import { Resource } from "../components/Resource";
import { Avis } from "../interfaces/Avis";
import { Paginated } from "../interfaces/Paginated";

export const VerifyAvisetsPage = () => {
  const [tab, setTab] = useState<"verify" | "verified" | "reported">("verify");
  const [page, setPage] = useState(0);
  const handleTabChange = useCallback((newTab: "verify" | "verified" | "reported") => {
    setPage(0);
    setTab(newTab);
  }, []);

  const updateList = () => {
    let tmp = page;
    setPage(-1);
    setPage(tmp);
  };

  return (
    <>
      <Col className="d-flex justify-content-between mb-4">
        <h1>Modération avis ETS</h1>
        <div className="d-flex align-items-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              style={{ opacity: tab === "verify" ? 1 : 0.7 }}
              onClick={() => handleTabChange("verify")}
            >
              À vérifier
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "verified" ? 1 : 0.7 }}
              onClick={() => handleTabChange("verified")}
            >
              Vérifié
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "reported" ? 1 : 0.7 }}
              onClick={() => handleTabChange("reported")}
            >
              Signalé
            </Button>

          </ButtonGroup>
        </div>
      </Col>
      <Col>
        {tab === "verify" && (
          <Resource<Paginated<Avis>>
            interval={1000}
            url={`/admin/avis/ets/unverified/${page}/?sort=date_desc`}
            render={(data) => (
              <AvisList
                type="avisets"
                data={data}
                setPage={setPage}
                updateList={updateList}
              />
            )}
          />
        )}
        {tab === "verified" && (
          <Resource<Paginated<Avis>>
            interval={1000}
            url={`/admin/avis/ets/verified/${page}/?sort=date_desc`}
            render={(data) => {
              return <VerifiedAvisetsList data={data} setPage={setPage} />;
            }}
          />
        )}
        {
          tab === "reported" && (
            <Resource<Paginated<Avis>>
              interval={1000}
              url={`/admin/avis/ets/reported/${page}/?sort=date_desc`}
              render={(data) => {
                return <ReportedAvisList data={data} setPage={setPage} />;
              }}
            />
          )
        }
      </Col>
    </>
  );
};
