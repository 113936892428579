import { useEffect, useState } from 'react';
import { ListGroup } from "react-bootstrap";
import { formService } from "../../../../services/form.service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StudyForms = ({etudeForms, setEtudeForms}) => {

    const [formsToAdd, setFormsToAdd] = useState(null);

    useEffect(async() => {
        if(!formsToAdd) {
            await formService.findAll().then(res => {
                setFormsToAdd(res.data);
            }).catch(err => console.log(err))
        }
    });

    return (
        formsToAdd && etudeForms &&
        <div className="etude-forms">
            <div className="etude-forms-header">
                <p>Formulaires présents</p>
                <FontAwesomeIcon 
                    className="forms-icon-header"
                    icon={faArrowsAltH} 
                    size="lg"
                    color="black"
                />
                <p>Formulaires à ajouter</p>
            </div>
            <div className="drag-drop-context">
                <DragDropContext onDragEnd={(param) => {
                    const src = param.source.index;
                    const dest = param.destination?.index;
                    let list = etudeForms;
                    let listToAdd = formsToAdd;

                    if(param.source.droppableId === "droppableForms") {
                        list.splice(src, 1);
                    } else if(param.destination && param.source.droppableId === "droppableFormsToAdd") {
                        list.splice(dest, 0, listToAdd[src])
                    } else {
                        return;
                    }
                    setEtudeForms(list);
                }}>
                    <div className="forms">
                        <Droppable droppableId="droppableForms" >
                            {(provided, _) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} style={{...provided.droppableProps.style, height:'100%'}}>
                                <ListGroup style={{height:'100%'}}>
                                    {
                                    etudeForms.map((form, i) => (
                                        form &&
                                        <Draggable key={i} draggableId={"draggableForms-" + i} index={i}>
                                            {(provided, snapshot) => (
                                            <ListGroup.Item style={{
                                                ...provided.draggableProps.style,
                                                boxShadow: snapshot.isDragging
                                                ? "0 0 .4rem #555"
                                                : "none"
                                            }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps} 
                                                {...provided.dragHandleProps}
                                            >
                                                <p className='col'>{form.name}</p>
                                            </ListGroup.Item>
                                            )}                          
                                        </Draggable>
                                    ))
                                    }
                                    {provided.placeholder}
                                </ListGroup>
                            </div>
                            )}
                        </Droppable>
                    </div>

                    <div className="forms-to-add">
                        <Droppable droppableId="droppableFormsToAdd" style={{height:'100%'}}>
                            {(provided, _) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} style={{...provided.droppableProps.style, height:'100%'}}>
                                <ListGroup style={{height:'100%'}}>
                                    {
                                    formsToAdd.map((form, i) => (
                                        <Draggable key={i} draggableId={"draggableFormsToAdd-" + i} index={i}>
                                            {(provided, snapshot) => (
                                            <ListGroup.Item style={{
                                                ...provided.draggableProps.style,
                                                boxShadow: snapshot.isDragging
                                                ? "0 0 .4rem #555"
                                                : "none"
                                            }}
                                                ref={provided.innerRef}
                                                {...provided.draggableProps} 
                                                {...provided.dragHandleProps}
                                            >
                                                <p className='col'>{form.name}</p>
                                            </ListGroup.Item>
                                            )}                          
                                        </Draggable>
                                    ))
                                    }
                                    {provided.placeholder}
                                </ListGroup>
                            </div>
                            )}
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
            
        </div>
    )

}

export default StudyForms;