import { useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Table } from 'react-bootstrap';
import { isCommaListExpression } from 'typescript';

import { CreateEcoleModal } from '../../components/ecoles/CreateEcoleModal';
import { EcoleListItem } from '../../components/ecoles/EcoleListItem';
import { Ecole, EcoleStatus } from '../../interfaces/Ecole';
import { url } from '../../server.json';
import { ecoleService } from '../../services/ecole.service';
import { trainingOrganizationService } from '../../services/training-organization.service';

export const VerifyEcolePage = () => {
  const [showSchoolModal, setShowSchoolModal] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<EcoleStatus>(
    EcoleStatus.ALL
  );
  const [searchEcole, setSearchEcole] = useState("");
  const [defaultEcoles, setDefaultEcoles] = useState([]);

  const compare = (ecole1: any, ecole2: any) => {
    if (
      (ecole1.status === "DRAFT" || ecole1.status === "UNDER_REVIEW") &&
      (ecole2.status !== "DRAFT" || ecole2.status !== "UNDER_REVIEW")
    ) {
      return -1;
    } else if (
      (ecole2.status === "DRAFT" || ecole2.status === "UNDER_REVIEW") &&
      (ecole1.status !== "DRAFT" || ecole1.status !== "UNDER_REVIEW")
    ) {
      return 1;
    }
    return 0;
  };

  const onDelete = (_id: string) => {
    setDefaultEcoles(defaultEcoles.filter((e: any) => e._id !== _id));
  };

  useEffect(() => {
    (async () => {
      const ecoles: any = await trainingOrganizationService.findAll();
      setDefaultEcoles(
        ecoles
          .sort((a: any, b: any) =>
            a.denomination.localeCompare(b.denomination)
          )
          .sort((a: any, b: any) => compare(a, b))
      );
    })();
  }, []);

  const handleFilterStatusChange = (status: EcoleStatus) => {
    if (filterStatus == status) {
      setFilterStatus(EcoleStatus.ALL);
      return;
    }
    setFilterStatus(status);
  };

  const filterEcole = (ecole: Ecole) => {
    if (filterStatus)
      return (
        ecole.denomination
          .toLowerCase()
          .includes(searchEcole.trim().toLowerCase()) &&
        ecole.status === filterStatus
      );

    return ecole.denomination.toLowerCase().includes(searchEcole.toLowerCase());
  };

  const schools = [...defaultEcoles].filter(filterEcole);

  return (
    <>
      <Col className="d-flex justify-content-between mb-4">
        <h1>Ecole Control Center</h1>
        <div className="d-flex align-items-center">
          <Button
            onClick={() => setShowSchoolModal(true)}
            variant="success"
            className="mx-1"
          >
            Créer une École
          </Button>
        </div>
      </Col>
      <Row className="d-flex justify-content-between align-items-center mb-4">
        <Col className="col-8">
          <input
            type="text"
            className="form-control"
            placeholder="Chercher une école"
            value={searchEcole}
            onChange={(e) => setSearchEcole(e.target.value)}
          />
        </Col>
        <Col
          className="btn-group col-3"
          role="group"
          aria-label="Basic example"
        >
          <button
            type="button"
            className={`btn ${
              filterStatus === EcoleStatus.DRAFT
                ? "btn-secondary"
                : "btn-outline-secondary"
            }`}
            onClick={(e) => handleFilterStatusChange(EcoleStatus.DRAFT)}
          >
            Brouillon
          </button>
          <button
            type="button"
            className={`btn ${
              filterStatus === EcoleStatus.PUBLISHED
                ? "btn-secondary"
                : "btn-outline-secondary"
            }`}
            onClick={(e) => handleFilterStatusChange(EcoleStatus.PUBLISHED)}
          >
            Publié
          </button>
          <button
            type="button"
            className={`btn ${
              filterStatus === EcoleStatus.ARCHIVED
                ? "btn-secondary"
                : "btn-outline-secondary"
            }`}
            onClick={(e) => handleFilterStatusChange(EcoleStatus.ARCHIVED)}
          >
            Archives
          </button>
        </Col>
      </Row>

      <Col>
        {schools.length === 0 ? (
          <Alert variant="success">Aucun resultat</Alert>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Denomination</th>
                <th>Pratiques</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {schools.map((ecole: Ecole) => (
                <EcoleListItem
                  key={ecole._id}
                  ecole={ecole}
                  onDelete={onDelete}
                />
              ))}
            </tbody>
          </Table>
        )}
      </Col>
      <CreateEcoleModal
        showCreateModal={showSchoolModal}
        setShowCreateModal={setShowSchoolModal}
        ecoles={defaultEcoles}
        setEcoles={setDefaultEcoles}
      />
    </>
  );
};
