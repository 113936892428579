import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { url as serveur } from "../../server.json";
import { Modal, Table, Button, FormCheck, Form } from "react-bootstrap";


const Forms = () => {

    const [formToSend, setformTosend] = useState({});
    const [modalCharge, setModalCharge] = useState(false);
    const [formulaireList, setformulaireList] = useState(null);
    const [currentForm, setCurrentForm] = useState({})
    const [test, setTest] = useState(null);

    const randomString = () => { return (Math.random()).toString(36).substring(2) };
    useEffect(() => {
        if (!formulaireList) {
            axios({
                url: `${serveur}/forms/`,
                method: 'GET',
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            }).then((res) => setformulaireList(res.data))
        }
    });
    console.log('tosend', formToSend);
    // currentForm.modules = la list de tout les elements.
    const renderKind = (element) => {
        const kind = element.kind;
        if (kind === 'input') {
            return (
                <div>
                    <h3>{element.question}</h3>
                    <input
                        style={{ padding: "8px" }}
                        placeholder={element.placeholder}
                        value={formToSend[element.id]}
                        onChange={(e) => setformTosend({
                            ...formToSend,
                            [element._id]: e.target.value,
                        })}></input>
                </div>
            )
        } else if (kind === 'title') {
            return (
                <div>
                    <h3>{element.texte}</h3>
                </div>
            )
        } else if (kind === 'selection') {
            return (
                <div>
                    <h3>{element.question}</h3>
                    { 
                        <div>
                        {
                            element.propositions.map((proposition, k) => (
                                
                                <div key={proposition._id + "-" + k} className="mb-3">
                                    <Form.Check
                                        inline={element.propositions.length <= 2}
                                        label={proposition.texte}
                                        type={element.multiple ? 'checkbox' : 'radio'}
                                        name={(element.multiple ? 'checkbox' : 'radio') + element._id}
                                        id={proposition._id}
                                        onChange={(e) => {
                                            if(element.multiple) {
                                                setformTosend({
                                                    ...formToSend,
                                                    [element._id]: {
                                                        ...formToSend[element._id],
                                                        [proposition._id]: e.target.value,
                                                    }
                                                })
                                                if(e.target.checked && proposition.motif && proposition.motif !== "") {
                                                    setTest({...test, [proposition._id]: true})
                                                }
                                                if(!e.target.checked && proposition.motif && proposition.motif !== "") {
                                                    setTest({...test, [proposition._id]: false})
                                                }
                                            } else {
                                                setformTosend({
                                                    ...formToSend,
                                                    [element._id]: {
                                                        [proposition._id]: e.target.value,
                                                    }
                                                })
                                                setTest(null)
                                                if(e.target.checked && proposition.motif && proposition.motif !== "") {
                                                    setTest({[proposition._id]: true})
                                                }
                                            }
                                            
                                        }}
                                    />
                                    {
                                        test && test[proposition._id] === true &&
                                        <div key={"motif-" + proposition._id+ "-" + k} style={{marginLeft: '30px', marginTop: '15px'}}>
                                            <span>{proposition.motif} &nbsp;</span>
                                            <input type='text'
                                                onChange={(e) => {
                                                    setformTosend({
                                                        ...formToSend,
                                                        [element._id]: {
                                                            ...formToSend[proposition._id],
                                                            [proposition._id]: {
                                                                motif: e.target.value
                                                            }
                                                        }
                                                    })
                                                }}
                                                value={formToSend[element._id] === undefined ? '' : formToSend[element._id].motif}
                                            ></input>                                         
                                        </div>
                                    }
                                </div>
                            ))
                        }
                        </div>  
                    }   
                </div>
            )
        } else if (kind === 'calendar') {
            return (
                <div>
                    <h3>{element.question}</h3>
                    <input type='date'
                        value={formToSend[element.name]}
                        onChange={(e) => setformTosend({
                            ...formToSend,
                            [element._id]: e.target.value
                        })} />

                </div>
            )

        }
    }

    // Function to Admin to create Forms

    const [hasModal, setHasModal] = useState(false);
    const [createKind, setCreateKind] = useState(null);
    const [newProposition, setNewProposition] = useState({
        id: '',
        texte: '',        
        motif: ''
    });
    const [indexSelected, setIndex] = useState(null);
    const [newValue, setNewValue] = useState({
        kind: ''
    });

    const deletElement = () => {
        let olDlist = currentForm.modules;
        olDlist.splice(indexSelected, 1);
        setCurrentForm({
            id: currentForm.id,
            name: currentForm.name,
            modules: olDlist
        })
        setIndex(null);
    }
    const reset = () => {
        setHasModal(false);
        setCreateKind(null);
        setIndex(null);
        setNewValue({
            id: randomString(),
            title: '',
            name: '',
            kind: ''
        });
        setNewProposition({
            id: randomString(),
            texte: '',        
            motif: ''
        })
    }

    const addElement = () => {
        let tmpCurrentForm = currentForm;
        let oldList = tmpCurrentForm.modules;
        if (oldList && oldList.length > 0) {
            oldList.splice(indexSelected + 1, 0, newValue);
            setCurrentForm({
                id: tmpCurrentForm.id,
                name: tmpCurrentForm.name,
                modules: oldList
            })
        } else {
            setCurrentForm({
                id: tmpCurrentForm.id,
                name: tmpCurrentForm.name,
                modules: [newValue]
            })
        }

        reset();
    }

    const addProposition = () => {
        let oldProps = newValue.propositions;
        if (oldProps && oldProps.length > 0) {
            oldProps.push({ 
                texte: newProposition.texte, 
                motif: newProposition.motif 
            });
        } else {
            oldProps = [{ 
                texte: newProposition.texte, 
                motif: newProposition.motif 
            }]
        }

        setNewValue({
            ...newValue,
            propositions: oldProps
        })
        setNewProposition({texte: ''});
    }
    const postFormulaire = () => {
        axios({
            url: `${serveur}/forms/`,
            method: 'POST',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: currentForm
        }).then((res) => {
            setCurrentForm({
                id: res.data.id,
                name: res.data.name,
                modules: res.data.modules
            })
        })
    }
    const updateFormulaire = () => {
        axios({
            url: `${serveur}/forms/` + currentForm.id,
            method: 'PUT',
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: currentForm
        })
        setformulaireList(null);
    }


    return (
        <div
            style={{
                maxWidth: '900px',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '200px',
                padding: "30px"
            }}
        >
            <Button
                onClick={() => setModalCharge(true)}
            >Charger un formulaire</Button>
            {
                currentForm && currentForm.modules &&
                <h2 style={{ marginTop: '100px' }}>Aperçu du formulaire:</h2>
            }
            
            <div>{
                currentForm && currentForm.modules &&
                <Form>
                {
                    currentForm.modules.map((element, k) => (
                        <div>
                            {renderKind(element)}
                            <hr />
                        </div>
                    ))
                }
                </Form>
                
            }
            </div>
            <h2>Modifier le formulaire:</h2>
            <div style={{ marginTop: '50px' }}>Titre du formulaire <input onChange={(e) => setCurrentForm({id: currentForm.id, modules: currentForm.modules, name: e.target.value})} value={currentForm.name} kind='text' /></div>
            <Table striped bordered hover style={{ marginTop: '50px' }}>
                <tbody>
                    {
                        currentForm && currentForm.modules &&
                        currentForm.modules.map((e, k) => (
                            <tr key={k} >
                                <td>{k}</td>
                                <td style={{ marginLeft: "10px" }}>{e.title}</td>
                                <td>{e.name}</td>
                                <td >
                                    <Button variant="warning" onClick={() => { setIndex(k); deletElement() }} style={{ marginLeft: "10px" }}>Supprimer</Button>
                                    <Button onClick={() => { setHasModal(true); setIndex(k) }} style={{ marginLeft: "10px" }}> Ajouter dessous</Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <Button onClick={() => { setHasModal(true); setIndex(currentForm.modules === undefined ? 0 : currentForm.modules.length) }} > Ajouter </Button>
            <br />
            <Button variant="success" style={{ marginTop: "20px" }} onClick={() => currentForm.id ? updateFormulaire() : postFormulaire()}>Enregistrer le formulaire et le rendre disponible</Button>
            <Modal
                show={hasModal}
                onHide={() => reset()}
            >
                <Modal.Title style={{ padding: '20px' }}>Element</Modal.Title>
                <Modal.Body>
                    <select style={{ padding: '5px' }}
                        onChange={(e) => {
                            setCreateKind(e.target.value)
                            if(e.target.value === 'selection') {
                                setNewValue({
                                    ...newValue,
                                    id: randomString(),
                                    kind: e.target.value,
                                    question: '',
                                    multiple: false,
                                    propositions: []
                                })
                            } else if(e.target.value === 'title') {
                                setNewValue({
                                    ...newValue,
                                    kind: e.target.value,
                                    texte: ''
                                })
                            }     
                            else if(e.target.value === 'calendar') {
                                setNewValue({
                                    ...newValue,
                                    id: randomString(),
                                    kind: e.target.value,
                                    question: ''
                                })
                            }        
                            else if(e.target.value === 'input') {
                                setNewValue({
                                    ...newValue,
                                    id: randomString(),
                                    kind: e.target.value,
                                    question: ''
                                })
                            }                         
                        }}
                    >
                        <option></option>
                        <option value='title'>Titre</option>
                        <option value='input'>Texte libre</option>
                        <option value='selection'>Selection</option>
                        <option value='calendar'>Date</option>
                    </select>
                    <div>
                        <div style={{ marginTop: "10px" }} >
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <p>{createKind === 'title' ? 'Le titre' : 'La question'}</p>
                            <input type='text' style={{ padding: "5px" }}
                                value={
                                    createKind === 'title' ? newValue.texte : newValue.question}
                                onChange={(e) => {
                                    if(createKind === 'title') {
                                        setNewValue({
                                            ...newValue,
                                            texte: e.target.value
                                            })
                                    } else {
                                        setNewValue({
                                            ...newValue,                                        
                                            question: e.target.value,
                                        })
                                    }                                    
                                }}
                            />
                        </div>
                        {
                            (createKind === 'selection') &&
                            <div style={{ marginTop: '20px' }}>
                                <FormCheck 
                                    style={{paddingBottom: '10px'}}
                                    type="switch" 
                                    label="Réponses multiples"
                                    onChange={(e) => {
                                        setNewValue({
                                            ...newValue,
                                            multiple: e.target.checked
                                        });                                   
                                    }}
                                ></FormCheck>
                                {
                                    newValue.propositions && 
                                    newValue.propositions.map((proposition) => (
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "start" }}>
                                            <p> - {proposition.texte} </p>
                                        {
                                            (proposition.motif !== '' && proposition.motif !== undefined) &&
                                            <p> &nbsp; [ motif :  {proposition.motif} ] </p>
                                        }
                                        </div>
                                    ))
                                }

                                <div style={{ marginTop: '30px' }}>
                                    Ajouter une proposition :
                                    <input type='text' style={{ padding: "5px", marginLeft: '30px' }} value={newProposition.texte} onChange={(e) => setNewProposition({texte: e.target.value})}/>
                                    <FormCheck 
                                    style={{paddingBottom: '10px', paddingTop: '10px'}}
                                    type="switch" 
                                    label="Demander un motif textuel"
                                    checked= {newProposition.motifenabled}
                                    onChange={(e) => {
                                        setNewProposition({
                                            ...newProposition,
                                            id: randomString(),
                                            motifenabled: e.target.checked
                                        });                                   
                                    }}
                                ></FormCheck>
                                {
                                    newProposition.motifenabled &&
                                    <div style={{ marginTop: '5px' }}>
                                        Question du motif:
                                        <input type='text' style={{ padding: "5px", marginLeft: '20px' }}
                                            value={newProposition.motif}
                                            onChange={(e) => setNewProposition({
                                                ...newProposition,
                                                motif: e.target.value
                                            })}
                                        />
                                    </div>
                                }
                                    <Button
                                        onClick={() => newProposition.texte !== '' && addProposition()}
                                    >ajouter</Button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        if(newValue.kind === 'selection' && newValue.multiple === undefined) {
                            setNewValue({
                                ...newValue,
                                multiple: false
                            });                    
                        }
                        (newValue.texte || newValue.question) && addElement()
                    }}>Valider</Button>
                    <Button onClick={() => reset()}>Annuler</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={modalCharge}
                onHide={() => setModalCharge(false)}
            >
                <Modal.Title>Charger un formulaire</Modal.Title>
                <Modal.Body>
                    {
                        formulaireList && formulaireList.map((e, k) => (
                            <Button
                                style={{ marginLeft: '30px' }}
                                onClick={() => {
                                    setCurrentForm({
                                        id: e.id,
                                        name: e.name,
                                        modules: e.modules
                                    });
                                    setModalCharge(false)
                                }}
                            >{e.name}</Button>
                        ))
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalCharge(false)}>Fermer</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Forms;