import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Button, Row, Table } from "react-bootstrap";
import { useSWRConfig } from "swr";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Resource } from "../../components/Resource";
import { Syndicat } from "../../interfaces/Syndicat";
import { syndicatService } from "../../services/syndicat.service";
import { CreateSyndicatModal } from "./CreateSyndicatModal";
import { UpdateSyndicatModal } from "./UpdateSyndicatModal";

export const Syndicats = () => {
  const FETCHING_URL = "/admin/syndicat/";

  const [loading, setLoading] = useState(false);
  const [initializing, setInitializaing] = useState(true);
  const [error, setError] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const [updatingSyndicat, setUpdatingSyndicat] = useState(null);

  const { mutate } = useSWRConfig();

  const mutateData = () => {
    mutate(FETCHING_URL);
  };

  useEffect(() => {
    if (updatingSyndicat) {
      setShowUpdateModal(true);
    }
  }, [updatingSyndicat]);

  useEffect(() => {
    if (!setShowUpdateModal) setUpdatingSyndicat(null);
  }, [setShowUpdateModal]);

  return (
    <div id="syndicats-list">
      <h1>Syndicats</h1>
      <div className="d-flex align-items-center"></div>
      {error ? (
        <div className="alert alert-danger" role="alert">
          Une erreur est survenue : {error}
        </div>
      ) : (
        <>
          <Button onClick={() => setShowCreateModal(true)}>
            + Ajouter un syndicat
          </Button>
          <Resource
            url={FETCHING_URL}
            render={(syndicats: any[]) => (
              <Table striped responsive>
                <thead>
                  <tr>
                    <th>sigle</th>
                    <th>denomination</th>
                    <th>email</th>
                    <th>téléphone</th>
                    <th>url</th>
                    <th>adresse</th>
                    <th>actions</th>
                  </tr>
                </thead>
                <tbody>
                  {syndicats.map((syndicat) => (
                    <tr>
                      <td>{syndicat.sigle}</td>
                      <td>{syndicat.denomination}</td>
                      <td>{syndicat.email}</td>
                      <td>{syndicat.phoneNumber}</td>
                      <td>{syndicat.url}</td>
                      <td>
                        {
                          syndicat.address ? (
                            syndicat.address.street +
                              "," +
                              syndicat.address.cp +
                              " " +
                              syndicat.address.city
                          ) : (null)
                        }
                      </td>
                      <td>
                        {!syndicat.isClaimed && (
                          <Button
                            variant="danger"
                            disabled={loading}
                            onClick={() => setUpdatingSyndicat(syndicat)}
                          >
                            Modifier
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                  <UpdateSyndicatModal
                    showUpdateModal={showUpdateModal}
                    setShowUpdateModal={setShowUpdateModal}
                    syndicat={updatingSyndicat}
                    onSuccess={mutateData}
                  />
                </tbody>
              </Table>
            )}
          />
        </>
      )}
      <CreateSyndicatModal
        showCreateModal={showCreateModal}
        setShowCreateModal={setShowCreateModal}
        onSuccess={mutateData}
      />
    </div>
  );
};
