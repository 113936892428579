import './App.scss';

import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import CreateTypePratique from './pages/CreateTypePratique';
import { VerifyDiplomePage } from './pages/diplome/VerifyDiplomePage';
import { VerifyEcolePage } from './pages/ecole/VerifyEcolePage';
import { EtablissementClaims } from './pages/etablissements/EtablissementClaims';
import { Etablissement } from './pages/etablissements/Etablissements';
import CreateForm from './pages/form/CreateForm';
import ListClaimPro from './pages/ListClaimPro';
import { LoginPage } from './pages/LoginPage';
import NotesByAccounts from './pages/NotesByAccounts';
import { PostEditor } from './pages/post/PostEditor';
import { PostListing } from './pages/post/PostListing';
import PratiquesPage from './pages/pratiques/Index';
import { VerifyProsCabinet } from './pages/pros/cabinetPro/VerifyProsCabinet';
import { VerifyProKbis } from './pages/pros/kbis/VerifyProKbis';
import { VerifyProsPartenariat } from './pages/pros/partenariat/VerifyProsPartenariat';
import { Pro } from './pages/pros/Pros';
import { Pros2 } from './pages/pros/Pros2';
import { ReportedPros } from './pages/pros/signalement/ReportedPros';
import { VerifyProsSyndicats } from './pages/pros/syndicat/VerifyProSyndicat';
import CreateEtude from './pages/study/Study';
import { Syndicats } from './pages/syndicat/Syndicats';
import { VerifyClaimSyndicat } from './pages/syndicats/claims/VerifyClaimSyndicat';
import { VerifyClaimPage } from './pages/training-orgnization/claim/VerifyClaimPage';
import { VerifyAvisetsPage } from './pages/VerifyAvisets';
import { VerifyAvisOfPage } from './pages/VerifyAvisOf';
import { VerifyAvisproPage } from './pages/VerifyAvispro';
import { WithLogin } from './utils/WithLogin';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={LoginPage} />
        <Route exact path="/pratiques">
          <WithLogin component={<PratiquesPage />} />
        </Route>
        <Route exact path="/diplome">
          <WithLogin component={<VerifyDiplomePage />} />
        </Route>
        <Route exact path="/ecole">
          <WithLogin component={<VerifyEcolePage />} />
        </Route>
        <Route exact path="/avisets">
          <WithLogin component={<VerifyAvisetsPage />} />
        </Route>
        <Route exact path="/avispro">
          <WithLogin component={<VerifyAvisproPage />} />
        </Route>
        <Route exact path="/avisof">
          <WithLogin component={<VerifyAvisOfPage />} />
        </Route>
        <Route exact path="/formulaireCreator">
          <WithLogin component={<CreateForm />} />
        </Route>
        <Route exact path="/etudes">
          <WithLogin component={<CreateEtude />} />
        </Route>
        <Route exact path="/etablissements">
          <WithLogin component={<Etablissement />} />
        </Route>
        <Route exact path="/etablissements/claims">
          <WithLogin component={<EtablissementClaims />} />
        </Route>
        <Route exact path="/syndicats">
          <WithLogin component={<Syndicats />} />
        </Route>
        <Route exact path="/of/claims">
          <WithLogin component={<VerifyClaimPage />} />
        </Route>
        <Route exact path="/syndicat/claims">
          <WithLogin component={<VerifyClaimSyndicat />} />
        </Route>
        <Route exact path="/notes">
          <WithLogin component={<NotesByAccounts />} />
        </Route>
        <Route exact path="/pro/claims">
          <WithLogin component={<ListClaimPro />} />
        </Route>
        <Route exact path="/pros-2">
          <WithLogin component={<Pros2 />} />
        </Route>
        <Route exact path="/pros">
          <WithLogin component={<Pro />} />
        </Route>
        <Route exact path="/pros/syndicats">
          <WithLogin component={<VerifyProsSyndicats />} />
        </Route>
        <Route exact path="/pros/siret">
          <WithLogin component={<VerifyProKbis />} />
        </Route>
        <Route exact path="/pros/reports">
          <WithLogin component={<ReportedPros />} />
        </Route>
        <Route exact path="/create-type-pro-pratiques">
          <WithLogin component={<CreateTypePratique />} />
        </Route>
        <Route exact path="/pros/cabinet-pro">
          <WithLogin component={<VerifyProsCabinet />} />
        </Route>
        <Route exact path="/pros/partenariats">
          <WithLogin component={<VerifyProsPartenariat />} />
        </Route>
        <Route exact path={`/ma-communication/editor/:postId`}>
          <WithLogin component={<PostEditor />} />
        </Route>
        <Route exact path={`/ma-communication/editor`}>
          <WithLogin component={<PostEditor />} />
        </Route>
        <Route exact path={`/ma-communication`}>
          <WithLogin component={<PostListing />} />
        </Route>
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  );
}

App.portalLocation = document.querySelector("#portal");

export default App;
