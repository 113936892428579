import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import { useLogin } from '../store/login.store';

export const Login = () => {
  const [password, setPassword] = useState('');
  const [login, logout] = useLogin((state) => [state.login, state.logout]);
  const loggedIn = useLogin((state) => state.loggedIn);
  const loading = useLogin((state) => state.loading);
  const error = useLogin((state) => state.error);
  const history = useHistory();

  useEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (loggedIn) {
      history.push('diplome');
    }
  }, [loggedIn, history]);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        login(password);
      }}>
      <Card style={{ width: '18rem' }}>
        <Card.Header>
          <Card.Title>Log In</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form.Group className='mb-3' controlId='form-pwd'>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Ex: $tr0nG_P@ssw0rd'
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
          </Form.Group>
          <Button variant='primary' className='w-100 mt-2' disabled={loading} onClick={() => login(password)}>
            {loading ? (
              <Spinner animation='border' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </Spinner>
            ) : (
              'Log In'
            )}
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};
