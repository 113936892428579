import "./pros.scss";

import {
  Avatar,
  Card,
  Col,
  Divider,
  Drawer,
  Grid as GridAnt,
  Layout,
  Row,
  Select,
} from "antd";
import Search from "antd/es/input/Search";
import { R_OK } from "constants";
import dayjs from "dayjs";
/** -----------------------------------  */
import { useEffect, useState } from "react";

import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { url as back } from "../../server.json";
import { ProService } from "../../services/pro.service";
import { DetailedConfigurationsModal } from "./DetailedConfigurationsModal";
import { DisplayStatusProfil } from "./displayStatusProfil";

// import { IUser } from './utils/pro_list';

const { Meta } = Card;
const { Sider } = Layout;
const { useBreakpoint } = GridAnt;

type account = {
  email: string;
  firstname: string;
  name: string;
};

type pro = {
  cabinet: string;
  nom: string;
  prenom: string;
  subscription: {
    options: Array<string>;
    type: string;
    created?: number;
    current_period_start?: number;
    current_period_end?: number;
  };
  accounts: Array<account>;
  configurations: any;
};
interface IUser {
  email: string;
  nom: string;
  prenom: string;
  profilPic: string;
  configurations: any;
  subscription: {
    createdAt: string;
    type: string;
  };
  typePros: [
    {
      typePro: {
        nom: string;
      };
    }
  ];
}

const DetailedInfos = (props: any) => {
  const { content } = props;
  if (content === null) return <></>;
  return (
    <>
      <DisplayStatusProfil pro={content} />
    </>
  );
};

const CompletionInfo = (props: any) => {
  const { data } = props;
  if (data === null) return <></>;
  return <>{data.configurations.percent}%</>;
};

export const Pro = () => {
  // const [defaultList, setDefaultList] = useState([]);
  // const [pros, setPro] = useState([]);
  // const [error, setError] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");
  // const [showDetailedConfigurations, setShowDetailedConfigurations] = useState(false);
  // const [selectedPro, setSelectedPro] = useState<any>(null);
  const [pros, setPros] = useState<IUser[] | []>([]);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [countPros, setCountPros] = useState<number>(0);
  const [sort, setSort] = useState<string>("");
  const [content, setContent] = useState<IUser | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const screens = useBreakpoint();
  const [nbCard, setNbCard] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const res = await ProService.getPro(page, perPage, sort);
      return res;
    };
    fetchData()
      .then((res) => {
        setPros(res);
        setCountPros(25);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await ProService.getPro(page, perPage, sort);
      return res;
    };
    setPage(1);
    fetchData()
      .then((res) => {
        setPros(res);
        setCountPros(25);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sort]);
  // useEffect(() => {
  //   setShowDetailedConfigurations(true);
  // }, [selectedPro]);

  // const handleSelectedOption = (value: string) => {
  //   setSelectedOption(value);
  //   if (value === "") {
  //     setProList(defaultList);
  //     return;
  //   }
  //   const sorted = defaultList.filter((etablissement: pro) =>
  //     etablissement.subscription.options.includes(value)
  //   );
  //   setProList(sorted);
  // };

  // const sortByName = () => {
  //   const oldList = [...proList];
  //   const sorted = oldList.sort((a: pro, b: pro) => {
  //     if (a.cabinet < b.cabinet) return -1;
  //     if (a.cabinet > b.cabinet) return 1;
  //     return 0;
  //   });

  //   console.log(sorted);
  //   setProList(sorted);
  // };

  // const sortBySubscription = () => {
  //   const oldList = [...proList];
  //   const sorted = oldList.sort((a: pro, b: pro) => {
  //     if (a.subscription.type < b.subscription.type) return -1;
  //     if (a.subscription.type > b.subscription.type) return 1;
  //     return 0;
  //   });

  //   console.log(sorted);
  //   setProList(sorted);
  // };

  // const sortByConfigurations = () => {
  //   const oldList = [...proList];
  //   const sorted = oldList.sort((a: pro, b: pro) => {
  //     if (a.configurations.requiredPercent < b.configurations.requiredPercent)
  //       return 1;
  //     if (a.configurations.requiredPercent > b.configurations.requiredPercent)
  //       return -1;
  //     return 0;
  //   });
  //   setProList(sorted);
  // };
  console.log(content);
  console.log(pros);
  const handleCLickCard = (pro: IUser) => {
    setOpen(true);
    setContent(pro);
  };

  const handleCloseInfos = () => {
    setOpen(false);
    setContent(null);
  };
  const onSearch = (search: string) => {
    console.log(search);
  };
  const handleChangeFilter = (values: string) => {
    console.log(values);
  };
  return (
    <div id="etablissement-list">
      {/* <Row style={{ marginBottom: 30 }}>
				<Col xs={8}style={{ paddingRight: 30, paddingLeft: 30 }}>
					<Search placeholder="rechercher..." onSearch={(s) => onSearch(s)} enterButton />
				</Col>
				<Col xs={8}>
					<div className='rflex-center' >
						<span>filtre: </span>
						<Select
							defaultValue="lucy"
							style={{ width: 120 }}
							onChange={handleChangeFilter}
							options={[
								{ value: 'jack', label: 'Jack' },
								{ value: 'lucy', label: 'Lucy' },
								{ value: 'Yiminghe', label: 'yiminghe' },
								{ value: 'disabled', label: 'Disabled', disabled: true },
							]}
						/>
					</div>
				</Col>
				<Col xs={8}>
				<div className='rflex-center' >
						<span>sort: </span>
						<Select
							defaultValue="lucy"
							style={{ width: 120 }}
							onChange={handleChangeFilter}
							options={[
								{ value: 'jack', label: 'Jack' },
								{ value: 'lucy', label: 'Lucy' },
								{ value: 'Yiminghe', label: 'yiminghe' },
								{ value: 'disabled', label: 'Disabled', disabled: true },
							]}
							/>
					</div>
				</Col>
			</Row> */}
      <Row gutter={[16, 24]}>
        {pros.length > 0 &&
          pros.map((pro, id) => {
            console.log(pro);
            const createdAt = new Date(pro.subscription.createdAt);
            const humanReadableDate = createdAt.toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "long",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
            });
            const card = (
              <Card
                bodyStyle={{ minHeight: 200, wordWrap: "break-word" }}
                onClick={() => handleCLickCard(pro)}
                actions={[<CompletionInfo data={pro} />]}
              >
                <Meta
                  avatar={
                    <Avatar
                      style={{ backgroundColor: "#87d068" }}
                      icon={<UserOutlined />}
                    />
                  }
                  title={pro.nom + " " + pro.prenom}
                  description={pro.email}
                />
                <div style={{ marginTop: 20, fontSize: 12 }}>
                  <b>date:</b> {humanReadableDate}
                </div>
                <div style={{ marginTop: 10, fontSize: 12 }}>
                  <b>type:</b> {pro.subscription.type}
                </div>
                <div>
                  <b>professions:</b>
                  <li>
                    {pro.typePros?.map((tp) => (
                      <ul>{tp.typePro?.nom ?? "Erreur profession"}</ul>
                    ))}
                  </li>
                </div>
              </Card>
            );
            return (
              <Col xs={24} sm={24} md={12} lg={8} xl={4}>
                {card}
              </Col>
            );
          })}
      </Row>
      <Drawer
        title="Information détaillée"
        placement="right"
        onClose={handleCloseInfos}
        open={open}
      >
        <DetailedInfos content={content} />
      </Drawer>
      {/* <TableContainer component={Paper} style={{  marginBottom: 20 }}>
				<Table sx={{ minWidth: "100%" }} aria-label="simple table">
					<TableHead sx={{ backgroundColor: "#69b57d" }}>
						<TableRow>
							<TableCell sx={{ fontSize: 18 }}>Avatar</TableCell>
							<TableCell sx={{ fontSize: 18 }} align='center'>Email</TableCell>
							<TableCell sx={{ fontSize: 18 }} align="center">Firstname</TableCell>
							<TableCell sx={{ fontSize: 18 }} align="center">Lastname</TableCell>
							<TableCell sx={{ fontSize: 18 }} align="right">Subscription</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{ 
						pros.map((pro, id) => {
							return (
								<TableRow
									key={id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell sx={{ fontSize: 16 }} align="center"><img src={pro.avatar} /></TableCell>
									<TableCell sx={{ fontSize: 16 }} align="center">{pro.email}</TableCell>
									<TableCell sx={{ fontSize: 16 }} align="center">{pro.firstName}</TableCell>
									<TableCell sx={{ fontSize: 16 }} align="center">{pro.lastName}</TableCell>
									<TableCell sx={{ fontSize: 16 }} align="right">{pro.subscriptionTier}</TableCell>
								</TableRow>
							)
						})
					}
					</TableBody>
				</Table>
            </TableContainer> */}
      {/* <Pagination sx={{ marginBottom: 30, float: 'right' }} count={Math.floor(countNotes/perPage) + 1} showFirstButton showLastButton onChange={(e, pg) => requestNewPage(pg)} variant="outlined" color="secondary" /> */}
      {/* <DetailedConfigurationsModal
				show={showDetailedConfigurations}
				setShow={setShowDetailedConfigurations}
				pro={selectedPro}
			/> */}
    </div>
  );
};
