import CreateBesoin from "./CreateBesoin";
import CreatePratique from "./CreatePratique";
import CreateTypePro from "./CreateTypePro";
import { Modal } from "antd";

const ModalGlobalComponent = ({ modalOpen, configModal, setModalOpen }) => {
    const handleCancel = () => {
        setModalOpen(false)
    }
    return (
        <Modal title={`${configModal}`} footer={null} open={modalOpen} onCancel={handleCancel} destroyOnClose={true}>
            {
                configModal === 'typepro' && (
                    <CreateTypePro />
                )
            }
            {
                configModal === 'pratique' && (
                    <CreatePratique />
                )
            }
            {
                configModal === 'besoin' && (
                    <CreateBesoin />
                )
            }
        </Modal>
    )
}

export default ModalGlobalComponent