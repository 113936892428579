import axios from "axios";
import { url as serveur } from "../server.json";

const instance = axios.create({
  withCredentials: true,
});

class PratiqueService {
    async getAllTypePros(page: number, limit: number) {
        try { 
            const res = await instance.get(`${serveur}/pratiques/typepros/all/${page}/${limit}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async getPratiqueById(id: string) {
        try { 
            const res = await instance.get(`${serveur}/pratiques/pratique/${id}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async getAllPratiques() {
        try { 
            const res = await instance.get(`${serveur}/pratiques/pratique/all`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async getBesoinById(id: string) {
        try { 
            const res = await instance.get(`${serveur}/pratiques/besoin/${id}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async getAllBesoins() {
        try { 
            const res = await instance.get(`${serveur}/pratiques/besoins/all`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async createTypePro(body: any) {
        try { 
            const res = await instance.post(`${serveur}/pratiques/typepro/create`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async updateTypePro(body: any) {
        try { 
            const res = await instance.patch(`${serveur}/pratiques/typepro/update_global`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }

    async createPratique(body: any) {
        try { 
            const res = await instance.post(`${serveur}/pratiques/pratique/create`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async updatePratique(body: any) {
        try { 
            const res = await instance.patch(`${serveur}/pratiques/pratique/update_global`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async createBesoin(body: any) {
        try { 
            const res = await instance.post(`${serveur}/pratiques/besoin/create`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
        
    }
    async updateTypeProPratique(tid: string, pid: string) {
        const body = {
            _id: tid,
            pratique_id: pid
        }
        try { 
            const res = await instance.patch(`${serveur}/pratiques/typepro/update_pratiques`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async updatePratiqueBesoin(besoin_id: string, pratique_id: string) {
        const body = {
            _id: besoin_id,
            pratique_id: pratique_id,
        }
        try { 
            const res = await instance.patch(`${serveur}/pratiques/pratique/update`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }

    async updateBesoin(body: any) {
        try { 
            const res = await instance.patch(`${serveur}/pratiques/besoin/update`, body, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
    async removeBesoinFromPratique(id_besoin: string, id_pratique: string) {
        try { 
            const res = await instance.delete(`${serveur}/pratiques/pratique/delete_besoin/${id_besoin}/${id_pratique}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
}

export const PratiquesService = new PratiqueService();