import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

export default function ConfirmLeaveModal({ show, onHide }) {
  return (
    <>
      <Modal show={show} onHide={onHide} centered className='answer-template__modal'>
        <Modal.Body className="flex flex-col align-items-center">
          <div>
            <i className="far fa-trash-alt fa-3x" aria-hidden="true" />
          </div>
          <p className='my-4 text-center'>
            Quitter sans enrengistrer les modifications ?
          </p>
          <Button className='btn btn-blue btn-sm' onClick={() => onHide(true)}>
            Confirmer
          </Button>
          <Button className='mt-2 btn-noStyle' onClick={() => onHide(false)}>
            Annuler
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
