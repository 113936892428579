import axios from "axios";
import { Form } from "../models/form.model";
import { url as serveur } from "../server.json";

const instance = axios.create({
    withCredentials: true,
})

class FormService {

    

    async findAll(): Promise<Form[]> {
        return await instance.get(`${serveur}/forms/`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
    }

}

export const formService = new FormService();