import axios from "axios";
import { url as serveur } from "../server.json";

const instance = axios.create({
  withCredentials: true,
});

class NoteService {
    async getAllNotesByAccounts(page: number, limit: number, filter: number): Promise<any> {
        const query = { 
            page: page,
            limit: limit,
            filter: filter
        }
        try {
            const res = await instance.post(`${serveur}/note/all`, query, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            });
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }

    async getInformationsAboutNotes(): Promise<any> {
        try {
            const res = await instance.get(`${serveur}/note/informations`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" },
            });
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }

    }
}

export const NotesService = new NoteService();