import { useContext, useEffect, useState } from "react"
import { PratiquesService } from "../../services/pratique.service"
import { Spin, Tag } from "antd"
import { PlusCircleOutlined } from "@ant-design/icons"
import { BesoinSelectedContext, GlobalSourceContext } from "./context"
import _ from 'underscore'

const GetBesoinById = ({ besoin, besoins, setBesoins, pratique_id }) => {
    const [loading, setLoading] = useState(false)
    let besoin_selected = useContext(BesoinSelectedContext)
    let global = useContext(GlobalSourceContext)

    const handleClickOnTag = () => {
        global.setConfigModal('besoin')
        besoin_selected.func({
            value: besoin,
            func: besoin_selected.func
        })
        global.setModalOpen(true)
    }

    if (loading) return <Spin />
    if (!besoin) return <></>;
    return (
        <Tag style={{ cursor: 'pointer' }} color="volcano" onClick={handleClickOnTag}>{besoin.libelle}</Tag>
    )
}

export default GetBesoinById