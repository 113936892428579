import React, { useState, useEffect } from "react";
import postEditionService from "../../services/post-edition";
import Loader from "../../components/loader/loader";
import DeletePostModal from "./DeletePostModal";
import { useHistory, useLocation } from "react-router-dom";
import { Toast } from "react-bootstrap";

import "./post.style.scss";
import { ErrorAlert } from "../../components/errorAlert/ErrorAlert";
import { PostTag } from "../../components/postTag/PostTag";
import moment from "moment";

export function PostListing() {
  const initialEditionState = new URLSearchParams(useLocation().search).get(
    "editionState"
  );

  const [newsPosts, setNewsPosts] = useState([]);
  const [eventPosts, setEventPosts] = useState([]);
  const [postSelected, setPostSelected] = useState(null);
  const [editionState, setEditionState] = useState(
    initialEditionState ? initialEditionState : "PUBLISHED"
  );
  const [channel, setChannel] = useState("");
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorSubscription, setErrorSubscription] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const posts = filterPosts(await postEditionService.list(editionState));
        const news = [];
        const events = [];
        for (const post of posts) {
          if (post.kind === "EVENT") {
            events.push(post);
          } else {
            // 'NEWS'
            news.push(post);
          }
        }

        setNewsPosts(news.sort(sortNews));
        setEventPosts(events.sort(sortEvent));
        setLoading(false);
      } catch (err) {
        // TODO manage error
        console.error(err);
        setError(true);
      }
    })();
  }, [editionState, channel]);

  function filterPosts(posts) {
    if (channel) {
      setErrorSubscription(false);
      return posts.filter((post) => (post.channel === channel || post.channel === "GRAND PUBLIC & PROFESSIONNELS"));
    }
    setErrorSubscription(false);
    return posts;
  }

  function sortNews(a, b) {
    if (editionState === "DRAFT") {
      if (a.createDate > b.createDate) return -1;
      return 1;
    }

    if (a.publishDate > b.publishDate) return -1;
    return 1;
  }

  function sortEvent(a, b) {
    if (editionState === "DRAFT") {
      if (a.createDate > b.createDate) return -1;
      return 1;
    }

    if (a.event.startDate > b.event.startDate) return 1;
    return -1;
  }

  function handleShowDeletionModal(post) {
    setPostSelected(post);
    setShowDeletionModal(true);
  }

  async function handleDeleteModelClosed(post) {
    if (post) {
      if (post.kind === "EVENT") {
        setEventPosts(eventPosts.filter((post) => post.id !== postSelected.id));
      } else {
        //'NEWS'
        setNewsPosts(newsPosts.filter((post) => post.id !== postSelected.id));
      }
    }
    setPostSelected(null);
    setShowDeletionModal(false);
  }

  const parsePlainText = (value) => {
    const parser = new DOMParser();
    return parser.parseFromString(value, "text/html");
  };

  function handleEditPostClicked(post) {
    history.push("/ma-communication/editor/" + post.id);
  }

  async function handlePublishPostClicked(postToPublish) {
    try {
      await postEditionService.updateEditionState(
        postToPublish.id,
        "PUBLISHED"
      );
      if (postToPublish.kind === "EVENT") {
        setEventPosts(
          eventPosts.filter((post) => post.id !== postToPublish.id)
        );
      } else {
        //'NEWS'
        setNewsPosts(newsPosts.filter((post) => post.id !== postToPublish.id));
      }
      setShowToast(true);
    } catch {
      //TODO manage error
    }
  }

  async function handleArchivePostClicked(postToArchive) {
    try {
      await postEditionService.updateEditionState(postToArchive.id, "ARCHIVED");
      if (postToArchive.kind === "EVENT") {
        setEventPosts(
          eventPosts.filter((post) => post.id !== postToArchive.id)
        );
      } else {
        //'NEWS'
        setNewsPosts(newsPosts.filter((post) => post.id !== postToArchive.id));
      }
    } catch {
      //TODO manage error
    }
  }

  const PostList = ({ posts }) => {
    return posts.map((post, i) => {
      return (
        <div
          key={i}
          className={`post-list mb-2 ${
            moment(post.event?.endDate) < moment() ? "post-outdated" : ""
          }`}
        >
          <div>
            <div className="d-flex align-items-center">
              <strong>{post.title}</strong>
              <PostTag channel={post.channel} />
            </div>

            <div className="ellipsis">
              {post.content && (
                <p>{post.content.replace(/(<([^>]+)>)/gi, "")}</p>
              )}
              {post.publishDate ? (
                <p className="text-muted font-italic mb-0">
                  Publié le {moment(post.publishDate).format("DD/MM/YYYY")}
                </p>
              ) : (
                <p className="text-muted font-italic mb-0">
                  Créé le {moment(post.createDate).format("DD/MM/YYYY")}
                </p>
              )}
            </div>
          </div>

          <div className="post-action-buttons">
            <button
              className="btn btn-danger"
              onClick={() => handleShowDeletionModal(post)}
            >
              Supprimer
            </button>
            {post.editionState === "DRAFT" ||
            post.editionState === "PUBLISHED" ? (
              <button
                onClick={() => handleEditPostClicked(post)}
                className="btn btn-secondary"
              >
                Editer
              </button>
            ) : (
              ""
            )}
            {post.editionState === "DRAFT" ? (
              <button
                onClick={() => handlePublishPostClicked(post)}
                className="btn btn-primary"
              >
                Publier
              </button>
            ) : (
              ""
            )}
            {post.editionState === "ARCHIVED" ? (
              <button
                className="btn btn-primary"
                onClick={() => handlePublishPostClicked(post)}
              >
                Republier
              </button>
            ) : (
              ""
            )}
            {post.editionState === "PUBLISHED" ? (
              <button
                className="btn btn-primary"
                onClick={() => handleArchivePostClicked(post)}
              >
                Archiver
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });
  };

  function handleEditionStateChange(event) {
    setEditionState(event.target.value);
  }

  function handleChannelChange(event) {
    setChannel(event.target.value);
  }

  if (newsPosts === null || eventPosts === null) return <Loader />;

  return (
    <div className="communication container" style={{ backgroundColor: "#eef2f7" }}>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <h3>Ma Communication</h3>
        <div className="row align-items-center">
          <p className="mr-3 mb-0">Filtres: </p>
          <select
            value={editionState}
            onChange={handleEditionStateChange}
            className="mr-3"
          >
            <option value="DRAFT">Brouillons</option>
            <option value="PUBLISHED">Publiés</option>
            <option value="ARCHIVED">Archives</option>
          </select>

          <select
            name="channel"
            id="channel"
            value={channel}
            onChange={handleChannelChange}
          >
            <option value="">Tous</option>
            <option value="GRAND PUBLIC">Grand Public</option>
            <option value="PROFESSIONNELS">Professionnels</option>
            <option value="GRAND PUBLIC & PROFESSIONNELS">Grand Public et Professionnels</option>
          </select>
        </div>
      </div>

      {error && <ErrorAlert>Impossible de charger les posts</ErrorAlert>}
      {loading ? (
        <Loader></Loader>
      ) : (
        <div className="row content">
          <div className="col-12 col-xl-6 mb-3">
            <div className="card">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Actualité</h3>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    history.push("/ma-communication/editor?kind=NEWS")
                  }
                >
                  + Rédiger une actualité
                </button>
              </div>

              <PostList posts={newsPosts} />
            </div>
          </div>
          <div className="col-12 col-xl-6">
            <div className="card">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Événement</h3>
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    history.push("/ma-communication/editor?kind=EVENT")
                  }
                >
                  + Rédiger un événement
                </button>
              </div>
              <PostList posts={eventPosts} />
            </div>
          </div>
        </div>
      )}

      {errorSubscription ? (
        <h3 className="text-center mt-5">
          Vous devez vous abonner à l'option "Mon Réseau Pro" pour publier sur
          le fil d'actualité pro
        </h3>
      ) : (
        ""
      )}

      <div className="channel-toast" style={{ zIndex: 11 }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <div className="d-flex">
            <Toast.Body className="bg-success text-white">
              Post publié avec succès
            </Toast.Body>
          </div>
        </Toast>
      </div>

      <DeletePostModal
        show={showDeletionModal}
        onHide={handleDeleteModelClosed}
        post={postSelected}
      />
    </div>
  );
}
