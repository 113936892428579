import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ISyndicatPro, Syndicat } from "../../../interfaces/Syndicat";
import { ProService } from "../../../services/pro.service";

import "./verifyProSyndicat.scss";

export const ValidateSyndicatModal = ({
  showValidateModal,
  setShowValidateModal,
  currentProProps
}: any) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false)
  const [currentPro, setCurrentPro] = useState(currentProProps)

  useEffect(() => {
    setCurrentPro(currentProProps)
  }, [currentProProps])

  const handleValidate = async (_id: string, typePro: number) => {
    setLoading(true);
    const updatePro = await ProService.acceptSyndicat(_id, typePro);
    setCurrentPro(updatePro)
    setLoading(false);
  };

  const handleDeny = async (_id: string, typePro: number) => {
    setLoading(true);
    const updatePro = await ProService.declineSyndicat(_id, typePro);
    setCurrentPro(updatePro)
    setLoading(false);
  };

  console.log(currentProProps)

  return (
    <Modal show={showValidateModal} onHide={() => setShowValidateModal(false)} id="verify-syndicat-modal">
      <Modal.Header closeButton>
        <Modal.Title>Validation des syndicats</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{currentPro.nom} {currentPro.prenom} </h4>
        <p>Validation des differents syndicats</p> 

        {currentPro.syndicats?.map((syndicat: any) => (
          (syndicat.syndicatStatus === "UNDER_REVIEW") && (
          <div>
            <p className="mb-1"> <strong>Syndicat pour la profession de {syndicat.typePro.nom}</strong> </p>
            <div className="card mb-5" key={syndicat.syndicat._id}>
            <div className="card-header">
              <label>{syndicat.syndicat.sigle} {syndicat.syndicat.denomination}</label>
            </div>
            <div className="card-body">
              <p>Nom: {syndicat.syndicat.denomination}</p>
              <p>Adresse: {syndicat.syndicat.address.street} — {syndicat.syndicat.address.city}, {syndicat.syndicat.address.cp}</p>
              <p>Tel: {syndicat.syndicat.phoneNumber}</p>
            <Button
                  variant="success"
                  disabled={loading}
                  className={`mx-1 ${syndicat.syndicatStatus === "DECLINED" ? "hide" : ""}`}
                  onClick={() => handleValidate(currentPro._id, syndicat.typePro.id)}
                >
                  Valider
                </Button>
                <Button
                  variant="danger"
                  disabled={loading}
                  className={`${syndicat.syndicatStatus === "ACCEPTED" ? "hide" : ""}`}
                  onClick={() => handleDeny(currentPro._id, syndicat.typePro.id)}
                >Refuser
                </Button>
            </div>
               
          </div>
          </div>
          )
        ))}      
      </Modal.Body>
    </Modal>
  );
};
