import { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { faEdit, faEye, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Claim, ClaimStatus } from '../../../../interfaces/training-organisation/Claim';
import { trainingOrganizationService } from '../../../../services/training-organization.service';

const statusToColor = (status: ClaimStatus): string => {
  switch (status) {
    case ClaimStatus.ACCEPTED:
      return "lime";
    case ClaimStatus.DECLINED:
      return "red";
    case ClaimStatus.PENDING:
      return "orange";
  }
};

export const ClaimListItem = ({ claim }: { claim: Claim }) => {
  const [loading, setLoading] = useState(false);

  const handleValidate = async () => {
    await trainingOrganizationService.acceptClaim(claim._id);
  };

  const handleDeny = async () => {
    await trainingOrganizationService.declineClaim(claim._id);
  };

  return (
    <tr style={{ borderLeft: `${statusToColor(claim.status)} 4px solid` }}>
      <td>
        <div className="applicant">{claim.applicant.denomination}</div>
      </td>
      <td>
        {" "}
        <div className="target">{claim.target.denomination}</div>
      </td>
      <td>
        {" "}
        <div className="account">
          {claim.account?.email ?? "Pas de compte associé"}
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-center">
          <Button
            variant="success"
            disabled={loading}
            className="mx-1"
            onClick={() => handleValidate()}
          >
            Valider
          </Button>
          <Button
            variant="danger"
            disabled={loading}
            onClick={() => handleDeny()}
          >
            Refuser
          </Button>
        </div>
      </td>
    </tr>
  );
};
