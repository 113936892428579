import React, { useState } from 'react';
import { TypePro } from '../../interfaces/TypePro';
import CompoForm from './CompoForm';

interface props {
    hide: any;
}
const TypeProCreator = (props: props) => {
    const [typePro, setTypro] = useState({
        id: '',
        nom: '',
        idParent: '',

    })
    return (
        <CompoForm object={typePro} setObject={setTypro} hide={props.hide} path='typepro' />
    )
}

export default TypeProCreator;