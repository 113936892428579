import { useMemo, useState } from "react";
import { Alert, Modal, Table } from "react-bootstrap";
import { Avis, Report } from "../../interfaces/Avis";
import { Paginated } from "../../interfaces/Paginated";
import { Pagination } from "../Pagination";
import { ReportedAvisListItem } from "./ReportedAvisListItem";

export type ReportedAvisListProps = {
  data: Paginated<Avis>;
  setPage: (p: number) => void;
};

export const ReportedAvisList = ({
  data,
  setPage,
}: ReportedAvisListProps) => {
  const paginationElement = useMemo(
    () => <Pagination data={data} setPage={setPage} />,
    [data, setPage]
  );
  const [currentReports, setCurrentReports] = useState<Report[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  if (data.items.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  
  return (
    <>
      {paginationElement}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>raisons</th>
            <th>note</th>
            <th>établissement</th>
            <th>pseudo</th>
            <th>email</th>
            <th>titre</th>
            <th>plus</th>
            <th>moins</th>
            <th>date</th>
            <th>statut</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((avis, i) => (
            <ReportedAvisListItem key={avis._id} avis={avis} setCurrentReports={setCurrentReports} setShowModal={setShowModal}/>
          ))}
        </tbody>
      </Table>
      {paginationElement}
      <Modal 
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>Signalements</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>date</th>
                <th>raison</th>
                <th>commentaire</th>
              </tr>
            </thead>
            <tbody>
              {currentReports && currentReports.map((report:Report, i:Number) => (
                <tr key={`report-${i}`}>
                  <td>{new Date(report.date).toLocaleDateString()}</td> 
                  <td>{report.reason}</td>
                  <td>{report.text}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>        
    </>
  );
};

