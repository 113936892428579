import FormGroup from "react-bootstrap/esm/FormGroup";
import FormControl from "react-bootstrap/esm/FormControl";
import FormLabel from "react-bootstrap/esm/FormLabel";
import Modal from "react-bootstrap/esm/Modal";
import Button from "react-bootstrap/esm/Button";
import { Avis, AvisUpdate } from "../../interfaces/Avis";
import { defaultIndicators, Indicators } from "../../interfaces/Indicateurs";
import { AvisListItemIndicator } from "./AvisListItemIndicator";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Rate } from "./star";
import { TrainingOrganizationReview } from "../../interfaces/TrainingOrganizationReview";

type TrainingOrganizationReviewModalProps = {
  approve: (avis: TrainingOrganizationReview) => void;
  deny: () => void;
  avis: TrainingOrganizationReview;
  show: boolean;
  showModal: (arg: boolean) => () => void;
  loading: boolean;
};

export function TrainingOrganizationReviewModal({
  approve,
  deny,
  avis,
  loading,
  show,
  showModal,
}: TrainingOrganizationReviewModalProps) {
  const [modified, setModified] = useState(false);

  useEffect(() => {}, [show]);

  const { reset, register, handleSubmit } = useForm({
    criteriaMode: "all",
    defaultValues: {
      email: avis.email,
      plus: avis.plus,
      moins: avis.minus,
      pseudo: avis.pseudo,
      titre: avis.title,
    },
  });

  const send = (data: any) => {
    const updated: TrainingOrganizationReview = {
      ...avis,
      pseudo: data.pseudo,
      status: avis.status,
      title: data.title,
      plus: data.plus,
      minus: data.minus,
    };
    approve(updated);
  };

  return (
    <Modal show={show} onHide={showModal(false)} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {"Avis de " +
            avis.pseudo +
            " " +
            new Date(avis.createdAt ?? null).toLocaleDateString()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormLabel>Note</FormLabel>
        <Rate
          className="stars"
          note={avis.note}
          total={5}
          height="20px"
          full={undefined}
          empty={undefined}
        />
        <form onSubmit={handleSubmit(send)}>
          <FormGroup style={{ marginTop: "1rem" }}>
            <FormLabel>Pseudo</FormLabel>
            <FormControl
              as="input"
              className="w-100"
              type="text"
              {...register("pseudo")}
              onChange={(e) => setModified(true)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormControl
              as="input"
              className="w-100"
              type="email"
              {...register("email")}
              onChange={(e) => setModified(true)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Titre</FormLabel>
            <FormControl
              as="input"
              className="w-100"
              type="text"
              {...register("titre")}
              onChange={(e) => setModified(true)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Plus</FormLabel>
            <FormControl
              as="textarea"
              className="w-100"
              rows={6}
              {...register("plus")}
              onChange={(e) => setModified(true)}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Moins</FormLabel>
            <FormControl
              as="textarea"
              className="w-100"
              rows={6}
              {...register("moins")}
              onChange={(e) => setModified(true)}
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="success"
              type="submit"
              disabled={loading}
              className="mx-1"
            >
              {modified ? "Modifier et valider" : "Valider"}
            </Button>
            <Button variant="danger" disabled={loading} onClick={deny}>
              Refuser
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
