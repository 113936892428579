import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import { Avis, AvisUpdate } from "../../interfaces/Avis";
import { AvisListItemModal } from "./AvisListItemModal";
import { Rate } from "./star";
import { avisService } from "../../services/avis";
import { TrainingOrganizationReview } from "../../interfaces/TrainingOrganizationReview";
import { TrainingOrganizationReviewModal } from "./TrainingOrganizationReviewModal";
import { trainingOrganizationService } from "../../services/training-organization.service";

export const TrainingOrganizationReviewItem = ({
  avis,
  type,
  updateList,
}: {
  avis: TrainingOrganizationReview;
  type: string;
  updateList: () => void;
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [denomination] = useState(
    avis.trainingOrganization?.denomination ?? ""
  );

  const showModal = useCallback(
    (x: boolean) => () => {
      setShow(x);
    },
    []
  );

  const approve = async (updated: TrainingOrganizationReview) => {
    setLoading(true);
    try {
      await trainingOrganizationService.approve(avis._id, updated);
      setLoading(false);
      setShow(false);
      updateList();
      setLoading(false);
    } catch (error) {
      console.log("erreur", error);
    }
  };

  const deny = async () => {
    setLoading(true);
    try {
      await trainingOrganizationService.deny(avis._id);
      setLoading(false);
      setShow(false);
      updateList();
      setLoading(false);
    } catch (error) {
      console.log("erreur", error);
    }
  };

  return (
    <>
      <tr>
        <td>
          <Rate
            className="stars"
            note={avis.note}
            total={5}
            height="20px"
            full={undefined}
            empty={undefined}
          />
        </td>
        <td>{denomination}</td>
        <td>{avis.pseudo}</td>
        <td>{avis.email}</td>
        <td>{avis.title}</td>
        <td>{avis.plus}</td>
        <td>{avis.minus}</td>
        <td>{new Date(avis.createdAt).toLocaleDateString()}</td>
        <td>
          <div className="d-flex">
            <Button variant="info" onClick={showModal(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        </td>
      </tr>
      <TrainingOrganizationReviewModal
        approve={approve}
        deny={deny}
        loading={loading}
        show={show}
        showModal={showModal}
        avis={avis}
      />
    </>
  );
};
