import "./pros.scss";

import {
  Button,
  Card,
  Grid as GridAnt,
  Input,
  InputRef,
  Layout,
  Space,
  Table,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { ColumnType, FilterConfirmProps, Key } from "antd/es/table/interface";
import moment from "moment";
/** -----------------------------------  */
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";

import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { Pro } from "../../interfaces/Pro";
import { ProService } from "../../services/pro.service";
import { DisplayStatusProfil } from "./displayStatusProfil";

// import { IUser } from './utils/pro_list';

const { Meta } = Card;
const { Sider } = Layout;
const { useBreakpoint } = GridAnt;

type account = {
  email: string;
  firstname: string;
  name: string;
};

type pro = {
  cabinet: string;
  nom: string;
  prenom: string;
  subscription: {
    options: Array<string>;
    type: string;
    created?: number;
    current_period_start?: number;
    current_period_end?: number;
  };
  accounts: Array<account>;
  configurations: any;
};
interface IUser {
  email: string;
  nom: string;
  prenom: string;
  profilPic: string;
  configurations: any;
  subscription: {
    createdAt: string;
    type: string;
  };
  typePros: [
    {
      typePro: {
        nom: string;
      };
    }
  ];
}

const DetailedInfos = (props: any) => {
  const { content } = props;
  if (content === null) return <></>;
  return (
    <>
      <DisplayStatusProfil pro={content} />
    </>
  );
};

const CompletionInfo = (props: any) => {
  const { data } = props;
  if (data === null) return <></>;
  return <>{data.configurations.percent}%</>;
};

export const Pros2 = () => {
  // const [defaultList, setDefaultList] = useState([]);
  // const [pros, setPro] = useState([]);
  // const [error, setError] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");
  // const [showDetailedConfigurations, setShowDetailedConfigurations] = useState(false);
  // const [selectedPro, setSelectedPro] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [subscription, setSubscription] = useState<string[]>(["DISCOVER"]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [pros, setPros] = useState<Pro[]>([]);

  // TODO GENERALISER
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex: any): ColumnType<Pro> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    fetchData()
      .then((result) => {
        setPros(result.data);
        setTotal(result.total);
      })
      .catch((e) =>
        setError(
          `debug: erreur lors du chargement des pros: limit=${limit} page=${page} message=${
            e.message ?? "Aucun message"
          }`
        )
      );
  }, []);

  const fetchData = async () => {
    const res = await ProService.findAll(
      limit,
      page,
      subscription[0] || undefined
    );
    return res;
  };

  useEffect(() => {
    fetchData()
      .then((result) => {
        setPros(result.data);
        setTotal(result.total);
      })
      .catch((e) =>
        setError(
          `debug: erreur lors du chargement des pros: limit=${limit} page=${page} message=${
            e.message ?? "Aucun message"
          }`
        )
      );
  }, [page, limit, subscription]);

  const columns: ColumnsType<Pro> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Souscription",
      align: "center",
      children: [
        {
          title: "Type",
          align: "center",
          key: "subscription",
          dataIndex: ["subscription", "type"],
          render: (_: any, record: Pro) => (
            <Tag
              color={record.subscription?.type ? "green" : "red"}
              key={record.subscription?.type}
            >
              {record.subscription?.type?.toUpperCase() ?? "Erreur"}
            </Tag>
          ),
          filters: [
            {
              text: "Découverte",
              value: "DISCOVER",
            },
            {
              text: "Référencement",
              value: "REFERENCEMENT",
            },
            {
              text: "Partenaire",
              value: "PARTENAIRE",
            },
          ],
          onFilter: (value: string | number | boolean, record) => {
            return true;
          },
        },
        {
          title: "Infos",
          align: "center",
          dataIndex: ["subscription"],
          key: "subscription-info",
          render: (_: any, record: Pro) => (
            <>
              {record.subscription?.type === "DISCOVER" && (
                <label>
                  <p>
                    {moment
                      .duration(
                        moment(record.subscription.createdAt)
                          .add(3, "months")
                          .diff(moment(record.subscription.createdAt))
                      )
                      .asDays()}{" "}
                    jour.s restant.s
                  </p>
                </label>
              )}
            </>
          ),
        },
      ],
    },

    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (_, record) => (
        <Space size="middle">
          <Button>Supprimer</Button>
        </Space>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (_, record) => (
        <Space size="middle">
          {record.location ? <CheckOutlined /> : <CloseOutlined />}
        </Space>
      ),
    },
  ];

  return (
    <>
      {pros && (
        <Table
          columns={columns}
          showHeader={true}
          pagination={{
            position: ["topCenter", "bottomCenter"],
            total,
            pageSizeOptions: [10, 100],
            showQuickJumper: true,
            showSizeChanger: true,
          }}
          dataSource={pros}
          onChange={(pagination, filters, sorter) => {
            setPage(pagination.current as number);
            setLimit(pagination.pageSize as number);
            setSubscription((filters.subscription as string[]) ?? []);
            console.log("sorter", sorter);
            console.log("filters", filters);
            console.log("pagination", pagination);
          }}
        />
      )}
    </>
  );
};
