export enum EcoleStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  ALL = ''
}

export interface Ecole {
  _id: string;
  denomination: string;
  status: EcoleStatus;
  practices: string[];
  createdAt: Date;
  updatedAt: Date;
}
