import { useEffect, useState } from "react"
import { Collapse, Form, Select, Divider, Button, Spin } from "antd"
import { PratiquesService } from "../../services/pratique.service"
import GetPratiqueByIdAndDisplay from "./GetPratiqueByIdAndDisplay"

const DisplayTypePro = ({ typepro, pratiquesGlobal  }) => {
    const update_typepro_form = Form.useForm()
    const [pratique_id, setPratiqueId] = useState(null)
    const [pratiques, setPratiques] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        setPratiques(typepro.pratiques)
        setLoading(false)
    }, [typepro])

    const onPratiqueChange = (value) => {
        setPratiqueId(value)
    }
    const updateTypePro = async () => {
        if (pratique_id !== "Choississez une pratique") {
            if (pratique_id !== null) {
                const updatedTypePro = await PratiquesService.updateTypeProPratique(typepro._id, pratique_id)
                console.log(updatedTypePro.data.pratiques)
                setPratiques(updatedTypePro.data.pratiques)
            }
        }
    }
    if (loading) {
        return (
            <Spin />
        )
    }
    return (
        <div>
            <div>
                <Select
                    defaultValue={"Choississez une pratique"}
                    onChange={onPratiqueChange}
                    style={{ marginRight: 20 }}
                    options={pratiquesGlobal.map((pratique_global) => {
                        return {
                            'value': pratique_global._id,
                            'label': `${pratique_global.inmLib} - ${pratique_global.libelle}`
                        }
                    })}
                />
                <Button onClick={() => updateTypePro()}>Ajouter la pratique</Button>
            </div>
            {
                pratiques.length > 0 ? (
                    <>
                        <Divider />
                        {
                            pratiques.map((pratique_id, id) => {
                                return (
                                    <GetPratiqueByIdAndDisplay
                                        key={id}
                                        pratique_id={pratique_id}
                                        typepro={typepro}
                                    />
                                )
                            })
                        }
                    </>
                ) : (<div style={{ marginTop: 20 }}>Aucune pratique associer...</div>)
            }
        </div>
    )
}

export default DisplayTypePro