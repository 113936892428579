import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useLogin } from "../store/login.store";
import { FormOutlined, LogoutOutlined, StarOutlined } from "@ant-design/icons";
import { Button, Layout, Menu, theme } from "antd";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import SynagogueOutlinedIcon from "@mui/icons-material/SynagogueOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import _ from "underscore";
import "./with_login.scss";
import { ProService } from "../services/pro.service";

/**
 * WITH_LOGIN HOC
 * - check if user is loggedIn via Store
 * y -> return layout + component declare in route
 * n -> return Login.tsx via react-router-dom
 * ----------------------------------------------
 * DESIGN LAYOUT ANTD IN HOC WITH_LOGIN
 * on utilise le layout admin que si la personne est log CQFD
 *
 * !!!!!!! ATTENTION !!!!!!!!!!!!!!!
 * each routes newly created now must be consistent with sider menu !
 */

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const routes = {
  1: "/ecole",
  2: "/of/claims",
  3: "/syndicat/claims",
  4: "/pro/claims",
  5: "/formulaireCreator",
  6: "/etudes",
  7: "/etablissements",
  8: "/etablissements/claims",
  9: "/syndicats",
  10: "/notes",
  11: "/pros/partenariats",
  12: "/pro/cabinet-pro",
  13: "/pros",
  14: "/diplome",
  15: "/pros/syndicats",
  16: "/pros/siret",
  17: "/pros/reports",
  18: "/avisets",
  19: "/avispro",
  20: "/avisof",
  22: "/create-type-pro-pratiques",
  23: "/ma-communication",
  24: "/pratiques",
  25: "/pros-2",
};

const items = [
  getItem("Communication", "23", <ChatOutlinedIcon />),
  getItem("Diplome", "14", <WorkspacePremiumOutlinedIcon />),
  getItem("Ecole", "1", <SchoolOutlinedIcon />),
  getItem("Etablissement", "sub1", <BusinessOutlinedIcon />, [
    getItem("ETS", "7"),
    getItem("Avis", "18"),
    getItem("Claims", "8"),
  ]),
  getItem("Etudes", "6", <AnalyticsOutlinedIcon />),
  getItem("Formulaire Creator", "5", <FormOutlined />),
  getItem("Notes", "10", <StarOutlined />),
  getItem("Organisme", "sub2", <CorporateFareOutlinedIcon />, [
    getItem("Avis", "20"),
    getItem("Claims", "2"),
  ]),
  getItem("Pratiques", "24", <AppsOutlinedIcon />),
  getItem("Pros", "sub3", <PermIdentityOutlinedIcon />, [
    getItem("Liste Pros", "13"),
    getItem("Liste Pros 2", "25"),
    getItem("Avis", "19"),
    getItem("Cabinet", "12"),
    getItem("Claims", "4"),
    getItem("Partenariats", "11"),
    getItem("Reports", "17"),
    getItem("Siret", "16"),
    getItem("Syndicats", "15"),
  ]),
  getItem("Syndicat", "sub4", <SynagogueOutlinedIcon />, [
    getItem("Syndicats", "9"),
    getItem("Prise de contrôle", "3"),
  ]),
  getItem("TypePro", "22", <PersonAddAltOutlinedIcon />),
];

export function WithLogin({ component }) {
  const loggedIn = useLogin((state) => state.loggedIn);
  const logout = useLogin().logout;
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("14");

  let history = useHistory();
  let location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  useEffect(() => {
    if (!loggedIn) history.push("/");
  }, [loggedIn, history]);

  useEffect(() => {
    const path = location.pathname;
    const match = _.mapObject(routes, (val, key) => {
      if (val === path) {
        return key;
      } else {
        return null;
      }
    });
    const matchFiltered = _.find(match, (item) => item !== null);
    setSelectedMenu(matchFiltered);
  }, [location]);

  const handleMenuClick = (e) => {
    history.push(routes[e.key]);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          minHeight: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.2)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            color: "white",
          }}
        >
          {collapsed ? "H" : "HOSPITALIDEE"}
        </div>
        <Menu
          theme="dark"
          selectedKeys={selectedMenu}
          onClick={(e) => handleMenuClick(e)}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout
        className="animation-slide"
        style={{
          marginLeft: collapsed ? 80 : 200,
        }}
      >
        <Header
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              ProService.sendMailDiscover();
            }}
            danger
            style={{ marginRight: 30 }}
          >
            Ne pas cliquer - Envoi Mail DISCOVER PRO!!!
          </Button>
          logout
          <LogoutOutlined
            style={{ cursor: "pointer", marginLeft: 10 }}
            onClick={() => logout()}
          />
        </Header>
        <Content
          className="site-layout"
          style={{
            margin: "16px 16px",
          }}
        >
          <div
            className="site-layout-content"
            style={{
              padding: 24,
              background: colorBgContainer,
            }}
          >
            {component}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Hospitalidee ©2023 Created by Hospitalidee Team
        </Footer>
      </Layout>
    </Layout>
  );
}
