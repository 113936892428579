import { url as back } from "../server.json";

export const EtablissementService = {
    getEtablissement() {
        return fetch(
            `${back}/admin/etablissement/subscriptions`,
            {
              method: "GET",
              credentials: "include",
              mode: "cors",
              headers: { "Content-Type": "application/json" },
            }
          ).then(res =>  res.json())
    }
}