import { useEffect, useState } from "react";
import { studyService } from "../../../../services/study.service";
import { Button, Form, Modal, Toast } from "react-bootstrap";
import "./study-update.scss";
import EtudeName from "./StudyName";
import EtudeForms from "./StudyForms";
import EtudeVisibility from "./StudyVisibility";
import Select from "react-select";
import { etudeService } from "./etudes.service";
import { etudeLocaleService } from "./local-study";

const StudyUpdate = ({ etudeId, hasUpdated }) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [etude, setEtude] = useState(null);
  const [etudeToSend, setEtudeToSend] = useState(null);
  const [findError, setFindError] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [specialites, setSpecialites] = useState(null);

  const [expertises, setExpertises] = useState(null);

  const [error, setError] = useState("");

  useEffect(() => {
    etudeService
      .findAllSpecialites()
      .then((result) => setSpecialites(result.data))
      .catch((error) => setError(error));
    etudeService
      .findAllExpertises()
      .then((result) => setExpertises(result.data))
      .catch((error) => setError(error));
  }, []);

  useEffect(() => {
    console.log("etudeIdUpdated");
    if (hasBeenUpdated) {
      setHasBeenUpdated(false);
      setShowUpdateModal(true);
      return;
    }
    findEtude();
  }, [etudeId]);

  const findEtude = async () => {
    console.log("findEtude");
    if (!etudeId) return;
    await studyService
      .find(etudeId)
      .then((result) => {
        setEtude(result.data);
        setEtudeToSend({
          id: result.data.id,
          name: result.data.name,
          forms: result.data.forms.map((form) => form.id),
          visibility: result.data.visibility,
          specialites: result.data.specialites,
          expertises: result.data.expertises,
        });
      })
      .catch((error) => {
        setFindError(error);
        console.log(error);
        console.log(findError);
      });
  };

  const setEtudeName = (name) => {
    console.log("setEtudeName");
    setEtude({
      ...etude,
      name: name,
    });
    setEtudeToSend({
      ...etudeToSend,
      name: name,
    });
    setHasBeenUpdated(true);
  };

  const setEtudeForms = (forms) => {
    console.log("setEtudeForms");
    setEtude({
      ...etude,
      forms: forms,
    });
    setEtudeToSend({
      ...etudeToSend,
      forms: forms.map((form) => form.id),
    });
    setHasBeenUpdated(true);
  };

  useEffect(() => {
    console.log("etude", etude);
  }, [etude]);

  const setEtudeVisibility = (visibility) => {
    console.log("setEtudeVisibility");
    setEtude({
      ...etude,
      visibility: visibility,
    });
    setEtudeToSend({
      ...etudeToSend,
      visibility: visibility,
    });
    setHasBeenUpdated(true);
  };

  const update = async () => {
    await studyService
      .update(etudeToSend)
      .then((result) => {
        setEtude(result.data);
        setEtudeToSend({
          id: result.data.id,
          name: result.data.name,
          forms: result.data.forms.map((form) => form.id),
          visibility: result.data.visibility,
          expertises: result.data.expertises,
          specialites: result.data.specialites,
        });
        setUpdateSuccessful(true);
      })
      .catch((error) => {
        setUpdateError(error);
        console.log(error);
        console.log(findError);
      });
    hasUpdated();
    setHasBeenUpdated(false);
    if (etudeToSend.visibility.kind === "RESTRICTED") {
      let etsToCreateLs = etudeToSend.visibility.etablissements.filter(
        (ets) => {
          if (
            etude.visibility.etablissements.some((ets2) => ets.id === ets2.id)
          )
            return true;
        }
      );
      etsToCreateLs.forEach(async (ets) => {
        await etudeLocaleService.create({
          etude: etudeToSend.id,
          etablissement: ets.id,
          description: etudeToSend.description,
          objectif: 50,
          expertises: etudeToSend.expertises,
          specialites: etudeToSend.specialites,
        });
      });
    }
  };

  return (
    <div className="etude-view">
      {etude && etudeToSend && (
        <div>
          <EtudeName etudeName={etude.name} setEtudeName={setEtudeName} />
          <div className="etude-view-grp-2">
            <EtudeForms
              etudeForms={etude.forms}
              setEtudeForms={setEtudeForms}
            ></EtudeForms>
            <div className="etude-view-grp-3">
              <EtudeVisibility
                etudeVisibility={etude.visibility}
                setEtudeVisibility={setEtudeVisibility}
              ></EtudeVisibility>
              <Form.Label>Spécialité(s)</Form.Label>
              <Select
                className="select"
                isMulti
                options={specialites}
                placeholder="Selectionnez une ou plusieurs spécialités..."
                value={etudeToSend.specialites}
                getOptionValue={(x) => x._id}
                getOptionLabel={(x) => x.libelle}
                onChange={(e) => {
                  console.log("specialites", e);
                  setEtudeToSend({
                    ...etudeToSend,
                    specialites: e.map((option) => option._id),
                  });
                }}
              />
              <Form.Label>Expertise(s)</Form.Label>
              <Select
                className="select"
                isMulti
                options={expertises}
                placeholder="Selectionnez une ou plusieurs expertises..."
                value={etudeToSend.expertises}
                getOptionValue={(x) => x._id}
                getOptionLabel={(x) => x.libelle}
                onChange={(e) => {
                  console.log("test", e);
                  setEtudeToSend({
                    ...etudeToSend,
                    expertises: e.map((option) => option._id),
                  });
                }}
              />
              <div className="etude-view-buttons">
                <Button
                  variant="success outline-light"
                  onClick={async () => {
                    await update();
                  }}
                  size="lg"
                >
                  Enregistrer
                </Button>
                <Button
                  variant="danger outline-light"
                  disabled={true} //TODO Ajouter un modal de confirmation de la suppression
                  onClick={async () => {
                    await update();
                  }}
                  size="lg"
                >
                  Supprimer
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {etude && (
        <Modal
          show={showUpdateModal}
          onHide={() => setShowUpdateModal(false)}
          backdrop="static"
        >
          <Modal.Header>Mise à jour</Modal.Header>
          <Modal.Body>
            <p>
              Vous avez des modification en cours sur l'étude [{etude.name}],
              veuillez sauvegarder ou annuler les changements.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{ marginLeft: "30px" }}
              onClick={async () => {
                await update();
                await findEtude();
                setShowUpdateModal(false);
              }}
            >
              Sauvegarder
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              onClick={async () => {
                await findEtude();
                setHasBeenUpdated(false);
                setShowUpdateModal(false);
              }}
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {updateError && (
        <Toast
          onClose={() => setUpdateError(null)}
          show={updateError}
          delay={3000}
          autohide
          style={{ backgroundColor: "red", color: "white" }}
        >
          <Toast.Body>
            Un problème est servenue lors de la mise à jour de l'étude
          </Toast.Body>
        </Toast>
      )}
      {updateSuccessful && (
        <Toast
          onClose={() => setUpdateSuccessful(false)}
          show={updateSuccessful}
          delay={3000}
          autohide
          style={{ backgroundColor: "green", color: "white" }}
        >
          <Toast.Body>L'étude à bien été sauvegardée !</Toast.Body>
        </Toast>
      )}
    </div>
  );
};

export default StudyUpdate;
