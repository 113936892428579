import create from 'zustand';
import { persist } from 'zustand/middleware';
import { url as serverBase } from '../server.json';

interface LoginStoreState {
  loggedIn: boolean;
  error: string;
  login: (pwd: string) => void;
  logout: () => void;
  loading: boolean;
}

export const useLogin = create<LoginStoreState>(
  persist(
    (set) => ({
      loggedIn: false,
      loading: false,
      error: '',
      login: async (password: string) => {
        set({ loading: true, error: '', loggedIn: false });

        try {
          const response = await fetch(`${serverBase}/admin/login`, {
            method: 'POST',
            credentials: "include",
            mode: 'cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password }),
          });
          if (response.status === 200) set({ loggedIn: true });
          else set({ error: 'Invalid password' });
          set({ loading: false });
        }catch(err: any) {
          set({error: err})
          console.error(err)
        }
        
      },
      logout: () => set({ loggedIn: false, loading: false, error: '' }),
    }),
    {
      name: 'login',
      blacklist: ['loading', 'error', 'login', 'logout'],
      getStorage: () => sessionStorage,
    }
  )
);
