export enum DiplomeStatus {
  UNDER_REVIEW = 'UNDER_REVIEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export interface Diplome {
  _id: string;
  comment: string;
  createdAt: string;
  trainingOrganization: any;
  file: string;
  idPro: string;
  level: number;
  numberOfHours: number;
  otherEcole: string;
  rncp: string;
  status: DiplomeStatus;
  title: string;
  pro?: {
    email: string
    name: string;
    profession: string;
  }
  updatedAt: string;
}
