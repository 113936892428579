import { useState } from "react";
import { Col, Button, ButtonGroup, Table, Spinner } from "react-bootstrap";

import { Resource } from "../../../components/Resource";
import { ProKbisItem } from "./ProKbisItem";

export const VerifyProKbis = () => {
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState("verify")

    return (
        <div id="verify-syndicat">
          <Col className="d-flex justify-content-between mb-4">
            <div>
              <h1>Pro SIRET</h1>
            </div>
            <div className="d-flex align-items-center">
              <ButtonGroup aria-label="Basic example">
                <Button
                  variant="secondary"
                  style={{ opacity: tab === "verify" ? 1 : 0.7 }}
                  onClick={() => setTab("verify")}
                >
                  À vérifier
                </Button>
                <Button
                  variant="secondary"
                  style={{ opacity: tab === "verified" ? 1 : 0.7 }}
                  onClick={() => setTab("verified")}
                >
                  Vérifié
                </Button>
              </ButtonGroup>
            </div>
          </Col>
          <Col>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Pro</th>
                  <th>Kbis</th>
                  <th>Siret</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading && <Spinner animation="border" />}
              {!loading && (
                <Resource
                  interval={1000}
                  url="/admin/pro/kbis"
                  render={(data: any) => (
                    <tbody>
                      {data
                        .filter((pro: any) => {
                          if (tab === "verified") {
                            return pro.siret
                          } else {
                            return !pro.siret;
                          }
                        })
                        
                        .map((pro: any) => (<ProKbisItem pro={pro} key={pro._id}/>))}             
                        </tbody>
                  )}
                />
              )}
            </Table>
          </Col>
        </div>
      );
}