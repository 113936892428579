import { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/esm/Button";
import FormControl from "react-bootstrap/esm/FormControl";
import FormGroup from "react-bootstrap/esm/FormGroup";
import FormLabel from "react-bootstrap/esm/FormLabel";
import Modal from "react-bootstrap/esm/Modal";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import { Avis, AvisUpdate } from "../../interfaces/Avis";
import { defaultIndicators, Indicators } from "../../interfaces/Indicateurs";
import { avisService } from "../../services/avis";
import { AvisListItemIndicator } from "./AvisListItemIndicator";
import { Rate } from "./star";

type AvisListItemModalProps = {
  updateList: () => void;
  setShow: (arg: boolean) => void;
  avis: Avis;
  show: boolean;
  showModal: (arg: boolean) => () => void;
  loading: boolean;
  isEts: boolean;
  target: string;
};

export function AvisListItemModal({
  avis,
  isEts,
  show,
  setShow,
  showModal,
  updateList,
  target,
}: AvisListItemModalProps) {
  const [answer, setAnswer] = useState({
    text: "",
    isHidResp: false,
  });
  const [modified, setModified] = useState(false);
  const [indicators, _setIndicators] = useState<Indicators>(defaultIndicators);
  const [note, setNote] = useState(avis.note);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) return;
    console.log("avis a afficher:", avis);
    setAnswer({ ...answer, text: "" });
    setIndicators(defaultIndicators);
    reset({
      note: avis.note,
      email: avis.email,
      plus: avis.plus,
      moins: avis.moins,
      pseudo: avis.pseudo,
      titre: avis.titre,
    });
  }, [show]);

  const approve = async (updated: AvisUpdate) => {
    setLoading(true);
    try {
      await avisService.approve(avis._id, updated, isEts);
      setShow(false);
      updateList();
    } catch (error: any) {
      console.log(error);
      return Swal.fire({
        title: "Une erreure est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const deny = async () => {
    setLoading(true);
    try {
      await avisService.deny(avis._id, isEts);
      setShow(false);
      updateList();
    } catch (error: any) {
      return Swal.fire({
        title: "Une erreure est survenue",
        text: error.message,
        icon: "error",
        customClass: {
          confirmButton: "validate-btn",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  const { reset, register, handleSubmit, watch } = useForm({
    criteriaMode: "all",
    defaultValues: {
      note: avis.note,
      email: avis.email,
      plus: avis.plus,
      moins: avis.moins,
      pseudo: avis.pseudo,
      titre: avis.titre,
      reponse: {
        text: "",
        isHidResp: false,
      },
    },
  });

  const watchReponse = watch("reponse.text");

  useEffect(() => {
    reset({ reponse: answer });
  }, [answer]);

  const setIndicators = useCallback(
    (arg: Partial<Indicators>) => {
      _setIndicators({ ...indicators, ...arg });
    },
    [indicators]
  );

  const send = (data: any) => {
    const updated: AvisUpdate = {
      ...avis,
      note: note,
      pseudo: data.pseudo,
      statut: avis.statut,
      titre: data.titre,
      plus: data.plus,
      moins: data.moins,
      reponse:
        isEts &&
        data.reponse &&
        avis.etablissement?.subscription?.options?.includes("AIDE_REPONSE")
          ? { ...data.reponse, isHidResp: Boolean(watchReponse) }
          : undefined,
      indicators: isEts ? (indicators ? indicators : undefined) : undefined,
    };
    approve(updated);
  };

  function setIndicator<T extends keyof Indicators>(name: T) {
    return (v: Indicators[T]) => {
      setIndicators({ [name]: v });
      console.log(indicators.service);
    };
  }

  const handleNote = (value: number) => {
    if (value === 1 && note < 5) {
      setNote(note + 1);
      setModified(true);
      return;
    }
    if (value === -1 && note > 1) {
      setNote(note - 1);
      setModified(true);
      return;
    }
  };

  return (
    <Modal
      show={show}
      onHide={showModal(false)}
      backdrop="static"
      fullscreen={true}
    >
      <form onSubmit={handleSubmit(send)} className="h-100">
        <Modal.Header closeButton style={{ height: "5%" }}>
          <Modal.Title>
            {"Avis de " +
              avis.pseudo +
              " " +
              new Date(avis.date ?? null).toLocaleDateString() +
              " " +
              target}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "85%", overflow: "scroll" }}>
          <Row>
            <div className="d-flex mb-3">
              <Rate
                className="stars"
                note={note}
                total={5}
                height="40px"
                full={"gold"}
                empty={undefined}
              />
              <Button
                variant={"dark"}
                className="mx-1"
                size="sm"
                style={{ fontSize: "20px" }}
                onClick={() => handleNote(-1)}
              >
                <b>-</b>
              </Button>
              <Button size="sm" variant={"dark"} onClick={() => handleNote(+1)}>
                <b>+</b>
              </Button>
            </div>
            <Col sm={12} md={8}>
              <FormGroup>
                <FormLabel>Pseudo</FormLabel>
                <FormControl
                  as="input"
                  className="w-100"
                  type="text"
                  {...register("pseudo")}
                  onChange={(e) => setModified(true)}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Email</FormLabel>
                <FormControl
                  as="input"
                  className="w-100"
                  type="email"
                  {...register("email")}
                  onChange={(e) => setModified(true)}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Titre</FormLabel>
                <FormControl
                  as="input"
                  className="w-100"
                  type="text"
                  {...register("titre")}
                  onChange={(e) => setModified(true)}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Plus</FormLabel>

                <FormControl
                  style={{ height: "80px" }}
                  as="textarea"
                  className="w-100"
                  rows={6}
                  {...register("plus")}
                  onChange={() => setModified(true)}
                />
              </FormGroup>
              <FormGroup className="mb-2">
                <FormLabel>Moins</FormLabel>
                <FormControl
                  style={{ height: "80px" }}
                  as="textarea"
                  className="w-100"
                  rows={6}
                  {...register("moins")}
                  onChange={() => setModified(true)}
                />
              </FormGroup>
              {isEts &&
                avis.etablissement?.subscription?.options?.includes(
                  "AIDE_REPONSE"
                ) &&
                avis.etablissement?.delegateAvisResp && (
                  <div className="answer">
                    <FormLabel>Répondre</FormLabel>
                    <FormControl
                      {...register("reponse.text")}
                      as="textarea"
                      className="w-100"
                      disabled={false}
                      rows={6}
                    />
                    {avis.etablissement?.answertemplates?.map((at: any) => (
                      <button
                        type="button"
                        style={{
                          marginInline: "5px",
                          color:
                            at.category === "positive"
                              ? "rgb(0, 175, 0)"
                              : "rgb(175, 0, 0)",
                          borderColor:
                            at.category === "positive"
                              ? "rgb(0, 175, 0)"
                              : "rgb(175, 0, 0)",
                          backgroundColor:
                            at.category === "positive"
                              ? "rgb(0, 175, 0)"
                              : "rgb(175, 0, 0)",
                          cursor: "pointer",
                          marginRight: "5rem !important",
                          padding: ".25rem .5rem",
                          fontSize: ".875rem",
                          lineHeight: "1.5",
                          borderRadius: ".2rem",
                        }}
                        onClick={() => {
                          setModified(true);
                          setAnswer({ ...answer, text: at.content });
                        }}
                      >
                        {at.name}
                      </button>
                    ))}
                  </div>
                )}
            </Col>
            <Col sm={12} md={4} className="px-3">
              {isEts && (
                <Row>
                  <Col xs={6}>
                    <AvisListItemIndicator
                      indicator={"parking"}
                      setIndicator={setIndicator("parking")}
                    />
                    <AvisListItemIndicator
                      indicator={"reception"}
                      setIndicator={setIndicator("reception")}
                    />
                    <AvisListItemIndicator
                      indicator={"room"}
                      setIndicator={setIndicator("room")}
                    />
                    <AvisListItemIndicator
                      indicator={"food"}
                      setIndicator={setIndicator("food")}
                    />
                    <AvisListItemIndicator
                      indicator={"organisation"}
                      setIndicator={setIndicator("organisation")}
                    />
                    <AvisListItemIndicator
                      indicator={"doctorRelationship"}
                      setIndicator={setIndicator("doctorRelationship")}
                    />
                  </Col>
                  <Col xs={6}>
                    <AvisListItemIndicator
                      indicator={"caregiversRelationship"}
                      setIndicator={setIndicator("caregiversRelationship")}
                    />

                    <AvisListItemIndicator
                      indicator={"medicalErrors"}
                      setIndicator={setIndicator("medicalErrors")}
                    />
                    <AvisListItemIndicator
                      indicator={"pain"}
                      setIndicator={setIndicator("pain")}
                    />
                    <AvisListItemIndicator
                      indicator={"service"}
                      setIndicator={setIndicator("service")}
                    />
                    <AvisListItemIndicator
                      indicator={"invoice"}
                      setIndicator={setIndicator("invoice")}
                    />
                    <AvisListItemIndicator
                      indicator={"covid"}
                      setIndicator={setIndicator("covid")}
                    />
                  </Col>
                </Row>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: "1rem",
                }}
              ></div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ height: "10%" }}>
          <Row className="w-100" xs={2}>
            <Col>
              <Button
                className="w-100"
                variant="success"
                type="submit"
                disabled={loading}
              >
                {modified ? "Modifier et valider" : "Valider"}
              </Button>
            </Col>
            <Col>
              <Button
                className="w-100"
                variant="danger"
                disabled={loading}
                onClick={deny}
              >
                Refuser
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
