import axios from "axios";
import { url as serveur } from "../server.json";

const instance = axios.create({
  withCredentials: true,
});

class ClaimProService {
    async getAllClaimPro(page: number, limit: number) {
        try { 
            const res = await instance.get(`${serveur}/claimed/all/${page}/${limit}`, {
                withCredentials: true,
                headers: { "Content-Type": "application/json" }
            })
            return res
        } catch (err: any) {
            if (err.response) {
                console.log("err", err);
                return Promise.reject(err.response.data);
            }
        }
    }
}

export const ClaimsProService = new ClaimProService()