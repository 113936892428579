import "./pros.scss";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { styled } from "@mui/system";
import Check from "@mui/icons-material/Check";
import PriorityHigh from "@mui/icons-material/PriorityHigh";
import PropTypes from "prop-types";
import { ProgressBar } from "react-bootstrap";
import {
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  StepContent,
} from "@mui/material";
import "./detailed-configurations-modal.scss";

export const DisplayStatusProfil = ({ pro }) => {
  const [activeStep, setActiveStep] = useState(0);

  if (!pro) return <></>;

  const _getConfigLabel = (label) => {
    switch (label) {
      case "KBIS":
        return <span>Extrait KBIS</span>;
      case "EMAIL":
        return "Email";
      case "PASSWORD":
        return "Mot de passe";
      case "COORDINATES":
        return <span>Coordonnées</span>;
      case "PROFESSION":
        return <span>Profession</span>;
      case "DIPLOMES":
        return <span>Diplômes</span>;
      case "PAIEMENT_TYPES":
        return <span>Paiements acceptés</span>;
      case "APPOINTMENT_TYPES":
        return <span>Séances</span>;
      case "AGENDA":
        return <span>Agenda</span>;
      case "PROFIL":
        return <span>Profil</span>;
      case "SYNDICAT":
        return <span>Syndicat</span>;
      case "RESUME":
        return <span>Résumé</span>;
      case "PROFIL_PIC":
        return <span>Photo de profil</span>;
      case "PHOTOS":
        return <span>Photos</span>;
      case "CABINET":
        return <span>Nom du cabinet</span>;
      case "AUTOCERTIFICATION":
        return <span>Autocertification</span>;
      default:
        return "";
    }
  };
  const stepProps = {};
  const labelProps = {};
  const labelStyle = { padding: "0px" };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: { borderColor: "#784af4" },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: { borderColor: "#784af4" },
    },
    [`& .${stepConnectorClasses.line}`]: {
      minHeight: "10px",
      borderColor: "#784af4",
    },
  }));

  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => {
    return {
      zIndex: 1,
      color: "red",
      width: 25,
      height: 25,
      paddingLeft: "3px",
      display: "flex",
      border: "1px solid black",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      borderColor: "gray",
      svg: {
        fontSize: "15px !important",
      },

      ...(ownerState.active && { border: "1px solid" }),
      ...(ownerState.completed && {
        borderColor: "green",
        color: "green",
      }),
    };
  });

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot
        ownerState={{ active, completed }}
        className={className}
      >
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <PriorityHigh className="QontoStepIcon-uncompletedIcon" />
        )}
      </QontoStepIconRoot>
    );
  }

  const QontoStepIconRoot2 = styled("div")(({ theme, ownerState }) => {
    return {
      zIndex: 1,
      color: "green",
      width: 20,
      height: 20,
      border: "none",
      paddingLeft: "3px",
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      svg: {
        fontSize: "12px !important",
      },
      ...(ownerState.completed && {
        color: "green",
      }),
    };
  });

  function QontoStepIcon2(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot2
        ownerState={{ active, completed }}
        className={className}
      >
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div
            style={{
              border: "1px solid red",
              height: "10px",
              width: "10px",
              borderRadius: "50%",
            }}
          />
        )}
      </QontoStepIconRoot2>
    );
  }

  const QontoStepIconRoot3 = styled("div")(({ theme, ownerState }) => {
    return {
      zIndex: 1,
      color: "red",
      width: 25,
      height: 25,
      paddingLeft: "3px",
      display: "flex",
      border: "1px solid black",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      borderColor: "gray",
      svg: {
        fontSize: "15px",
      },

      ...(ownerState.active && { border: "1px solid red" }),
      ...(ownerState.completed && {
        borderColor: "green",
        color: "green",
        i: {
          fontSize: "12px !important",
          paddingRight: "4px",
        },
      }),
    };
  });

  function QontoStepIcon3(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot3
        ownerState={{ active, completed }}
        className={className}
      >
        {completed ? (
          <i className="fas fa-trophy" />
        ) : (
          <PriorityHigh className="QontoStepIcon-uncompletedIcon" />
        )}
      </QontoStepIconRoot3>
    );
  }

  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
  };

  return (
        <div
          id="completion"
          className={
            "dashboard__card" +
            (pro.configurations.isShowable ? "" : " incomplete")
          }
        >
          <p>Cabinet : {pro.cabinet ?? "Non renseigné"}</p>
          <p>
            Pro : {pro.prenom ?? ""} {pro.nom ?? ""}
          </p>
          <p className="mb-0">
            Progression : {parseFloat(pro.configurations.percent).toFixed(0)}%
          </p>
          <div className="position-relative mb-3">
            <div className="dot"></div>
            <ProgressBar
              now={parseFloat(pro.configurations.percent).toFixed(0)}
              max={100}
            />
          </div>
          <p className="mb-0">
            Champs nécéssaires :{" "}
            {parseFloat(pro.configurations.requiredPercent).toFixed(0)}%
          </p>
          <div className="position-relative mb-3">
            <div className="dot"></div>
            <ProgressBar
              now={parseFloat(pro.configurations.requiredPercent).toFixed(0)}
              max={100}
            />
          </div>
          <Stepper
            nonLinear
            activeStep={activeStep}
            connector={<QontoConnector />}
            orientation="vertical"
          >
            {pro.configurations.list.map((config, index) => {
              return (
                <Step
                  key={index}
                  completed={config.completed}
                  active={true}
                  {...stepProps}
                >
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    onClick={() => setActiveStep(index)}
                    style={labelStyle}
                    {...labelProps}
                  >
                    {_getConfigLabel(config.name)}{" "}
                    {config.required && <span style={{ color: "red" }}>*</span>}
                  </StepLabel>
                  <StepContent {...stepProps}>
                    {config.list && (
                      <Stepper
                        nonLinear
                        className="mt-1"
                        connector={<div style={{ height: "5px" }} />}
                        orientation="vertical"
                      >
                        {config.list.map((nested, index) => {
                          return (
                            <Step
                              key={index}
                              completed={nested.completed}
                              {...stepProps}
                            >
                              <StepLabel
                                StepIconComponent={QontoStepIcon2}
                                style={labelStyle}
                                {...labelProps}
                              >
                                {_getConfigLabel(nested.name)}{" "}
                                {nested.required && (
                                  <span style={{ color: "red" }}>*</span>
                                )}
                              </StepLabel>
                            </Step>
                          );
                        })}
                      </Stepper>
                    )}
                  </StepContent>
                </Step>
              );
            })}
            <Step
              key={"end-step"}
              completed={
                pro.configurations.isShowable && pro.configurations.verified
              }
              {...stepProps}
            >
              <StepLabel
                StepIconComponent={QontoStepIcon}
                style={labelStyle}
                {...labelProps}
              >
                Profil validé
              </StepLabel>
            </Step>
            <Step
              key={"end-step-2"}
              completed={pro.configurations.isShowable}
              {...stepProps}
            >
              <StepLabel
                StepIconComponent={QontoStepIcon3}
                style={labelStyle}
                {...labelProps}
              >
                Profil visible
              </StepLabel>
            </Step>
          </Stepper>
        </div>
  );
};
