import { Button, Form, Modal } from 'react-bootstrap';

import { url } from '../../server.json';
import { ecoleService } from '../../services/ecole.service';
import { trainingOrganizationService } from '../../services/training-organization.service';

export const RemoveEcoleModal = ({
  showRemoveModal,
  setShowRemoveModal,
  ecole,
  onDelete,
}: any) => {
  const removeEcole = async () => {
    try {
      const result = await trainingOrganizationService.delete(ecole._id);
      onDelete(ecole._id);
      setShowRemoveModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Supprimer Ecole</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Etes vous sur de vouloir supprimer cette ecole ?</h4>

        <div className="d-flex align-items-center justify-content-around">
          <Button variant="danger" onClick={removeEcole}>
            Supprimer
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => setShowRemoveModal(false)}
          >
            Annuler
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
