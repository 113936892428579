import { useMemo, useEffect, useState } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Table from "react-bootstrap/esm/Table";
import { Avis } from "../../interfaces/Avis";
import { Paginated } from "../../interfaces/Paginated";
import { Pagination } from "../Pagination";
import { AvisListItem } from "./AvisListItem";
import { avisService } from "../../services/avis";
import _ from 'underscore'

export type AvisetsListProps = {
  setPage: (p: number) => void;
  data: Paginated<Avis>;
  type: string;
  updateList: () => void;
};

export const AvisList = ({
  data,
  setPage,
  type,
  updateList,
}: AvisetsListProps) => {

  const [proNames, setProNames] = useState([])

  useEffect(() => {
    async function getName() {
      const arrayOfIdPro = _.pluck(data.items, 'pro')
      const res = await avisService.getAllProNameForAvis(arrayOfIdPro)
      setProNames(res)
    }
    getName()
  }, [])
  const paginationElement = useMemo(
    () => <Pagination data={data} setPage={setPage} />,
    [data, setPage]
  );

  if (data.items.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  return (
    <>
      {paginationElement}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>note</th>
            <th>pro</th>
            <th>établissement</th>
            <th>pseudo</th>
            <th>email</th>
            <th>titre</th>
            <th>plus</th>
            <th>moins</th>
            <th>date</th>
            <th>statut</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((avis, i) => (
            <AvisListItem
              key={avis._id}
              avis={avis}
              type={type}
              proName={proNames[i]}
              updateList={updateList}
            />
          ))}
        </tbody>
      </Table>
      {paginationElement}
    </>
  );
};
