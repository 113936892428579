import { useCallback, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import Table from 'react-bootstrap/Table';
import { Diplome, DiplomeStatus } from '../../interfaces/Diplome';
import { VerifiedDiplomeListItem } from './VerifiedDiplomeListItem';

const inTitle = (d: Diplome, f: string) => d.title?.toLowerCase().includes(f);
const inProName = (d: Diplome, f: string) => d.pro?.name?.toLowerCase().includes(f);
const inProfession = (d: Diplome, f: string) => d.pro?.profession?.toLowerCase().includes(f);
const inComment = (d: Diplome, f: string) => d.comment?.toLowerCase().includes(f);
const inRncp = (d: Diplome, f: string) => d.rncp?.toLowerCase().includes(f);

type TFilter = 'unfiltered' | DiplomeStatus.ACCEPTED | DiplomeStatus.DECLINED;

export const VerifiedDiplomeList = ({ diplomes }: { diplomes: Diplome[] }) => {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState<TFilter>('unfiltered');
  const [dip, setDip] = useState<Array<Diplome>>([])

  useEffect(() => {
    setDip(diplomes)
  }, [])
  
  const getFiltered = useCallback(() => {
    const f = search.toLowerCase();
    let filtered = dip.filter(
      (diplome) => {
        return (
          inTitle(diplome, f) ||
          inProName(diplome, f) ||
          inProfession(diplome, f) ||
          inComment(diplome, f) ||
          inRncp(diplome, f)
        )
      }
    );
    if (filter !== 'unfiltered') {
      filtered = filtered.filter((diplome) => diplome.status === filter);
    }
    return filtered;
  }, [diplomes, search, filter, dip]);

  const handleFilterClick = useCallback(
    (newFilter: TFilter) => {
      if (filter === newFilter) {
        setFilter('unfiltered');
      } else {
        setFilter(newFilter);
      }
    },
    [filter]
  );

  if (diplomes.length === 0) return <Alert variant='success'>Aucun resultat</Alert>;
  return (
    <>
      <div className='d-flex align-items-center mb-2'>
        <FormControl
          as='input'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Rechercher (Nom, Profession, Diplôme, RNCP, Commentaire)'
        />
        <Button
          variant={filter === DiplomeStatus.ACCEPTED ? 'warning' : 'secondary'}
          onClick={() => handleFilterClick(DiplomeStatus.ACCEPTED)}
          className='mx-2'>
          Accepté
        </Button>
        <Button
          variant={filter === DiplomeStatus.DECLINED ? 'warning' : 'secondary'}
          onClick={() => handleFilterClick(DiplomeStatus.DECLINED)}>
          Refusé
        </Button>
      </div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Créé le</th>
            <th>Modifié le</th>
            <th>Nom</th>
            <th>Profession</th>
            <th>Diplôme</th>
            <th>RNCP</th>
            <th>Heures</th>
            <th>Commentaire</th>
            <th>Niveau</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {getFiltered().map((diplome, i) => (
            <VerifiedDiplomeListItem key={diplome._id} diplome={diplome} dip={dip} setDip={setDip} />
          ))}
        </tbody>
      </Table>
    </>
  );
};
