import { useContext, useEffect, useState } from "react"
import { Spin, Tag, Select, Button } from "antd"
import { PlusOutlined, EditFilled } from "@ant-design/icons"
import { BesoinsContext, GlobalSourceContext, PratiqueSelectedContext, TypeProSelectedContext, BesoinSelectedContext } from "./context"
import { PratiquesService } from "../../services/pratique.service"
import GetBesoinById from "./GetBesoinById"

const GetPratiqueByIdAndDisplay = ({ pratique_id, setConfigModal, setModalOpen, typepro }) => {
    const [loading, setLoading] = useState(false)
    const [current_pratique, setCurrentPratique] = useState({})
    const [besoinId_selected, SetBesoinIdSelected] = useState(null)
    const [besoins, setBesoins] = useState([])
    let besoins_globaux = useContext(BesoinsContext)
    let pratique_select = useContext(PratiqueSelectedContext)
    let typepro_selected =  useContext(TypeProSelectedContext)
    let global = useContext(GlobalSourceContext)
    let besoin_selected = useContext(BesoinSelectedContext)
    useEffect( async () => {
        setLoading(true)
        const res = await PratiquesService.getPratiqueById(pratique_id)
        console.log(res.data)
        setCurrentPratique(res.data)
        setBesoins(res.data.besoins)
        setLoading(false)
    }, [])

    const onChange = (value) => {
        SetBesoinIdSelected(value)
    }

    const handleClickTagBesoin = (pratique) => {
        global.setConfigModal('besoin')
        pratique_select.func({
            value: current_pratique,
            func: pratique_select.func
        })
        besoin_selected.func({
            value: null,
            func: besoin_selected.func
        })
        global.setModalOpen(true)
    }

    const addBesoinToPratique = async () => {
        if (besoinId_selected !== "choississez un besoin") {
            const updated_pratique = await PratiquesService.updatePratiqueBesoin(besoinId_selected, current_pratique._id)
            console.log(updated_pratique.data)
            setCurrentPratique(updated_pratique.data)
            setBesoins(updated_pratique.data.besoins)
            SetBesoinIdSelected(null)
        }
    }

    const updatePratiqueModal = () => {
        pratique_select.func({
            value: current_pratique,
            func: pratique_select.func
        })
        typepro_selected.func({
            value: typepro,
            func: typepro_selected.func
        })
        global.setConfigModal('pratique')
        global.setModalOpen(true)
    }

    if (loading) {
        return (
            <Spin />
        )
    }
    if (!besoins) return <></>
    return (
        <div style={{ marginBottom: 20 }}>
            <span>{current_pratique.libelle}, <i style={{ fontSize: 10 }}>{current_pratique.inmLib} - {current_pratique.typeLib}</i></span>
            <Select
                onChange={onChange}
                defaultValue="choississez un besoin"
                style={{ marginRight: 20, marginLeft: 20 }}
                options={besoins_globaux.besoins.map((b) => {
                    return {
                        'value': b._id,
                        'label': `${b.libelle}`
                    }
                })}
            />
            <Button onClick={addBesoinToPratique}>Ajouter le besoin</Button>
            <div style={{ float: 'right'}}>
                <EditFilled style={{ cursor: 'pointer' }} onClick={() => updatePratiqueModal()} />
            </div>
            <hr />
            <p>{current_pratique.definition ? current_pratique.definition : <i>Aucune definition pour cette pratique</i>}</p>
            {
                besoins.length === 0 && (<i>Aucun besoin pour cette pratique</i>)
            }
            {
               besoins.length > 0 && besoins.map((besoin, id) => {
                    return (
                        <GetBesoinById key={id} besoin={besoin} besoins={besoins} setBesoins={setBesoins} pratique_id={current_pratique._id} />
                    )
                })
            }
            <Tag onClick={() => handleClickTagBesoin()} style={{ borderStyle: 'dashed', cursor: 'pointer' }} color='blue'><PlusOutlined style={{ verticalAlign: 1 }} /> besoin</Tag>
        </div>
    )
}

export default GetPratiqueByIdAndDisplay