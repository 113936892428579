import { useEffect, useState, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { Toast } from "react-bootstrap";

import Loader from "../../components/loader/loader";
import { PostImage } from "./PostImage";
import { TagEditor } from "./TagEditor";
import { EventSpecificFields } from "./EventSpecificFields";
import postEditionService from "../../services/post-edition";
import ConfirmLeaveModal from "./ConfirmLeaveModal";

export const PostEditor = () => {
  //Know if feature is enabled

  const params = useParams();
  const initialKind = new URLSearchParams(useLocation().search).get("kind");
  const [loading, setLoading] = useState(true);
  const [errorSubscription, setErrorSubscription] = useState(false);

  const [postId, setPostId] = useState(params.postId);
  const [originalPost, setOriginalPost] = useState({});
  const [kind, setKind] = useState(initialKind ? initialKind : "NEWS");
  const [title, setTitle] = useState();
  const [tags, setTags] = useState([]);
  const [content, setContent] = useState();
  const [event, setEvent] = useState({});
  const [imageKey, setImageKey] = useState();
  const [channel, setChannel] = useState('GRAND PUBLIC');
  const [editionState, setEditionState] = useState("DRAFT");
  const [unsavedContent, setUnsavedContent] = useState(false);
  const [showConfirmLeaveModal, setShowConfirmLeaveModal] = useState(false);
  const history = useHistory();
  const editorRef = useRef(null);

  useEffect(() => {
    (async () => {
      if (postId) {
        try {
          const post = await postEditionService.get(postId);
          setOriginalPost(post);
          setKind(post.kind);
          setTitle(post.title);
          setContent(post.content);
          setTags(post.tags);
          setImageKey(post.image);
          setEditionState(post.editionState);
          setChannel(post.channel);
          // -- Event-Post specific
          setEvent(post.event);
          setLoading(false);

          // editorRef.current = content
          // --
        } catch (err) {
          console.error(err);
          _leave();
        }
      } else {
        setLoading(false);
      }
    })();
  }, [postId]);

  function handleLeave() {
    if (unsavedContent) {
      setShowConfirmLeaveModal(true);
    } else {
      _leave();
    }
  }

  function handleConfirmLeaveClosed(confirm) {
    setShowConfirmLeaveModal(false);
    if (confirm) {
      _leave();
    }
  }

  function _leave() {
    if (editionState) {
      history.push(`/ma-communication?editionState=${editionState}`);
    } else {
      history.push("/ma-communication");
    }
  }

  async function _createDraft() {
    const isDraft = true;
    const draft = await postEditionService.create(_getPostData(), isDraft);
    setPostId(draft.id);
    setEditionState("DRAFT");
    return draft.id;
  }

  function _getPostData() {
    const common = {
      kind,
      title,
      content,
      tags,
      channel,
    };

    if (kind === "EVENT") {
      return {
        ...common,
        event,
      };
    } else {
      //'NEWS'
      // No specific fields
      return common;
    }
  }

  async function handleValidate() {
    if (!postId) {
      await _createDraft();
    } else {
      await postEditionService.update(postId, _getPostData());
    }
    _leave();
  }

  const handleEventChange = (event) => {
    setEvent(event);
    setUnsavedContent(true);
  };

  function handleTitleChange(event) {
    setTitle(event.target.value);
    setUnsavedContent(true);
  }

  function handleContentChange() {
    setContent(editorRef.current.getContent());
    setUnsavedContent(true);
  }

  function handleTagsAdd(tag) {
    setTags([...tags, tag]);
    setUnsavedContent(true);
  }

  function handleChannelChange(channel) {
    setErrorSubscription(false);
    setChannel(channel);
    setUnsavedContent(true);
  }

  function handleRemoveTag(removeTag) {
    const updatedTags = tags.filter(
      (tag) => tag.value !== removeTag.value || tag.kind !== removeTag.kind
    );
    setTags(updatedTags);
    setUnsavedContent(true);
  }

  // Important : 'imageKey' is **NOT** used by backend
  // Backend return last load image at "/api/post/editor/${postId}/image"
  // It's a trick to tell React to re-load image when the image is uptaded

  return (
    <div className="communication">
      <h2>
        Ma Communication - Rédiger{" "}
        {kind === "NEWS" ? "une Actualité" : "un Événement"}
      </h2>
      {loading ? (
        <Loader />
      ) : (
        <div className="editor-grid-area content">
          <div className="card" id="info">
            <div className="row mb-3">
              <label htmlFor="titleInput">Titre</label>
              <input
                type="text"
                value={title}
                id="titleInput"
                onChange={handleTitleChange}
                className="col-12"
                placeholder="Titre de l'article"
              ></input>
            </div>
            <div className="row mb-3">
              <label htmlFor="channel">Fil d'actualité</label>
              <select name="channel" id="channel" className="col-12" value={channel} onChange={(e) => handleChannelChange(e.target.value)}>
                <option value="GRAND PUBLIC">Grand Public</option>
                <option value="PROFESSIONNELS">Professionnels</option>
                <option value="GRAND PUBLIC & PROFESSIONNELS">Grand Public & Professionnels</option>
              </select>

              {errorSubscription ? (
                <p>Vous devez vous abonner à l'option "Mon Réseau Pro" pour publier sur le fil d'actualité pro</p>
              ) : ""}
            </div>
            <TagEditor
              tags={tags}
              removeTag={handleRemoveTag}
              addTag={handleTagsAdd}
            />

            {kind === "NEWS" ? (
              ""
            ) : (
              <EventSpecificFields event={event} update={handleEventChange} />
            )}

            <PostImage
              imgKey={imageKey}
              postId={postId}
              createDraft={_createDraft}
              onChange={setUnsavedContent}
            />
          </div>

          <div className="card" id="editor">
            <div>
              <h3>Déontologie à respecter</h3>
              <ul>
                <li>
                  Rester sincère quant à ses domaines de compétences et ne pas
                  faire de fausses promesses
                </li>
                <li>Ne pas se proclamer plus compétent que son voisin</li>
                <li>Ne pas avancer des éléments non-prouvés</li>
              </ul>
            </div>

            <div>
              <h3>Nos recommandations</h3>
              <ul>
                <li>3000 mots</li>
                <li>Mot-clés en gras, 3 répétitions</li>
                <li>Titre: répondre à un besoin, une émotion des clients</li>
                <li>Bien choisir les titres-balises</li>
              </ul>
            </div>

            <hr />

            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              tinymceScriptSrc="../../../plugin/tinymce/tinymce.min.js"
              onChange={handleContentChange}
              id="editor-content"
              initialValue={originalPost.content}
              init={{
                language: "fr_FR",
                selector: "textarea#TODO",
                // plugins:
                //   "advlist lists" + " searchreplace " + " paste help wordcount" + "table",
                // toolbar: "undo redo" + " | bold italic" + " | bullist numlist" + '| formatselect' + '| table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'table', 'wordcount'
                ],
                toolbar: 'undo redo  | ' +
                'bold italic ' +
                'bullist numlist outdent indent | ' +
                'removeformat',
                block_formats: 'Paragraph=p; Titre=h3; Sous Titre=h4;'
              }}
            />
          </div>
        </div>
      )}

      <div className="w-100 d-flex justify-content-end">
        <button
          onClick={handleValidate}
          className="btn-blue mr-2"
          disabled={!unsavedContent}
        >
          {postId ? "Mettre à jour" : "Créer"}
        </button>
        <button onClick={handleLeave} className="btn btn-blue-outline">
          Quitter
        </button>
      </div>


      <ConfirmLeaveModal
        show={showConfirmLeaveModal}
        onHide={handleConfirmLeaveClosed}
      />
    </div>
  );
};
