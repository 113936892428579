import React from "react";
import { Form, Button } from "react-bootstrap";
import { url as serveur } from "../../server.json";
import axios from 'axios';

interface props {
    object: any;
    setObject: any;
    path: string;
    hide: any;
}

const CompoForm = (props: props) => {
    const object = props.object;
    const setObject = props.setObject;
    const path = props.path;
    const send = () => {
        axios({
            url: `${serveur}/${path}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: object
        }).then((e) => {
            if (e.status === 201) {
                props.hide()
            }
        });
    }


    return (
        <div style={{ padding: '20px' }}>
            <Form>
                {
                    Object.keys(object).map((key, k) => (
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>{key}</Form.Label>
                            <Form.Control type="text" placeholder={key}
                                value={object[key]}
                                onChange={(e) => setObject({
                                    ...object,
                                    [key]: e.target.value,
                                })} />
                        </Form.Group>
                    ))
                }
            </Form>
            <Button style={{ margin: '10px' }} onClick={() => send()}>Valider</Button>
            <Button onClick={() => props.hide(null)}>Annuler</Button>
        </div>
    )
}

export default CompoForm;