import { useContext, useEffect, useRef, useState } from 'react'
import { Form, Select, Button, Input, Tag } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TweenOneGroup } from 'rc-tween-one';
import { BesoinSelectedContext, GlobalSourceContext, PratiqueSelectedContext } from './context';
import { PratiquesService } from '../../services/pratique.service';

const CreateBesoin = () => {
    const [tags, setTags] = useState([])
    const [form_besoin] = Form.useForm()
    const inputRef = useRef(null)
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState(null)
    let besoin_selected = useContext(BesoinSelectedContext)
    let pratique_selected = useContext(PratiqueSelectedContext)
    let global = useContext(GlobalSourceContext)
    const [updateState, setUpdateState] = useState(false)

    useEffect(() => {
        if (besoin_selected.value) {
            setUpdateState(true)
            if (besoin_selected.value.motsClefs.length === 0 && besoin_selected.value.motsCles) {
                setTags(besoin_selected.value.motsCles)
            } else {
                setTags(besoin_selected.value.motsClefs)
            }
            form_besoin.setFieldsValue({ libelle: besoin_selected.value.libelle })
        } else {
            form_besoin.resetFields()
            setTags([])
            form_besoin.setFieldsValue({ libelle: "" })
        }
    }, [besoin_selected.value])
    
    /** begin tag */
    useEffect(() => {
        if (inputVisible) {
          inputRef.current?.focus();
        }
    }, [inputVisible]);

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag);
        setTags(newTags);
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };
    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
          setTags([...tags, inputValue]);
        }
        setInputVisible(false);
        setInputValue('');
    };
    const forMap = (tag) => {
        const tagElem = (
          <Tag
            className='tag-besoin'
            closable
            onClose={(e) => {
                e.preventDefault();
                handleClose(tag);
            }}
          >
            {tag}
          </Tag>
        );
        return (
          <span
            key={tag}
            style={{
              display: 'inline-block',
            }}
          >
            {tagElem}
          </span>
        );
    };
    const tagChild = tags.map(forMap);
    const tagPlusStyle = {
        borderStyle: 'dashed',
        cursor: 'pointer'
    };
    /** end tag */
    const submitBesoin = async (values) => {
        const body = {
            libelle: values.libelle,
            motsClefs: tags,
            pratique: values.pratique
        }
        const res = await PratiquesService.createBesoin(body)
        global.setModalOpen(false)
        form_besoin.resetFields()
        setTags([])
        // lance une update sur tout le document sans rafraichir la page
        global.setUpdate(true)
        // setBesoins(besoins.concat(res))
    }
    const updateBesoin = async () => {
        const body = {
            libelle: form_besoin.getFieldValue("libelle"),
            motsClefs: tags,
            _id: besoin_selected.value._id
        }

        console.log(body)
        const res = await PratiquesService.updateBesoin(body)
        global.setModalOpen(false)
        form_besoin.resetFields()
        setTags([])
        // lance une update sur tout le document sans rafraichir la page
        global.setUpdate(true)
    }
    return (
        <Form
            name='create_besoin'
            onFinish={updateState ? updateBesoin: submitBesoin}
            form={form_besoin}
            initialValues={{
                libelle: updateState ? besoin_selected.value.libelle : "",
                pratique: pratique_selected.value ? pratique_selected.value._id : ""
            }}
        >
            <Form.Item
                label="Libelle"
                name="libelle"
                rules={[
                    {
                        required: true,
                        message: 'Le libelle est requis!',
                    },
                ]}                            
            >
                <Input />
            </Form.Item>
            {
                updateState ? (null) : (
                    <Form.Item
                        label="Pratique"
                        name="pratique"
                    >
                        <Select
                            style={{ minWidth: 400 }}
                            options={global.pratiquesGlobal.map((pratique) => {
                                return {
                                    'value': pratique._id,
                                    'label': `${pratique.libelle}`
                                }
                            })}
                        />
                    </Form.Item>
                )
            }
            <div
                style={{
                    marginBottom: 16,
                }}
            >
                <TweenOneGroup
                    enter={{
                        scale: 0.8,
                        opacity: 0,
                        type: 'from',
                        duration: 100,
                    }}
                    onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                            e.target.style = 'display: inline-block';
                        }
                    }}
                    leave={{
                        opacity: 0,
                        width: 0,
                        scale: 0,
                        duration: 200,
                    }}
                    appear={false}
                >
                    {tagChild}
                </TweenOneGroup>
            </div>
            {inputVisible ? (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    style={{
                        width: 78,
                    }}
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            ) : (
                <Tag onClick={showInput} style={tagPlusStyle}>
                    <PlusOutlined style={{ verticalAlign: 2 }}/> mot clef
                </Tag>
            )}
            <Form.Item>
                <Button htmlType='submit' style={{ marginTop: 20 }}>{ updateState ? ("Mettre à jour le besoin") : ("Ajouter le besoin")}</Button>
            </Form.Item>
        </Form>
    )
}

export default CreateBesoin