import { Account } from "../Account";
import { TrainingOrganization } from "./TrainingOrganization";

export enum ClaimStatus {
  PENDING = "1",
  DECLINED = "2",
  ACCEPTED = "3",
}

export interface Claim {
  _id: string;
  applicant: TrainingOrganization;
  account: Account;
  target: TrainingOrganization;
  status: ClaimStatus;
  email: string;
  createdAt?: string;
  updatedAt?: string;
  invitation: any;
}
