import { useState, useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import postEditionService from "../../services/post-edition";

export default function DeletePostModal({ show, onHide, post }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  async function deletePost() {
    setIsLoading(true);
    setError(null);

    try {
      await postEditionService.delete(post.id);
      handleClose(post);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose(post) {
    onHide(post);
  }

  return (
    <>
      {post && (
        <Modal
          show={show}
          onHide={onHide}
          centered
          className="answer-template__modal"
        >
          <Modal.Body className="flex flex-col align-items-center">
            <div>
              <i className="far fa-trash-alt fa-3x" aria-hidden="true" />
            </div>
            <p className="my-4 text-center">
              Etes-vous sûrs de vouloir supprimer {post.title} ?
            </p>
            {error && <span>Une erreur est survenue</span>}
            <Button
              className="btn btn-blue btn-sm"
              onClick={deletePost}
              disabled={isLoading}
            >
              Supprimer
            </Button>
            <Button
              className="mt-2 btn-noStyle"
              onClick={() => handleClose()}
              disabled={isLoading}
            >
              Annuler
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
