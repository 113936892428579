import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

import { Ecole } from "../../interfaces/Ecole";
import { url } from "../../server.json";
import { ecoleService } from "../../services/ecole.service";
import { trainingOrganizationService } from "../../services/training-organization.service";

export const UpdateEcoleModal = ({
  showUpdateModal,
  setShowUpdateModal,
  ecole,
}: any) => {
  const [error, setError] = useState("");
  const [sigle, setSigle] = useState(ecole.sigle ?? "");
  const [denomination, setDenomination] = useState(ecole.denomination ?? "");
  const [contactMail, setContactMail] = useState(ecole.contactMail ?? "");
  const [street, setStreet] = useState(ecole.address?.street ?? "");
  const [cp, setCp] = useState(ecole.address?.cp ?? "");
  const [city, setCity] = useState(ecole.address?.city ?? "");
  const [url, setUrl] = useState(ecole.url ?? "");

  const updateEcole = async (e: any) => {
    e.preventDefault();
    const data = {
      sigle,
      denomination,
      contactMail,
      address: {
        street,
        cp,
        city,
      },
      url,
    };
    try {
      const newEcole = await trainingOrganizationService.update(
        ecole._id,
        data
      );
      setShowUpdateModal(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSigleChange = (e: any) => {
    setSigle(e.target.value);
  };

  const handleDenominationChange = (e: any) => {
    setDenomination(e.target.value);
  };

  const handleContactMailChange = (e: any) => {
    setContactMail(e.target.value);
  };

  const handleUrlChange = (e: any) => {
    setUrl(e.target.value);
  };

  const handleStreetChange = (e: any) => {
    setStreet(e.target.value);
  };

  const handleCityChange = (e: any) => {
    setCity(e.target.value);
  };

  const handleCpChange = (e: any) => {
    setCp(e.target.value);
  };

  return (
    <Modal show={showUpdateModal} onHide={() => setShowUpdateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Modifer une école</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={updateEcole}>
          <Form.Group className="mb-2">
            <Form.Label htmlFor="denomination">Dénomination</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="denomination"
              id="denomination"
              value={denomination}
              onChange={handleDenominationChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="sigle">Sigle</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="sigle"
              id="sigle"
              value={sigle}
              onChange={handleSigleChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="contactMail">Email de contact</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="contactMail"
              id="contactMail"
              value={contactMail}
              onChange={handleContactMailChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="url">Lien url</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="url"
              id="url"
              value={url}
              onChange={handleUrlChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="street">Rue et libellé de voie</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="street"
              id="street"
              value={street}
              onChange={handleStreetChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="city">Ville</Form.Label>
            <Form.Control
              as="input"
              type="text"
              name="city"
              id="city"
              value={city}
              onChange={handleCityChange}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label htmlFor="cp">Code postal</Form.Label>
            <Form.Control
              as="input"
              type="number"
              name="cp"
              id="cp"
              value={cp}
              onChange={handleCpChange}
            />
          </Form.Group>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <Button type="submit" variant="success">
            Mettre à jour
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
