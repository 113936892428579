import { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { Claim } from "../../../../interfaces/training-organisation/Claim";
import { ClaimListItem } from "./ClaimListItem";

export const ClaimList = ({ claims }: { claims: Claim[] }) => {
  if (claims.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Demandeur</th>
          <th>Demandé</th>
          <th>Compte</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {claims.map((claim, i) => (
          <ClaimListItem key={claim._id} claim={claim} />
        ))}
      </tbody>
    </Table>
  );
};
