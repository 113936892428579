import { useEffect, useState } from "react";
import moment from "moment";

import Loader from "../../components/loader/loader";


export const EventSpecificFields = ({ event, update }) => {
  const [loader, setLoader] = useState(true);
  const [kind, setKind] = useState(event?.kind);
  const [startDate, setStartDate] = useState(event?.startDate);
  const [endDate, setEndDate] = useState(event.endDate);
  const [address, setAddress] = useState(event?.address?.address);
  const [city, setCity] = useState(event?.address?.city);
  const [postalCode, setPostalCode] = useState(event?.address?.postalCode);

  const [isAddress, setIsAddress] = useState(Boolean(event?.address?.address));
  const [proAddress, setProAddress] = useState({});

  const handleIsAddress = () => {
    const newIsAddress = !isAddress;
    setIsAddress(newIsAddress);
    const newEvent = { ...event };

    let address, city, postalCode;

    if (newIsAddress) {
      address = proAddress.address?.address;
      city = proAddress.address?.city;
      postalCode = proAddress.address?.postalCode;

      setAddress(address);
      setCity(city);
      setPostalCode(postalCode);
    }

    newEvent.address = {
      address,
      city,
      postalCode,
    };

    update(newEvent);
  };

  const handleKindChange = (value) => {
    setKind(value);

    const newEvent = { ...event };
    newEvent.kind = value;
    update(newEvent);
  };

  const handleStartDateChange = (value) => {
    setStartDate(value);

    const newEvent = { ...event };
    newEvent.startDate = moment.utc(value).format("yyyy-MM-DDTHH:mm");
    update(newEvent);
  };

  const handleEndDateChange = (value) => {
    setEndDate(value);

    const newEvent = { ...event };
    newEvent.endDate = moment.utc(value).format("yyyy-MM-DDTHH:mm");
    update(newEvent);
  };

  const handleAddressChange = (value) => {
    setAddress(value);

    const newEvent = { ...event };
    newEvent.address = {
      ...newEvent.address,
      address: value,
    };

    update(newEvent);
  };

  const handleCityChange = (value) => {
    setCity(value);

    const newEvent = { ...event };
    newEvent.address = {
      ...newEvent.address,
      city: value,
    };
    update(newEvent);
  };

  const handlePostalCodeChange = (value) => {
    setPostalCode(value);

    const newEvent = { ...event };
    newEvent.address = {
      ...newEvent.address,
      postalCode: value,
    };
    update(newEvent);
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="row event-informations">
          <div className="col-12 mb-3">
            <div className="row">
              <label htmlFor="eventTypeInput">Type d'événement</label>
              <select
                id="eventTypeInput"
                value={kind}
                onChange={(e) => handleKindChange(e.target.value)}
                className="col-12"
              >
                <option value="course">Stage</option>
                <option value="workshop">Atelier</option>
                <option value="stay">Séjour</option>
              </select>
            </div>
          </div>
          <div className="col-12 dates-input mb-3">
            <div className="row">
              <div className="col-6">
                <label htmlFor="startDateInput">Début</label>
                <input
                  type="datetime-local"
                  className="col-12"
                  id="startDateInput"
                  value={
                    event.startDate
                      ? moment(startDate).format("yyyy-MM-DDTHH:mm")
                      : ""
                  }
                  onChange={(e) => handleStartDateChange(e.target.value)}
                ></input>
              </div>
              <div className="col-6">
                <label htmlFor="endDateInput">Fin</label>
                <input
                  type="datetime-local"
                  className="col-12"
                  id="endDateInput"
                  value={
                    event.endDate
                      ? moment(endDate).format("yyyy-MM-DDTHH:mm")
                      : ""
                  }
                  onChange={(e) => handleEndDateChange(e.target.value)}
                ></input>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="row d-flex align-items-center">
              <label>En ligne</label>
              <label className="switch mx-3">
                <input
                  type="checkbox"
                  hidden
                  checked={isAddress}
                  onChange={handleIsAddress}
                />
                <span className="slider round"></span>
              </label>

              <label>Sur place</label>
            </div>
          </div>
          {isAddress ? (
            <div className="col-12">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="row">
                    <label htmlFor="eventAddress">Adresse</label>
                    <input
                      type="text"
                      id="eventAddress"
                      className="col-12"
                      value={address}
                      onChange={(e) => handleAddressChange(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="row">
                        <label htmlFor="eventCity">Ville</label>
                        <input
                          type="text"
                          id="eventCity"
                          className="col-12"
                          value={city}
                          onChange={(e) => handleCityChange(e.target.value)}
                        ></input>
                      </div>
                    </div>

                    <div className="col-4">
                      <div className="row">
                        <label htmlFor="eventPostalCode">Code Postal</label>
                        <input
                          type="text"
                          id="eventPostalCode"
                          className="col-12"
                          value={postalCode}
                          onChange={(e) =>
                            handlePostalCodeChange(e.target.value)
                          }
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
};
