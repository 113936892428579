import { useState } from "react"
import { Alert, Button } from "react-bootstrap"
import {url as back} from "../../../server.json"
import { ProService } from "../../../services/pro.service"
import { Pro } from "../../../interfaces/Pro"

export const ProKbisItem = (props: any) => {
    const pro:Pro = props.pro
    const [siret, setSiret] = useState(pro.siret)
    const [error, setError] = useState("")

    const updateKbis = async (id: string, siret: string) => {
      try {
        if(siret.length !== 14)
        throw new Error("Le format du SIRET n'est pas bon")
        const update = await ProService.updateKbis(id, siret)
      } catch (error) {
        console.error(error)
        setError(error.message)
      }
    }
    return (
        <tr>
        <td>
          {pro.prenom ?? ""} {pro.nom ?? ""}
        </td>
        <td>{pro.kbis?.file ?? ""} <a href={`${back}/pros/document/${pro.kbis?.file}`} target={"_blank"}><Button>voir</Button></a></td>
        <td><input type="text" value={siret} placeholder="Inscrire le SIRET" onChange={(e) => setSiret(e.target.value)}/> {error ? <Alert variant="danger">{error}</Alert>: ""}</td>
        <td><Button variant="success" onClick={() => updateKbis(pro._id, siret)}>Valider</Button></td>
      </tr>
    )
}