import axios from 'axios';

import { AvisUpdate } from '../interfaces/Avis';
import { TrainingOrganizationReview } from '../interfaces/TrainingOrganizationReview';
import { url as serveur } from '../server.json';

const instance = axios.create({
  withCredentials: true,
});

class TrainingOrganizationService {
  async approve(id: string, avis: TrainingOrganizationReview): Promise<any> {
    return instance.put(
      `${serveur}/admin/training-organization/review/${id}/approve`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: avis,
      }
    );
  }

  async create(data: any): Promise<any> {
    return instance.post(
      `${serveur}/admin/training-organization/`,
      { ...data },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  }

  async delete(id: string) {
    return instance.delete(`${serveur}/admin/training-organization/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async deny(id: string): Promise<any> {
    return instance.put(
      `${serveur}/admin/training-organization/review/${id}/deny`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
  }

  async findAll() {
    const ret = await instance.get(`${serveur}/training-organization/`);
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async update(id: string, data: any) {
    const ret = await instance.put(
      `${serveur}/admin/training-organization/${id}/draft`,
      data
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async publish(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/training-organization/${id}/publish`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async toDraft(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/training-organization/${id}/to-draft`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async acceptClaim(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/training-organization/claim/${id}/accept`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }

  async declineClaim(id: string) {
    const ret = await instance.put(
      `${serveur}/admin/training-organization/claim/${id}/decline`
    );
    if (ret) return Promise.resolve(ret.data);
    else return Promise.reject("Not found");
  }
}

export const trainingOrganizationService = new TrainingOrganizationService();
