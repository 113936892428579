import { Avis } from "../../interfaces/Avis";
import { Rate } from "./star";
import { TrainingOrganizationReview } from "../../interfaces/TrainingOrganizationReview";

export const TrainingOrganizationVerifiedReviewItem = ({
  avis,
}: {
  avis: TrainingOrganizationReview;
}) => {
  return (
    <tr>
      <td>
        <Rate
          className="stars"
          note={avis.note}
          total={5}
          height="20px"
          full={undefined}
          empty={undefined}
        />
      </td>
      <td>{avis.trainingOrganization?.denomination}</td>
      <td>{avis.pseudo}</td>
      <td>{avis.email}</td>
      <td>{avis.title}</td>
      <td>{avis.plus}</td>
      <td>{avis.minus}</td>
      <td>{new Date(avis.createdAt).toLocaleDateString()}</td>
    </tr>
  );
};
