import React, { useState } from 'react';
import axios from 'axios';
import { url as serveur } from "../../server.json";
import { Form, Button } from 'react-bootstrap';

interface props {
    hide: any;
}
interface besoinCreate {
    libelle: string;
    motsCles: Array<string>;
    pratiques: Array<any>;
}

const BesoinCreator = (props: props) => {
    const [newKeyword, setNewKeyword] = useState<string>('');
    const [besoin, setBesoin] = useState<besoinCreate>({
        libelle: '',
        motsCles: [],
        pratiques: [],
    });
    const send = () => {
        axios({
            url: `${serveur}/besoins`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            data: besoin
        }).then((e) => {
            if (e.status === 201) {
                props.hide()
            } else {
                console.log(e);
            }
        });
    }
    const addKeyword = () => {
        const newArray = besoin.motsCles;
        newArray.push(newKeyword);
        setBesoin({
            ...besoin,
            motsCles: newArray,
        });
        setNewKeyword('');
    }

    return (
        <div style={{ padding: '20px' }}>
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>libelle</Form.Label>
                    <Form.Control type="text"
                        value={besoin.libelle}
                        onChange={(e) => setBesoin({
                            ...besoin,
                            libelle: e.target.value,
                        })} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="keywork">
                    <Form.Label>Mot clef</Form.Label>
                    <Form.Control type="text"
                        value={newKeyword}
                        onChange={(e) => setNewKeyword(e.target.value)} />
                    <Button onClick={() => addKeyword()}>Ajouter</Button>
                    <div
                        style={{ border: 'solid 1px', borderRadius: '8px', margin: '10px', }}
                    >
                        {
                            besoin.motsCles.map((keyword, k) => (
                                <div
                                    style={{ margin: '10px', }}
                                    key={k}>{keyword}</div>
                            ))
                        }
                    </div>
                </Form.Group>
            </Form>
            <Button style={{ margin: '10px' }} onClick={() => send()}>Valider</Button>
            <Button onClick={() => props.hide(null)}>Annuler</Button>
        </div>
    )
};

export default BesoinCreator;