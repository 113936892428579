import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

import { url as serveur } from '../../server.json';

interface props {
  hide: any;
}
const PratiqueCreator = (props: props) => {
  // TODO make a fetch to easy update
  const liste: any = {
    1: [
      "Art thérapies",
      "Programmes d'éducation santé",
      "Psychothérapie",
      "Pratiques psychocorporelles",
      "Thérapies Assistées par l'animal",
    ],
    2: [
      "Programmes d'activités physique",
      "Hortithérapie",
      "Physiothérapies",
      "Thérapies manuelles",
      "Techniques de puncture",
      "Programmes balnéologiques",
    ],
    3: ["Compléments alimentaires", "Programmes nutritionnels"],
    4: [
      "m-santé",
      "Thérapies par le jeu vidéo",
      "Thérapie par la réalité virtuelle",
    ],
    5: [
      "Préparations minérales",
      "Préparations botaniques",
      "Méthodes éléctro-magnétiques",
      "Cosméceutiques",
    ],
  };

  const inmListe = [
    "Psychologiques",
    "Physiques",
    "Nutritionnelles",
    "Numériques",
    "Elémentaires",
  ];

  //Object to send
  const [object, setObject] = useState<any>({});

  const send = () => {
    axios({
      url: `${serveur}/pratiques`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: object,
    }).then((e) => {
      if (e.status === 201) {
        props.hide();
      }
    });
  };

  console.log("object", object);
  return (
    <div
      style={{
        paddingTop: "50px",
        paddingBottom: "40px",
        paddingLeft: "20px",
        paddingRight: "20px",
      }}
    >
      <Form>
        <Form.Group>
          <Form.Label>Libellée</Form.Label>
          <Form.Control
            type="text"
            placeholder="libele"
            value={object["libele"]}
            onChange={(e) =>
              setObject({
                ...object,
                libelle: e.target.value,
              })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>INM</Form.Label>
          <Form.Select
            aria-label="Default select example"
            onChange={(e: any) =>
              setObject({
                ...object,
                inm: e.target.value,
                inmLib: inmListe[e.target.value - 1],
              })
            }
          >
            {inmListe.map((value, k) => (
              <option value={k + 1}>{value}</option>
            ))}
          </Form.Select>
        </Form.Group>
        {object.inm && (
          <Form.Group>
            <Form.Label>Type:</Form.Label>
            <Form.Select
              onChange={(e: any) =>
                setObject({
                  ...object,
                  type: e.target.value,
                  typeLib: liste[object.inm][e.target.value - 1],
                })
              }
            >
              {liste[object.inm].map((value: string, k: number) => (
                <option key={k} value={k + 1}>
                  {value}
                </option>
              ))}
            </Form.Select>
            <Form.Label>MCA</Form.Label>
            <Form.Select
              onChange={(e: any) =>
                setObject({
                  ...object,
                  mca: e.target.value,
                })
              }
            >
              <option value={0}>Inconnues</option>
              <option value={1}>Acceptées</option>
              <option value={2}>Tolérées</option>
              <option value={3}>Rejetées</option>
            </Form.Select>
            <Button style={{ marginTop: "30px" }} onClick={() => send()}>
              Ajouter
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export default PratiqueCreator;
