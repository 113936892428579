import axios from 'axios';

import { AvisUpdate } from '../interfaces/Avis';
import { url as serveur } from '../server.json';

const instance = axios.create({
  withCredentials: true,
});

class AvisService {
  async approve(id: string, avis: AvisUpdate, isEts: boolean): Promise<any> {
    try {
      const ret = await instance.put(
        `${serveur}/admin/avis/${isEts ? "ets" : "pro"}/${id}/approve`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          data: avis,
        }
      );
      return ret;
    } catch (err: any) {
      console.log("err", err.response.data);
      return Promise.reject(err.response.data);
    }
  }

  async deny(id: string, isEts: boolean): Promise<any> {
    try {
      const ret = instance.put(
        `${serveur}/admin/avis/${isEts ? "ets" : "pro"}/${id}/deny`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return ret;
    } catch (err: any) {
      console.log("err", err.response.data);
      return Promise.reject(err.response.data);
    }
  }

  async approveAfterReport(id: string, isEts: boolean): Promise<any> {
    try {
      const ret = instance.put(
        `${serveur}/admin/avis/${isEts ? "ets" : "pro"}/${id}/approveAfterReport`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return ret;
    } catch (err: any) {
      console.log("err", err.response.data);
      return Promise.reject(err.response.data);
    }
  }

  async denyAfterReport(id: string, isEts: boolean): Promise<any> {
    try {
      const ret = instance.put(
        `${serveur}/admin/avis/${isEts ? "ets" : "pro"}/${id}/denyAfterReport`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
        
      return ret;
    } catch (err: any) {
      console.log("err", err.response.data);
      return Promise.reject(err.response.data);
    }
  }


  async getReportsDetailed(idAvis: string, isEts: boolean) : Promise<any> {
    try {
      const ret = await instance.get(
        `${serveur}/admin/avis/${isEts ? "ets" : "pro"}/reported/${idAvis}/detailed`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return ret.data;
    } catch (err: any) {
      console.log("err", err.response.data);
      return Promise.reject(err.response.data);
    }
  }

  async getAllProNameForAvis(arrayOfIdPro: Array<number|undefined>) : Promise<any> {
    const res = await instance.post(`${serveur}/admin/avis/pro/get_name`,  arrayOfIdPro, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },  
    })
    return res.data 
  }
}

export const avisService = new AvisService();
