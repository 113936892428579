import { useRef, useState } from "react";
import { Spinner, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/esm/Col";

import { Resource } from "../../../components/Resource";
import { ISyndicatPro, Syndicat } from "../../../interfaces/Syndicat";
import { syndicatService } from "../../../services/syndicat.service";
import { CreateSyndicatModal } from "../../syndicat/CreateSyndicatModal";
import { ValidateSyndicatModal } from "./ValidateSyndicatModal";

import "./verifyProSyndicat.scss"

export const VerifyProsSyndicats = () => {
  const [loading, setLoading] = useState(false);
  const [createSyndicatModal, setCreateSyndicatModal] = useState(false);
  const [validateSyndicatModal, setValidateSyndicatModal] = useState(false);
  const [currentPro, setCurrentPro] = useState({})

  const [tab, setTab] = useState<"verify" | "verified">("verify");
  const statusToColor = (status: string): string => {
    switch (status) {
      case "ACCEPTED":
        return "lime";
      case "DECLINED":
        return "red";
      case "UNDER_REVIEW":
        return "orange";
    }
    return "black";
  };


  const showModal = (pro: any) => {
    setCurrentPro(pro)
    setValidateSyndicatModal(true)
  }

  return (
    <div id="verify-syndicat">
      <Col className="d-flex justify-content-between mb-4">
        <div>
          <h1>Pro syndicats</h1>
          <Button
            onClick={() => setCreateSyndicatModal(true)}
            variant="success"
            className="mx-1"
          >
            Créer un syndicat
          </Button>
        </div>
        <div className="d-flex align-items-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              style={{ opacity: tab === "verify" ? 1 : 0.7 }}
              onClick={() => setTab("verify")}
            >
              À vérifier
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "verified" ? 1 : 0.7 }}
              onClick={() => setTab("verified")}
            >
              Vérifié
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Pro</th>
              <th>Syndicats</th>
              <th>Actions</th>
            </tr>
          </thead>
          {loading && <Spinner animation="border" />}
          {!loading && (
            <Resource
              interval={1000}
              url="/admin/pro/syndicats"
              render={(data: any) => (
                <tbody>
                  {data
                    .filter((pro: any) => {
                      if (tab === "verify") {
                        return pro.syndicats.map((syndicat: ISyndicatPro) => syndicat.syndicatStatus).includes("UNDER_REVIEW") ;
                      } else {
                        return pro.syndicats.map((syndicat: ISyndicatPro) => syndicat.syndicatStatus).includes(["ACCEPTED","DECLINED", "DELETE"]);
                      }
                    })
                    
                    .map((pro: any) => {
                      return (
                          <tr
                            style={{
                              borderLeft: `${statusToColor(
                                pro.syndicatStatus
                              )} 4px solid`,
                            }}
                            key={pro.id}
                          >
                            <td>
                              {pro.prenom ?? ""} {pro.nom ?? ""}
                            </td>
                            <td>{pro.syndicats?.filter((syndicat: ISyndicatPro)=> syndicat.syndicat).map((syndicat: ISyndicatPro) => syndicat.syndicat.denomination).join(", ") ?? ""}</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                  <Button onClick={(e) => showModal(pro)}>
                                    Modifier
                                  </Button>
                              </div>
                            </td>
                          </tr>
                    )})}
                    </tbody>
              )}
            />
          )}
        </Table>
      </Col>
      <CreateSyndicatModal
        showCreateModal={createSyndicatModal}
        setShowCreateModal={setCreateSyndicatModal}
      />

      <ValidateSyndicatModal
        showValidateModal={validateSyndicatModal}
        setShowValidateModal={setValidateSyndicatModal}
        currentProProps={currentPro}
      />
    </div>
  );
};
