import { useEffect, useState } from "react";
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from "dayjs";
import { EtablissementService } from "../../services/etablissement.service";

import "./etablissement.style.scss"


type account = {
  email: string,
  firstname: string,
  name: string
}

type ets = {
  raisonSociale: string,
  subscription: {
    options: Array<string>,
    type: string,
    created?: number,
    current_period_start?: number,
    current_period_end?: number,
  },
  accounts: Array<account>
}

export const Etablissement = () => {
  const [defaultList, setDefaultList] = useState([]);
  const [etsList, setEtsList] = useState([]);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("")

  useEffect(() => {
    (async () => {
      try {
        const list = await EtablissementService.getEtablissement()
        console.log(list)
        list.forEach((element:ets) => {
          element.subscription.type = element.subscription.type?.toLowerCase()??"inconnue"
          element.subscription.options = element.subscription.options.map(opt => opt.toLowerCase().replace("_", " "))
          return element
        });

        list.sort((a:ets,b:ets) => {
          if (!a.subscription.created) {
            return 1;
          }
          if (!b.subscription.created) {
            return -1;
          }
          return b.subscription.created - a.subscription.created;
        })

        setDefaultList(list)
        setEtsList(list);
      } catch (error: any) {
        setError(error);
        console.log(error)
      }
    })();
  }, []);

  const handleSelectedOption = (value: string) => {
    setSelectedOption(value)
    if(value === "") {
      setEtsList(defaultList)
      return
    }
    const sorted = defaultList.filter((etablissement:ets) => etablissement.subscription.options.includes(value))
    setEtsList(sorted)
  }

  const sortByName = () => {
    const oldList = [...etsList];
    const sorted = oldList.sort((a:ets,b:ets) => {
      if (a.raisonSociale < b.raisonSociale) return -1;
      if (a.raisonSociale > b.raisonSociale) return 1;
      return 0;
    })

    console.log(sorted);
    setEtsList(sorted);

  }

  const sortBySubscription = () => {
    const oldList = [...etsList];
    const sorted = oldList.sort((a:ets,b:ets) => {
      if (!a.subscription || !b.subscription) {
        return 0;        
      }
      if (a.subscription.type < b.subscription.type) return -1;
      if (a.subscription.type > b.subscription.type) return 1;
      return 0;
    })

    console.log(sorted)
    setEtsList(sorted)
  }

  etsList.forEach((ets:ets) => {
    if (ets.subscription.created) {
      console.log()  
    }
  })
  return (
    <div id="etablissement-list">
      <h1>Etablissements</h1>

      {error ? (
        <div className="alert alert-danger" role="alert">
          Une erreur est survenue sur le chargement des établissements
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center my-3">
            <h5 className="mb-0">Options: </h5>
            <select name="option-selector" id="option-selector" className="ms-2" value={selectedOption} onChange={(e) => handleSelectedOption(e.target.value)}>
             <option value="">Toutes</option>
              <option value="aide reponse">Aide aux réponses</option>
              <option value="tonalite">Tonalité</option>
            </select>
          </div>
        <table className="table table-sort table-arrows">
          <thead>
            <tr>
              <th role="button" scope="col-3" onClick={(e) => sortByName()}>Nom <FontAwesomeIcon icon={faChevronDown} /></th>
              <th role="button" scope="col-3" onClick={(e) => sortBySubscription()}>Type d'abonnement <FontAwesomeIcon icon={faChevronDown} /></th>
              <th scope="col-6">Options</th>
              <th role="button" scope="col-6" onClick={(e) => setEtsList(defaultList)}>Date de souscription <FontAwesomeIcon icon={faChevronDown} /></th>
              <th scope="col-6">Contact</th>
            </tr>
          </thead>
          <tbody>
              {etsList.map((ets:ets) => (
                <tr>
                   <th scope="row">{ets.raisonSociale}</th>
                   <td>{ets.subscription.type}</td>
                   <td>{ets.subscription.options.map(opt=> <>{opt} <br /></>)}</td>
                   <td>{ets.subscription.created ? dayjs(ets.subscription.created).format("DD/MM/YYYY") : 'N/A'}</td>
                   <td className="email">{ets.accounts ? (
                     ets.accounts.map(account => {
                       return <>{account.email}<br /></>
                     })
                   ): <>la</>}</td>
                 </tr>
              ))}
          </tbody>
        </table>
        </>
      )}
    </div>
  );
};
