import { Button, ButtonGroup, Col, Modal, Spinner, Table } from "react-bootstrap";
import { Resource } from "../../../components/Resource";

import { useState } from "react";
import { Survey } from "../../../interfaces/Survey";
import { PartenariatProItem } from "./PartenariatProItem";

export const VerifyProsPartenariat = () => {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState("verify")
  const [modalData, setModalData] = useState<Survey>();
  const [showModal, setShowModal] = useState(false)

  return (
    <div id="verify-partenariat">
      <Col className="d-flex justify-content-between mb-4">
        <div>
          <h1>Partenariats</h1>
        </div>
        <div className="d-flex align-items-center">
          <ButtonGroup aria-label="Basic example">
            <Button
              variant="secondary"
              style={{ opacity: tab === "verify" ? 1 : 0.7 }}
              onClick={() => setTab("verify")}
            >
              À vérifier
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "accepted" ? 1 : 0.7 }}
              onClick={() => setTab("accepted")}
            >
              Acceptés
            </Button>
            <Button
              variant="secondary"
              style={{ opacity: tab === "denied" ? 1 : 0.7 }}
              onClick={() => setTab("denied")}
            >
              Refusés
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Pro</th>
              <th>Questionnaire</th>
              <th>Action</th>
            </tr>
          </thead>
        {loading && <Spinner animation="border"/>}
        {!loading && (
          <Resource
            interval={1000}
            url="/admin/partnership"
            render={(data: any) => (
              <tbody>
                {data
                  .filter((pro: any) => {
                    if (tab === "accepted") {
                      return pro.openToPartnership.isOpen
                    } else if (tab === "denied") {
                      return pro.openToPartnership.isRefused
                    } else {
                      return !pro.openToPartnership.isOpen && !pro.openToPartnership.isRefused
                    }
                  })
                  .map((pro: any) => {
                    return (
                      <PartenariatProItem pro={pro} setModalData={setModalData} setShowModal={setShowModal} key={pro._id}/>
                    )
                  })
                }
              </tbody>
            )}
          />
        )}
        </Table>
      </Col>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Résultat Du Questionnaire
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Question</th>
                <th>Réponse</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                1. Comment réalisez-vous le suivi de vos clients ?
                </td>
                <td>
                  {modalData?.kindSuivi.join(" ; ")}
                </td>
              </tr>
              <tr>
                <td>
                2. Demandez-vous un retour sur les effets de vos séances auprès de votre clientèle ?
                </td>
                <td>
                  {modalData?.feedback}
                </td>
              </tr>
              <tr>
                <td>
                3. Comment gérez-vous les effets indésirables
                et/ou intéractions médicamenteuse ou alimentaire
                et/ou risques par rapport au profil du client ?
                </td>
                <td>
                  {modalData?.effectsHandling}
                </td>
              </tr>
              <tr>
                <td>
                4. Vous arrive-t-il d'adresser vos clients à des médecins ?
                </td>
                <td>
                  {modalData?.referral}
                </td>
              </tr>
              <tr>
                <td>
                5. Vous arrive-t-il d'adresser vos clients à d'autres praticiens de "médecines douces" ?
                </td>
                <td>
                  {`Réponse : ${modalData?.referralSoftMed.response} ;
                  Pourquoi? : ${modalData?.referralSoftMed.why}`}
                </td>
              </tr>
              <tr>
                <td>
                6. Vous arrive-t-il de renoncer à réaliser une séance avec un client en raison de son état
                de santé ou de ses symptômes ?
                </td>
                <td>
                  {`Réponse : ${modalData?.renounce.response} ;
                  Dans quel cas plus particulièrement? : ${modalData?.renounce.why}`}
                </td>
              </tr>
              <tr>
                <td>
                7. Comment gérez-vous les réclamations de vos clients ?
                </td>
                <td>
                  {modalData?.complaintsHandling}
                </td>
              </tr>
              <tr>
                <td>
                8. Qu'attendez vous en tant que "Partenaire du médical HOSPITALIDEE" ?
                </td>
                <td>
                  {modalData?.expectations.join(" ; ")}
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>

  );
};