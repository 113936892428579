import { useEffect, useState } from "react"
import { Alert, Button } from "react-bootstrap"
import Swal from "sweetalert2"
import {url as back} from "../../../server.json"
import { CabinetProService } from "../../../services/cabinetPro.service"

export const CabinetProItem = (props: any) => {
    const [error, setError] = useState("");
    const cabinet = props.cabinet;
    //const [account, setAccount] = 

    /*useEffect(
      () => 
        (async () => {
          if (cabinet?.status === "PENDING") {
            try {
              const account = await ProService.getAccountLinkedToCabinet(cabinet?.id)
              setAccount(account)
            } catch (error) {
              console.error(error)
              setError(error.message)
            }
          }
        }
        )(),
      []
    );*/

    const _acceptCabinet = async (id: string) => {
      try {
        await CabinetProService.acceptCabinet(id)
      } catch (error) {
        console.error(error)
        setError(error.message)
      }
    }

    const _declineCabinet = async (id: string) => {
      try {
        await CabinetProService.declineCabinet(id)
      } catch (error) {
        console.error(error)
        setError(error.message)
      }
    }

    const _deleteCabinet = async (id: string) => {
      // Swal to confirm modal
      Swal.fire({
        title: "Êtes-vous sûr de vouloir supprimer ce cabinet ?",
        text: "Cette action est irréversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await CabinetProService.deleteCabinet(id)
            Swal.fire("Supprimé", "Le cabinet a bien été supprimé", "success")
          } catch (error) {
            console.error(error)
            setError(error.message)
          }
        }
      })
    }

    return (
      <tr>
        <td>
          {cabinet?.businessName ?? ""}
        </td>
        {<td>
          {/* make a string for all members */}
          {cabinet?.team?.map((member: any) => member.account.firstName + " " + member.account.name).join(", ")}
        </td>}
        <td>
          {cabinet?.address ?? ""}, {cabinet?.postalCode ?? ""} {cabinet?.city ?? ""}
        </td>
        <td>
          <Button variant="success" onClick={() => {_acceptCabinet(cabinet?._id)}}>Valider</Button>
          <Button variant="warning" onClick={() => {_declineCabinet(cabinet?._id)}}>Refuser</Button>
          <Button variant="danger" onClick={() => {_deleteCabinet(cabinet?._id)}}>Supprimer</Button>
        </td>
      </tr>
    )
}
