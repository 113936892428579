import { useMemo } from "react";
import Alert from "react-bootstrap/esm/Alert";
import Table from "react-bootstrap/esm/Table";
import { Avis } from "../../interfaces/Avis";
import { Paginated } from "../../interfaces/Paginated";
import { Pagination } from "../Pagination";
import { VerifiedAvisetsListItem } from "./VerifiedAvisListItem";

export type VerifiedAvisetsListProps = {
  setPage: (p: number) => void;
  data: Paginated<Avis>;
};

export const VerifiedAvisetsList = ({
  data,
  setPage,
}: VerifiedAvisetsListProps) => {
  const paginationElement = useMemo(
    () => <Pagination data={data} setPage={setPage} />,
    [data, setPage]
  );
  if (data.items.length === 0)
    return <Alert variant="success">Aucun resultat</Alert>;
  return (
    <>
      {paginationElement}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>note</th>
            <th>établissement</th>
            <th>pseudo</th>
            <th>email</th>
            <th>titre</th>
            <th>plus</th>
            <th>moins</th>
            <th>date</th>
            <th>statut</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((avis, i) => (
            <VerifiedAvisetsListItem key={avis._id} avis={avis} />
          ))}
        </tbody>
      </Table>
      {paginationElement}
    </>
  );
};
