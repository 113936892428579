import React, { useEffect, useState } from "react";
import { ClaimsProService } from "../services/claim.service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import { Button, Spin, Dropdown, Space } from "antd";
import { CheckOutlined, CloseOutlined, UserOutlined, DownOutlined } from '@ant-design/icons'
import { EmailOutlined } from "@mui/icons-material";
import './list_claims_pro.scss'

const items = [
    {
      label: 'Accepter',
      key: '1',
      icon: <CheckOutlined />,
    },
    {
      label: 'Refuser',
      key: '2',
      icon: <CloseOutlined />,
      danger: true,
    },
    {
      label: 'Renvoyer le mail',
      key: '3',
      icon: <EmailOutlined />,
      disabled: true,
    },
];

const handleMenuClick = (e) => {
    if (e.key === 1){
        //accepter        
    } else if (e.key === 2) {
        //refuser
    }
}

const menuProps = {
    items,
    onClick: handleMenuClick,
};

const Actions = () => {
    return (
        <Dropdown menu={menuProps} trigger="click">
            <Button>
                <Space>
                    Actions
                    <DownOutlined style={{ marginBottom: 5 }} />
                </Space>
            </Button>
        </Dropdown>
    )
}
const ListClaimPro = () => {
    const [claims, setClaims] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setlimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [countClaims, setCountClaims] = useState(0)

    useEffect(async () => {
        setLoading(true)
        const res = await ClaimsProService.getAllClaimPro(page, limit)
        setClaims(res.data.items)
        setCountClaims(res.data.count)
        setLoading(false)
    }, [])


    const requestNewPage = async (pg) => {
        const res = await ClaimsProService.getAllClaimPro(pg, limit)
        setClaims(res.data.items)
    }
    if (loading) {
        return (
            <div className="loading-state-claims">
                <Spin size="large" />
                <div style={{ marginTop: 40, fontSize: 20 }}>Request data from server</div>
            </div>
        )
    }
    console.log(claims)
    return (
        <div className="container-claims">
            <h3>Demande de liaison pro</h3>
            <div className="claims-tables" style={{ marginTop: 20 }}>
                <TableContainer component={Paper} style={{  marginBottom: 20 }}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead sx={{ backgroundColor: "#3446eb" }}>
                            <TableRow>
                                <TableCell sx={{ fontSize: 18, color: 'white' }}>Date</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align='center'>Email de contact</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="center">Nom</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="center">Addresse</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="center">Profession</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="center">Téléphone</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="center">Statut</TableCell>
                                <TableCell sx={{ fontSize: 18, color: 'white' }} align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { 
                            claims.map((claim, id) => {
                                const createdAt = new Date(claim.createdAt);
                                const humanReadableDate = createdAt.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' });
                                return (
                                    <TableRow
                                        key={id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ fontSize: 16 }} component="th" scope="row">
                                            {humanReadableDate}
                                        </TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.email}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.lastname} {claim.firstname}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.postalCode}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.profession}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.phone ? (claim.phone): (<i>Non renseigné</i>)}</TableCell>
                                        <TableCell sx={{ fontSize: 16 }} align="center">{claim.status}</TableCell>
                                        <TableCell key={id} sx={{ fontSize: 16 }} align="right">
                                            <Actions claim={claim} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                <Pagination sx={{ marginBottom: 30, float: 'right' }} count={Math.floor(countClaims/limit) + 1} showFirstButton showLastButton onChange={(e, pg) => requestNewPage(pg)} variant="outlined" color="secondary" />
            </div>
        </div>
    )
}

export default ListClaimPro