import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs'

ReactDOM.render(
  <React.StrictMode>
    <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
      <App />
    </StyleProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
