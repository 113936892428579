import { useCallback, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import FormCheck from 'react-bootstrap/esm/FormCheck';
import FormGroup from 'react-bootstrap/esm/FormGroup';
import FormLabel from 'react-bootstrap/esm/FormLabel';

import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';

import { defaultCovidIndicators, Indicators } from '../../interfaces/Indicateurs';

type AvisListItemIndicatorProps<T extends keyof Indicators> = {
  indicator: T;
  setIndicator: (v: Indicators[T]) => void;
};

const indicatorKeyMap: Record<keyof Indicators, string> = {
  covid: "Règles sanitaires",
  caregiversRelationship: "Relation Soignants",
  doctorRelationship: "Relation Médecin",
  food: "Repas",
  invoice: "Facture",
  medicalErrors: "Erreurs méd.",
  organisation: "Organisation",
  pain: "Douleur",
  parking: "Parking",
  reception: "Accueil",
  room: "Chambre",
  service: "Services",
};

const covidKeyMap: Record<
  keyof Exclude<Indicators["covid"], "+" | null>,
  string
> = {
  insufficentCleaning: "Manque nettoyage mains, usages gants...",
  noMask: "Non port du masque par le personnel",
  proximity: "Proximité avec les usagers",
};
export function AvisListItemIndicator<T extends keyof Indicators>({
  indicator,
  setIndicator,
}: AvisListItemIndicatorProps<T>) {
  const [indicatorState, setIndicatorState] = useState<Indicators[T]>(null);
  const handleClick = useCallback(
    (v: Indicators[T]) => () => {
      setIndicator(v);
      setIndicatorState(v);
    },
    [setIndicator]
  );

  useEnhancedEffect(() => {
    console.log("indicatorState", indicatorState);
  }, [indicatorState]);
  if (indicator === "covid") {
    return (
      <AvisListItemIndicatorCovid
        indicator="covid"
        setIndicator={setIndicator as any}
        handleClick={handleClick}
        indicatorState={indicatorState}
      />
    );
  }
  return (
    <FormGroup>
      <label className="mb-1">{indicatorKeyMap[indicator]}</label>
      <ButtonGroup className="w-100">
        <Button
          size="sm"
          variant={indicatorState === null ? "dark" : "secondary"}
          onClick={handleClick(null)}
          style={{ fontSize: "12px" }}
        >
          Non spécifié
        </Button>
        <Button
          size="sm"
          style={{ fontSize: "12px" }}
          variant={indicatorState === "+" ? "success" : "secondary"}
          onClick={handleClick("+")}
        >
          +
        </Button>
        {(() => {
          return (
            <Button
              size="sm"
              style={{ fontSize: "12px" }}
              variant={indicatorState === "-" ? "danger" : "secondary"}
              onClick={handleClick("-")}
            >
              -
            </Button>
          );
        })()}
      </ButtonGroup>
    </FormGroup>
  );
}

//TODO: Better Typings
function AvisListItemIndicatorCovid({
  indicator,
  setIndicator,
  handleClick,
  indicatorState,
}: AvisListItemIndicatorProps<"covid"> & {
  handleClick: any;
  indicatorState: any;
}) {
  const covidRef = useRef(defaultCovidIndicators);
  const isMinusSelected = useMemo(
    () => !["+", null].includes(indicatorState),
    [indicatorState]
  );
  const setCovidChecks = useCallback(
    (v: any) => {
      const updated = { ...covidRef.current, ...v };
      covidRef.current = updated;
      setIndicator(updated);
    },
    [setIndicator]
  );
  return (
    <FormGroup>
      <label className="mb-1">{indicatorKeyMap[indicator]}</label>
      <ButtonGroup className="w-100">
        <Button
          size="sm"
          style={{ fontSize: "12px" }}
          variant={indicatorState === null ? "dark" : "secondary"}
          onClick={handleClick(null)}
        >
          Non spécifié
        </Button>
        <Button
          size="sm"
          style={{ fontSize: "12px" }}
          variant={indicatorState === "+" ? "success" : "secondary"}
          onClick={handleClick("+")}
        >
          +
        </Button>
        <Button
          size="sm"
          style={{ fontSize: "12px" }}
          variant={indicatorState === "-" ? "danger" : "secondary"}
          onClick={handleClick("-")}
        >
          -
        </Button>
      </ButtonGroup>
      {isMinusSelected && (
        <div>
          <FormCheck
            onChange={(e) => setCovidChecks({ noMask: e.target.checked })}
            type="checkbox"
            checked={covidRef.current["noMask"]}
            label={covidKeyMap["noMask"]}
          />
          <FormCheck
            onChange={(e) => setCovidChecks({ proximity: e.target.checked })}
            type="checkbox"
            checked={covidRef.current["proximity"]}
            label={covidKeyMap["proximity"]}
          />
          <FormCheck
            onChange={(e) =>
              setCovidChecks({ insufficentCleaning: e.target.checked })
            }
            type="checkbox"
            checked={covidRef.current["insufficentCleaning"]}
            label={covidKeyMap["insufficentCleaning"]}
          />
        </div>
      )}
    </FormGroup>
  );
}
