import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Col from 'react-bootstrap/esm/Col';
import { DiplomeList } from '../../components/diplomes/DiplomeList';
import { VerifiedDiplomeList } from '../../components/diplomes/VerifiedDiplomeList';
import { Resource } from '../../components/Resource';
import { Diplome } from '../../interfaces/Diplome';

let shape: { diplomes: Diplome[] };

export const VerifyDiplomePage = () => {
  const [tab, setTab] = useState<'verify' | 'verified'>('verify');
  return (
    <>
      <Col className='d-flex justify-content-between mb-4'>
        <h1>Diplome Control Center</h1>
        <div className='d-flex align-items-center'>
          <ButtonGroup aria-label='Basic example'>
            <Button
              variant='secondary'
              style={{ opacity: tab === 'verify' ? 1 : 0.7 }}
              onClick={() => setTab('verify')}>
              À vérifier
            </Button>
            <Button
              variant='secondary'
              style={{ opacity: tab === 'verified' ? 1 : 0.7 }}
              onClick={() => setTab('verified')}>
              Vérifié
            </Button>
          </ButtonGroup>
        </div>
      </Col>
      <Col>
        {tab === 'verify' && (
          <Resource
            dataShape={shape}
            url='/admin/diplomes/unverified'
            render={(data) => <DiplomeList diplomes={data.diplomes} />}
          />
        )}
        {tab === 'verified' && (
          <Resource
            dataShape={shape}
            url='/admin/diplomes/verified'
            render={(data) => <VerifiedDiplomeList diplomes={data.diplomes} />}
          />
        )}
      </Col>
    </>
  );
};
